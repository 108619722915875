import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

// import * as notifier from '../../actions/notifier';
import { login, forgotPassword } from '../../actions/user';
import { TextInput, Text, Button, Loader } from '../../components';
import { globalStyles } from '../../theme';
import validateInput from '../../services/FormValidation/validateInput';
import ResetPassword from '../Account/ResetPassword';

class Login extends Component {
    state = {
        email: '',
        emailError: false,
        password: '',
        passwordError: false,
        visibility: false,
        showPasswordResetModal: false
    };

    _toggleVisibility = () => {
        this.setState({
            visibility: !this.state.visibility
        });
    };

    _toggleModal = () => {
        this.setState({
            showPasswordResetModal: false
        });
    };

    _onBlur = (type) => {
        this.setState({
            [`${ type }Error`]: validateInput(type, this.state[type])
        });
    };

    _handleInputChange = (type, event) => {
        this.setState({
            [type]: event.target.value.trim()
        });
    };

    _login = async () => {
        const emailError = validateInput('email', this.state.email);
        const passwordError = validateInput('password', this.state.password);

        this.setState({
            emailError,
            passwordError
        });

        if (emailError || passwordError) {
            return;
        }

        await this.props.dispatch(login(this.state.email, this.state.password));
    };

    _navigateTo = (link) => {
        this.props.history.push(link);
    }

    _forgotPassword = () => {
        const emailError = validateInput('email', this.state.email);
        this.setState({ emailError });

        let success = false;

        if (!emailError) {
            success = this.props.dispatch(forgotPassword({ email: this.state.email }));
        }

        if (success) {
            setTimeout(() => {
                this.setState({
                    showPasswordResetModal: true
                });
            }, 2000);
        }

        return null;
    };

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.container}>
                <div style={{ paddingTop: 20 }}>
                    <img
                        alt="Hitchedagram Logo"
                        src={require('../../assets/logo-full-color-medium.png')}
                        className={classes.logo}
                        style={{ cursor: 'pointer' }}
                        onClick={() => this._navigateTo('/')}
                    />
                </div>

                <div className={classes.form}>
                    <Text
                        mediumLarge
                        bold
                        display="block"
                        align="left"
                        margin="20px 0"
                        customLinkStyle={{ lineHeight: '40px' }}
                    >
                        Welcome
                    </Text>

                    <TextInput
                        variant="outlined"
                        id="login"
                        label="Email"
                        type="email"
                        onBlur={() => this._onBlur('email')}
                        onChange={(value) => this._handleInputChange('email', value)
                        }
                        error={Boolean(this.state.emailError)}
                        helperText={this.state.emailError}
                        fullWidth
                        autofocus
                    />

                    <TextInput
                        variant="outlined"
                        id="password"
                        label="Password"
                        value={this.state.password}
                        inputProps={{
                            endAdornment: (
                                <InputAdornment>
                                    {this.state.visibility ? (
                                        <VisibilityIcon
                                            style={{ cursor: 'pointer' }}
                                            onClick={this._toggleVisibility}
                                        />
                                    ) : (
                                        <VisibilityOffIcon
                                            style={{ cursor: 'pointer' }}
                                            onClick={this._toggleVisibility}
                                        />
                                    )}
                                </InputAdornment>
                            )
                        }}
                        onBlur={() => this._onBlur('password')}
                        onChange={(value) => this._handleInputChange('password', value)
                        }
                        error={Boolean(this.state.passwordError)}
                        helperText={this.state.passwordError}
                        secure={!this.state.visibility}
                        fullWidth
                    />

                    <Button
                        onClick={this._login}
                        color="primary"
                        marginTop={40}
                        fullWidth
                    >
                        Login
                    </Button>

                    <div className={classes.subtext}>
                        <Button
                            onClick={() => this._navigateTo('/signup')}
                            color="primary"
                            variant="text"
                        >
                           Signup
                        </Button>

                        <Button
                            onClick={this._forgotPassword}
                            color="primary"
                            variant="text"
                        >
                            Forgot Password?
                        </Button>
                    </div>
                </div>

                {this.state.showPasswordResetModal && (
                    <ResetPassword
                        close={this._toggleModal}
                        email={this.state.email}
                    />
                )}

                {this.props.loading && <Loader />}
            </div>
        );
    }
}

/**
 *
 * @param {*} theme
 */
const styles = (theme) => ({
    container: globalStyles.container,
    form: globalStyles.form,
    logo: globalStyles.logo,
    noDecor: {
        textDecoration: 'none',
        textDecorationColor: 'none'
    },
    subtext: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 15
    }
});

/**
 *
 * @param {*} state
 */
const mapStateToProps = (state) => ({
    loading: state.user.loading
});

export default withStyles(styles)(connect(mapStateToProps)(Login));