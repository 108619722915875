import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';

import { globalStyles, colors } from '../../theme';
import Text from '../Text';
import Button from '../Button';

/**
 * @param title
 * @param subtitle
 * @param paragraph
 * @param checkbox
 * @param checked
 * @param classes
 * @param btnName
 * @param btn
 * @param btnOnClick
 * @param btnCustomStyle
 * @param noBorder
 * @param onChangeCheckbox
 * @returns {*}
 * @constructor
 */
const MessageCard = ({
    title,
    subtitle,
    paragraph,
    classes,
    checkbox,
    checked,
    btnName,
    btn,
    btnOnClick,
    btnCustomStyle,
    noBorder,
    onChangeCheckbox
}) => (
    <div
        className={`${ classes.contentBlock } ${ classes.cardStyle } ${ noBorder &&
			classes.noBorder }`}
    >
        <Text
            align="left"
            mediumLarge
            bold
            margin="0px 0px 10px 0px"
            display="block"
        >
            {title}
        </Text>

        <div className={`${ classes.subtitleBlock } `}>
            {checkbox && (
                <div className={classes.checkBlock}>
                    <Checkbox
                        checked={checked}
                        onChange={onChangeCheckbox}
                        color="primary"
                    ></Checkbox>
                </div>
            )}

            {subtitle && (
                <Text
                    align="left"
                    medium
                    margin="20px 0px 20px 0px"
                    display="block"
                >
                    {subtitle}
                </Text>
            )}
        </div>

        {paragraph && (
            <Text align="left" small margin="20px 0px 20px 0px" display="block">
                {paragraph}
            </Text>
        )}

        {btn && (
            <div className={classes.flexBlock}>
                <Button
                    color="primary"
                    customStyle={`${ classes.btnStyle } ${ btnCustomStyle } `}
                    onClick={btnOnClick}
                >
                    {btnName}
                </Button>
            </div>
        )}
    </div>
);

/**
 * @param theme
 */
const styles = (theme) => ({
    contentBlock: globalStyles.contentBlock,
    flexBlock: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    subtitleBlock: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline'
    },
    checkBlock: {
        display: 'block'
    },
    cardStyle: {
        backgroundColor: colors.lightBlue,
        boxShadow: 'none',
        padding: '20px',
        margin: '24px',
        border: `2px dashed ${ colors.mediumBlue }`,
        borderRadius: '15px'
    },
    noBorder: {
        border: 'none'
    },
    btnStyle: {
        width: 'fit-content'
    }
});

export default withStyles(styles)(MessageCard);