import moment from 'moment-timezone';

/**
 * @param date
 * @param format
 * @returns {*}
 */
function formatDateForDisplay(date, format = 'lll') {
    return moment(date)
        // .tz(Localization.timezone)
        .format(format);
}

/**
 * @param date
 * @returns {*}
 */
function formatIncoming(date) {
    const readyDate = moment(date).format('MM/DD/YYYY');
    const readyTime = moment(date).format('hh:mm');
    const AmPm = moment(date).format('A');

    return { readyTime, AmPm, readyDate };
}

/**
 * Convert the date to a unix timestamp for saving in the database
 * @param date
 * @returns {*}
 */
function formatOutgoing(date) {
    return moment(date, 'MM/DD/YYYY').toISOString();
}

/**
 * @param date
 * @returns {*}
 */
function formatIncomingDateForCountdown(date) {
    let now = moment(new Date()); // todays date
    let end = moment.unix(date); // event date
    let duration = moment.duration(end.diff(now));
    let days = duration.asDays();

    return Math.floor(days);
}

/**
 * Convert the date to a unix timestamp for saving in the database
 * @param date
 * @returns {*}
 */
function formatToISOString(date, time) {
    if (time === '') {
        time = '11:59';
    }

    const combinedStart = `${ date } ${ time }`;

    console.log(moment(combinedStart, 'MM/DD/YYYY hh:mm').toISOString());

    // Todo: check
    return moment(combinedStart, 'MM/DD/YYYY hh:mm').toISOString();
}

/**
 * Convert the date to a unix timestamp for saving in the database
 * @param date
 * @returns {*}
 */
function formatOutgoingLong(dateTime) {

    return moment(dateTime).toISOString();
}

/**
 * @param date
 * @param noFuture
 * @returns {string}
 */
function validateDate(date, noFuture = true) {
    if (!date || date === '') {
        return 'Please enter a date: MM/DD/YYYY';
    }

    if (!moment(date)._isValid) {
        return 'Invalid date';
    }

    // if (noFuture && !moment(date, "MM/DD/YYYY", true).isBefore()) {
    //     return 'Date cannot be in the future';
    // }

    return null;
}

export {
    formatIncoming,
    formatDateForDisplay,
    formatIncomingDateForCountdown,
    formatOutgoing,
    formatOutgoingLong,
    formatToISOString,
    validateDate
};