import initialState from '../initialState';
import {
    LOADING,
    GET_USER,
    // SIGNUP_OWNER_SUCCESS,

    UPDATE_USER_SETTINGS_SUCCESS,
    ADD_COUPLE_SUCCESS,
    // UPDATE_DEPENDENT_SUCCESS,
    PAYMENT_DETAILS_SUCCESS,
    REMOVE_COUPLE_SUCCESS,
    ADD_BENEFICIARY_SUCCESS,
    UPDATE_BENEFICIARY_SUCCESS,
    REMOVE_BENEFICIARY_SUCCESS,
    UPDATE_CURRENT_BENEFICIARIES,
    ADD_TRUSTEE_SUCCESS,
    UPDATE_TRUSTEE_SUCCESS,
    REMOVE_TRUSTEE_SUCCESS,
    REMOVE_NOTIFICATION_SUCCESS
} from '../../actions/types/user';

export default (state = initialState.user, action) => {
    switch (action.type) {
        case LOADING: {
            return {
                ...state,
                loading: action.state
            };
        }

        // case SIGNUP_OWNER_SUCCESS: {
        //     console.log('SIGNUP_OWNER_SUCCESS', action.userAndWedding);
        //     return {
        //         ...state,
        //         dependents: {
        //             ...state.dependents,
        //             firstName: false,
        //             lastName: false,
        //             birthdate: false,
        //             relationship: false
        //         }
        //     };
        // }

        case GET_USER: {
            return {
                ...state,
                meta: action.meta,
                dependents: action.dependents,
                beneficiaries: action.beneficiaries,
                trustee: action.trustee,
                notifications: action.notifications
            };
        }

        case UPDATE_USER_SETTINGS_SUCCESS: {
            return {
                ...state,
                meta: { ...state.meta, ...action.data }
            };
        }

        case PAYMENT_DETAILS_SUCCESS: {
            return {
                ...state,
                meta: {
                    ...state.meta,
                    paymentsSetup: 1
                }
            };
        }

        case ADD_COUPLE_SUCCESS: {
            return {
                ...state,
                dependents: [ action.dependent, ...state.dependents ]
            };
        }

        // case UPDATE_DEPENDENT_SUCCESS: {
        //     return {
        //         ...state,
        //         dependents:
        //             state.dependents.map((dependent) => {
        //                 if (dependent.dependentID === action.dependentID) {
        //                     return {
        //                         ...dependent,
        //                         ...action.dependent
        //                     };
        //                 }

        //                 return dependent;

        //             })
        //     };
        // }

        case REMOVE_COUPLE_SUCCESS: {
            return {
                ...state,
                dependents:
                    state.dependents.filter((dependent) => dependent.dependentID !== action.dependentID)
            };
        }

        case ADD_BENEFICIARY_SUCCESS: {
            return {
                ...state,
                beneficiaries: [ action.beneficiary, ...state.beneficiaries ]
            };
        }

        case UPDATE_BENEFICIARY_SUCCESS: {
            return {
                ...state,
                beneficiaries:
                    state.beneficiaries.map((beneficiary) => {
                        if (beneficiary.beneficiaryID === action.beneficiaryID) {
                            return {
                                ...beneficiary,
                                ...action.beneficiary
                            };
                        }

                        return beneficiary;

                    })
            };
        }

        case REMOVE_BENEFICIARY_SUCCESS: {
            return {
                ...state,
                beneficiaries:
                    state.beneficiaries.filter((beneficiary) => beneficiary.beneficiaryID !== action.beneficiaryID)
            };
        }

        case UPDATE_CURRENT_BENEFICIARIES: {
            const currentBeneficiaries = action.currentBeneficiaries;
            return {
                ...state,
                beneficiaries: state.beneficiaries.map((beneficiary) => {
                    currentBeneficiaries.some((currentBeneficiary) => {
                        if (currentBeneficiary.beneficiaryID === beneficiary.beneficiaryID) {
                            beneficiary = {
                                ...beneficiary,
                                ...currentBeneficiary
                            };

                            return true;
                        }

                        return false;
                    });

                    return {
                        ...beneficiary
                    };
                })
            };
        }

        case ADD_TRUSTEE_SUCCESS: {
            return {
                ...state,
                trustee: action.trustee
            };
        }

        case UPDATE_TRUSTEE_SUCCESS: {
            return {
                ...state,
                trustee: action.trustee
            };
        }

        case REMOVE_TRUSTEE_SUCCESS: {
            return {
                ...state,
                trustee: {
                    firstName: null,
                    lastName: null,
                    relationship: null
                }
            };
        }

        case REMOVE_NOTIFICATION_SUCCESS: {
            return {
                ...state,
                notifications: state.notifications.filter((item) => item.id !== action.id)
            };
        }

        default:
            return state;
    }
};