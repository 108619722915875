import * as types from '../../actions/types/theme';
import initialState from '../initialState';

export default (state = initialState.theme, action) => {
    switch (action.type) {
        case types.CHANGE_BASE_THEME:
            return {
                ...action.theme,
                name: action.name
            };

        case types.CHANGE_COLOR_THEME:
            return {
                ...state,
                theme: action.theme
            };

        default:
            return state;
    }
};