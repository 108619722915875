import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import HistoryIcon from '@material-ui/icons/History';
import SettingsIcon from '@material-ui/icons/Settings';
import CheckIcon from '@material-ui/icons/Check';
import AddIcon from '@material-ui/icons/Add';
import ReceiptIcon from '@material-ui/icons/Receipt';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import BarChartIcon from '@material-ui/icons/BarChart';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ListAltIcon from '@material-ui/icons/ListAlt';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PersonIcon from '@material-ui/icons/Person';
import GradeIcon from '@material-ui/icons/Grade';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import PeopleIcon from '@material-ui/icons/People';
import SecurityIcon from '@material-ui/icons/Security';
import TimelineIcon from '@material-ui/icons/Timeline';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import CreditCardIcon from '@material-ui/icons/CreditCard';

import Text from '../Text';
import { colors } from '../../theme';

/**
 *
 * @param {*} param0
 */
const Tile = ({
    onClick,
    title,
    description,
    icon,
    disabled,
    step,
    classes,
    customStyle,
    img
}) => {
    let className = `${ classes.container }`;

    if (disabled) {
        className += ` ${ classes.disabled }`;
    }

    if (customStyle) {
        className += ` ${ customStyle }`;
    }

    // Add additional icons here if necessary

    const iconMap = {
        creditCard: (
            <CreditCardIcon
                className={classes.icon}
                style={{ color: icon.color || '#000' }}
            />
        ),
        howToReg: (
            <HowToRegIcon
                className={classes.icon}
                style={{ color: icon.color || '#000' }}
            />
        ),
        deviceHub: (
            <DeviceHubIcon
                className={classes.icon}
                style={{ color: icon.color || '#000' }}
            />
        ),
        assignment: (
            <AssignmentIcon
                className={classes.icon}
                style={{ color: icon.color || '#000' }}
            />
        ),
        cloudDownload: (
            <CloudDownloadIcon
                className={classes.icon}
                style={{ color: icon.color || '#000' }}
            />
        ),
        cloudUpload: (
            <CloudUploadIcon
                className={classes.icon}
                style={{ color: icon.color || '#000' }}
            />
        ),
        thumbDown: (
            <ThumbDownIcon
                className={classes.icon}
                style={{ color: icon.color || '#000' }}
            />
        ),
        doneAll: (
            <DoneAllIcon
                className={classes.icon}
                style={{ color: icon.color || '#000' }}
            />
        ),
        add: (
            <AddIcon
                className={classes.icon}
                style={{ color: icon.color || '#000' }}
            />
        ),
        receipt: (
            <ReceiptIcon
                className={classes.icon}
                style={{ color: icon.color || '#000' }}
            />
        ),
        attachMoney: (
            <AttachMoneyIcon
                className={classes.icon}
                style={{ color: icon.color || '#000' }}
            />
        ),
        barChart: (
            <BarChartIcon
                className={classes.icon}
                style={{ color: icon.color || '#000' }}
            />
        ),
        listAlt: (
            <ListAltIcon
                className={classes.icon}
                style={{ color: icon.color || '#000' }}
            />
        ),
        insertInvitation: (
            <InsertInvitationIcon
                className={classes.icon}
                style={{ color: icon.color || '#000' }}
            />
        ),
        shoppingCart: (
            <ShoppingCartIcon
                className={classes.icon}
                style={{ color: icon.color || '#000' }}
            />
        ),
        person: (
            <PersonIcon
                className={classes.icon}
                style={{ color: icon.color || '#000' }}
            />
        ),
        grade: (
            <GradeIcon
                className={classes.icon}
                style={{ color: icon.color || '#000' }}
            />
        ),
        supervisedUserCircle: (
            <SupervisedUserCircleIcon
                className={classes.icon}
                style={{ color: icon.color || '#000' }}
            />
        ),
        supervisorAccount: (
            <SupervisorAccountIcon
                className={classes.icon}
                style={{ color: icon.color || '#000' }}
            />
        ),
        people: (
            <PeopleIcon
                className={classes.icon}
                style={{ color: icon.color || '#000' }}
            />
        ),
        security: (
            <SecurityIcon
                className={classes.icon}
                style={{ color: icon.color || '#000' }}
            />
        ),
        timeline: (
            <TimelineIcon
                className={classes.icon}
                style={{ color: icon.color || '#000' }}
            />
        ),
        accountCircle: (
            <AccountCircleIcon
                className={classes.icon}
                style={{ color: icon.color || '#000' }}
            />
        ),
        history: (
            <HistoryIcon
                className={classes.icon}
                style={{ color: icon.color || '#000' }}
            />
        ),
        settings: (
            <SettingsIcon
                className={classes.icon}
                style={{ color: icon.color || '#000' }}
            />
        ),
        check: (
            <CheckIcon
                className={classes.icon}
                style={{ color: icon.color || '#000' }}
            />
        )
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row-reverse',
                justifyContent: 'flex-start',
                alignItems: 'center'
                // opacity: 0.2
                // background:
                // 	'linear-gradient(0.25turn, rgba(0,0,0,.3), rgba(255,255,255))'
                // background:
                // 	'linear-gradient(0.25turn, rgba(0,0,0,0.5),transparent)'
            }}
        >
            <div
                style={
                    {
                        backgroundImage: `url(${ iconMap })`
                        // backgroundRepeat: 'no-repeat',
                        // // backgroundAttachment: 'fixed',
                        // backgroundPosition: 'center',
                        // backgroundSize: 'cover'
                    }
                }
                className={className}
                onClick={disabled || !onClick ? null : onClick}
            >
                {disabled && (
                    <div className={classes.disabledOverlay}>
                        <Text extraLarge color={colors.darkBlue}>
                            {`Step ${ step }`}
                        </Text>
                    </div>
                )}

                {/* <div className={classes.iconContainer}>{iconMap[icon.name]}</div> */}

                <div className={classes.textContainer}>
                    <Text
                        largest
                        color={colors.black}
                        display="block"
                        align="center"
                    >
                        {title}
                    </Text>

                    <Text
                        mediumLarge
                        light
                        color={colors.gradientBlue}
                        display="block"
                        align="center"
                        margin="10px 0 0 0"
                    >
                        {description}
                    </Text>
                </div>
            </div>
        </div>
    );
};

/**
 *
 * @param {*} theme
 */
const styles = (theme) => ({
    container: {
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.15)',
        background: colors.surface,
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-end',
        cursor: 'pointer',
        margin: '8px',
        position: 'relative',
        '&:hover': {
            boxShadow: '0px 12px 40px rgba(0, 0, 0, 0.15)'
        },
        padding: 20
    },
    disabled: {
        boxShadow: 'none',
        '&:hover': {
            boxShadow: 'none'
        }
    },
    disabledOverlay: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        width: '100%',
        height: '100%',
        background: 'rgba(232, 232, 232, 0.7)'
    },
    iconContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 0.5
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 0.5,
        // padding: '0 20px',
        opacity: 1,
        borderRadius: 50,
        backgroundColor: colors.white,
        padding: 10
    },
    icon: {
        fontSize: '38px'
    }
});

export default withStyles(styles)(Tile);