import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import Button from './Button';
import Text from './Text';
import globalStyles from '../theme/globalStyles';

/**
 * @param props
 * @returns {*}
 * @constructor
 */
function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class StepCompletionNotif extends Component {
    render() {
        const {
            classes,
            dialog,
            open,
            type,
            handleClose,
            handleNext,
            disableBackdropClick,
            disableEscapeKeyDown
        } = this.props;

        return (
            <div>
                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    disableBackdropClick={disableBackdropClick}
                    disableEscapeKeyDown={disableEscapeKeyDown}
                >
                    <DialogTitle>{type} Completed!</DialogTitle>
                    <DialogContent>
                        <Text medium>{dialog}</Text>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleClose}
                            customStyle={classes.modalBtn}
                        >
							Close
                        </Button>
                        <Button
                            onClick={handleNext}
                            customStyle={classes.modalBtn}
                        >
							Next
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

/**
 * @param theme
 */
const styles = (theme) => ({
    modalBtn: globalStyles.modalBtn
});

export default withStyles(styles)(StepCompletionNotif);