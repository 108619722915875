import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { logout } from '../../actions/user';

import { Button, Text, Loader } from '../../components';
import { globalStyles, colors } from '../../theme';


class InternalError extends Component {
    state = {};

    _navigateTo = (link) => {
        this.props.history.push(link);
    }

    _logout = () => {
        this.props.dispatch(logout());
    };

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.container}>
                <div style={{ paddingTop: 20 }}>
                    <img
                        alt="Hitchedagram Logo"
                        src={require('../../assets/logo-full-color-medium.png')}
                        className={classes.logo}
                    />
                </div>

                <Text
                    largest
                    bold
                    display="block"
                    align="center"
                    margin="20px 0"
                    customLinkStyle={{ lineHeight: '40px' }}
                >
                    500 SERVER ERROR, please contact administration.
                </Text>

                <div className={classes.buttonWrapper}>
                    <Button
                        onClick={() => this._navigateTo('/gallery')}
                        color="primary"
                        fullWidth
                    >
                        Navigate to home
                    </Button>

                    <Button
                        onClick={() => this._navigateTo('/my-account')}
                        transparent
                    >
                        Navigate to my account
                    </Button>

                    <Button
                        onClick={() => this._navigateTo('/support')}
                        transparent
                    >
                        Navigate to support
                    </Button>

                    <Button
                        onClick={this._logout}
                        transparent
                    >
                        <Text medium color={colors.red}>Logout</Text>
                    </Button>

                    {this.props.loading && <Loader />}
                </div>
            </div>
        );
    }
}

/**
 * 
 * @param {*} theme 
 */
const styles = (theme) => ({
    container: globalStyles.container,
    buttonWrapper: {
        margin: '50px auto 50px auto',
        maxWidth: '550px'
    },
    logo: globalStyles.logo,
    noDecor: {
        color: colors.mediumDarkGrey,
        textDecoration: 'none',
        textDecorationColor: 'none'
    },
    subtext: {
        color: colors.mediumDarkGrey,
        cursor: 'pointer',
        display: 'block',
        fontSize: '12px',
        marginBottom: '16px',
        marginTop: '16px',
        textAlign: 'center'
    }
});

InternalError.propTypes = {
    loading: PropTypes.bool
};

/**
 * 
 * @param {*} state 
 */
const mapStateToProps = (state) => ({
    loading: state.user.loading
});

export default withStyles(styles)(connect(mapStateToProps)(InternalError));