import * as notifier from './notifier';
import * as types from './types/posts';
import * as posts from '../xhr/posts';
import { history } from '../App';

/**
 * @param dispatch
 * @param state
 */
function setLoading(dispatch, state) {
    dispatch({
        type: types.LOADING,
        state
    });
}

/**
 * @param weddingId
 * @returns {function(*=): Promise<number | never | boolean>}
 * @constructor
 */
export function getPosts(weddingId) {
    return (dispatch) => {
        setLoading(dispatch, true);

        return posts
            .getFeed(weddingId)
            .then((data) => {
                dispatch({
                    type: types.GET_ALL_POSTS,
                    items: data.posts
                });

                setLoading(dispatch, false);
                return data;
            })
            .catch((error) => {
                dispatch(notifier.displayError(error));
                
                setLoading(dispatch, false);
                return false;
            });
    };
}

/**
 * @param body
 * @returns {function(*): Promise<any | never>}
 */
export function createPost(body) {
    return (dispatch) => {
        setLoading(dispatch, true);

        return posts
            .createPost(body)
            .then((data) => {


                setLoading(dispatch, false);
                return data;
            })
            .catch((error) => {
                dispatch(notifier.displayError(error));

                setLoading(dispatch, false);
                return false;
            });
    };
}

/**
 * @param post
 * @returns {function(*): Promise<any | never>}
 */
export function completePost(post) {
    return (dispatch) => {
        setLoading(dispatch, true);

        return posts
            .completePost(post)
            .then((data) => {
                dispatch({
                    type: types.COMPLETE_POST_SUCCESS,
                    item: data.post
                });

                setLoading(dispatch, false);

                return data;
            })
            .catch((error) => {
                setLoading(dispatch, false);

                dispatch(notifier.displayError(error));
                return false;
            });
    };
}

/**
 * @param body
 * @returns {function(*): Promise<any | never>}
 */
export function createPhoto(body) {
    return (dispatch) => {
        setLoading(dispatch, true);

        return posts
            .createPhoto(body)
            .then((data) => {
                // console.log('createPhoto', data);

                setLoading(dispatch, false);
                return data;
            })
            .catch((error) => {
                setLoading(dispatch, false);

                dispatch(notifier.displayError(error));
                return false;
            });
    };
}

/**
 * @param body
 * @returns {function(*): Promise<any | never>}
 */
export function markPhotoUploaded(body) {
    return (dispatch) => {
        setLoading(dispatch, true);

        return posts
            .markPhotoUploaded(body)
            .then((data) => {
                console.log('markPhotoUploaded', data);

                setLoading(dispatch, false);
                return data;
            })
            .catch((error) => {
                setLoading(dispatch, false);

                dispatch(notifier.displayError(error));
                return false;
            });
    };
}

/**
 * @param body
 * @returns {function(*): Promise<any | never>}
 */
export function submitComment(body) {
    return (dispatch) => {
        setLoading(dispatch, true);

        return posts
            .addComment(body)
            .then((data) => {

                dispatch({
                    type: types.SUBMIT_COMMENT_SUCCESS,
                    post: data.post
                });

                setLoading(dispatch, false);

                return data;
            })
            .catch((error) => {
                setLoading(dispatch, false);

                dispatch(notifier.displayError(error));
                return false;
            });
    };
}

/**
 * @param likes
 * @param postId
 * @returns {function(*): Promise<any | never>}
 */
export function addLike(postId) {
    return (dispatch) => {
        setLoading(dispatch, true);

        return posts
            .addLike({ postId })
            .then((data) => {
                setLoading(dispatch, false);

                return data;
            })
            .catch((error) => {
                setLoading(dispatch, false);

                dispatch(notifier.displayError(error));
                return false;
            });
    };
}

/**
 * @param likes
 * @param postId
 * @returns {function(*): Promise<any | never>}
 */
export function removeLike(postId) {
    return (dispatch) => {
        setLoading(dispatch, true);

        return posts
            .removeLike({ postId })
            .then((data) => {
                setLoading(dispatch, false);

                return data;
            })
            .catch((error) => {
                setLoading(dispatch, false);

                dispatch(notifier.displayError(error));
                return false;
            });
    };
}

/**
 * @param postId
 * @returns {function(*): Promise<any | never>}
 */
export function blockPost(postId, weddingId) {
    return (dispatch) => {
        setLoading(dispatch, true);

        return posts
            .blockPost({ postId })
            .then((data) => {
                history.push('/gallery');

                dispatch(getPosts({ weddingId }));
                setLoading(dispatch, false);

                return data;
            })
            .catch((error) => {
                setLoading(dispatch, false);

                notifier.displayError(error.verbiage);
                return false;
            });
    };
}

/**
 * @param userId
 * @returns {function(*): Promise<any | never>}
 */
export function blockUser(userId, weddingId) {
    return (dispatch) => {
        setLoading(dispatch, true);

        return posts
            .blockUser({ userIdToBlock: userId })
            .then((data) => {
                history.push('/gallery');
                 
                dispatch(getPosts({ weddingId }));
                setLoading(dispatch, false);

                return data;
            })
            .catch((error) => {
                setLoading(dispatch, false);

                notifier.displayError(error.verbiage);
                return false;
            });
    };
}