import React, { Component } from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Avatar from 'react-avatar';
import Grid from '@material-ui/core/Grid/Grid';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu/Menu';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';

import { globalStyles, colors } from '../../theme';
import Text from '../Text';

class MemberCard extends Component {
    state = {
        anchorEl: null
    };

    _handleMenuClick = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    _handleCloseMenu = () => {
        this.setState({ anchorEl: null });
    };

    _editUser = () => {
        this._handleCloseMenu();
        this.props.onEditEmployee();
    };

    _notifyUser = () => {
        this._handleCloseMenu();
        this.props.sendNotification();
    };

    render() {
        const { classes } = this.props;
        const name = `${ this.props.member.firstName } ${ this.props.member.lastName }`;

        return (
            <Grid
                container
                alignItems="center"
                className={`${ classes.cardBlock } ${ classes.memberCard }`}
            >
                <Grid item xs={2} sm={2} md={1}>
                    <Avatar
                        name={name}
                        size="40"
                        textSizeRatio={2.2}
                        round={false}
                    />
                </Grid>
                <Grid onClick={this._editUser} item xs={6} sm={6} md={8}>
                    <Grid container direction="column" alignItems="flex-start">
                        <p className={classes.cardTitle}>{name}</p>
                        <p className={classes.cardSubtitle}>
                            {this.props.member.email}
                        </p>
                    </Grid>
                </Grid>
                <Grid item xs={2} sm={2} md={2} style={{ textAlign: 'center' }}>
                    {this.props.division && (
                        <p className={classes.cardTitle}>
                            {this.props.division}
                        </p>
                    )}
                </Grid>
                <Grid item xs={2} sm={1} md={1} style={{ textAlign: 'center' }}>
                    <IconButton
                        aria-label="Edit"
                        onClick={this._handleMenuClick}
                    >
                        <MoreVertIcon />
                    </IconButton>

                    <Menu
                        anchorEl={this.state.anchorEl}
                        open={Boolean(this.state.anchorEl)}
                        onClose={this._handleCloseMenu}
                        disableAutoFocusItem={true}
                    >
                        <MenuItem
                            onClick={this._editUser}
                            style={{ width: 80 }}
                        >
                            <Text color="inherit" medium display="inline">
                                Edit
                            </Text>
                        </MenuItem>

                        {this.props.sendNotification && (
                            <MenuItem onClick={this._notifyUser}>
                                <Text color="inherit" medium display="inline">
                                    Notify
                                </Text>
                            </MenuItem>
                        )}
                    </Menu>
                </Grid>
            </Grid>
        );
    }
}

/**
 * @param theme
 */
const styles = (theme) => ({
    cardBlock: globalStyles.cardBlock,
    cardSubtitle: {
        color: colors.mediumDarkGrey,
        fontSize: '12px',
        margin: '0',
        padding: '0'
    },
    cardTitle: globalStyles.cardTitle,
    memberCard: globalStyles.memberCard
});

export default withStyles(styles)(MemberCard);