export default {
    user: {
        meta: { email: 'Loading...',
            firstName: 'Loading...',
            lastName: 'Loading...',
            id: 'Loading...' },
        role: "owner",
        jwt: null,
        loading: false
    },
    guests: {
        items: [],
        loading: false
    },
    theme: {
        name: 'lightTheme',
        surface: 'white',
        borders: 'black',
        text: 'black',
        lightAccent: 'lightGrey',
        mediumAccent: 'mediumGrey',
        darkAccent: 'mediumDarkGrey',
        contrast: 'gradientBlue'
    },
    wedding: {
        weddingId: null,
        data: {
            createdAt: null,
            date: null,
            id: null,
            name: null,
            shortCode: null,
            updatedAt: null
        },
        loading: false
    },
    usage: {
        items: null,
        loading: false
    },
    events: {
        currentEvent: null,
        items: [],
        loading: false
    },
    signup: {
        userDetails: {
            accountIdentifier: false,
            userIdentifier: false,
            email: false,
            password: false,
            confirmPassword: false
        }
    },
    posts: {
        items: [],
        loading: false
    },
    notifier: {
        showing: false,
        verbiage: 'Something failed, please try again',
        type: 'error'
    }
};