/* eslint-disable camelcase */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import {
    updateFormField,
    addCouple
} from '../../../actions/signup';
import {
    TextInput,
    Button,
    Text
} from '../../../components';
import { globalStyles, colors } from '../../../theme';
import validateInput from '../../../services/FormValidation/validateInput';
// import * as selectFieldOptions from '../../../services/selectFieldOptions';

class Couple extends PureComponent {
    state = {
        firstName: '',
        lastName: '',
        email: '',
        role: 'admin',
        weddingId: this.props.wedding.id || '',
        notifyText: false,
        notifyTextColor: ''
    };

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            weddingId: nextProps.wedding.id
        });
    }

    _onBlur = (type) => {
        // update state with new form field value so it sticks when switching between views
        this.props.dispatch(updateFormField('couple', type, this.state[type]));
    };

    _handleInputChange = (type, event) => {
        this.setState({
            [type]: event.target.value
        });
    };

    _isFormEmpty = () => {
        const { firstName, lastName, email, role, weddingId } = this.state;

        if (firstName === '' && lastName === '' && email === '' && role === '' && weddingId === '') {
            return false;
        }

        return this._saveCouple();
    };

    _validateForm = () => {
        const { firstName, lastName, email, role, weddingId } = this.state;

        const firstnameError = validateInput('firstName', firstName);
        const lastnameError = validateInput('lastName', lastName);
        const emailError = validateInput('email', email);
        const roleError = validateInput('presence', role);
        const weddingIdError = validateInput('presence', weddingId);

        this.setState({
            firstnameError,
            lastnameError,
            emailError,
            roleError, 
            weddingIdError
        });

        if (firstnameError || lastnameError || emailError || roleError || weddingIdError) {
            return false;
        }

        return true;
    };

    /**
     * Called after age validation
     * @private
     */
    _saveCouple = async () => {
        const isValid = await this._validateForm();
        if (!isValid) {

            console.log('Invalid form');
            return null;
        }

        const { firstName, lastName, email, role, weddingId } = this.state;

        const data = {
            firstName: firstName,
            lastName: lastName,
            email,
            weddingId,
            role
        };

        const createSpouse = await this.props.dispatch(addCouple(data));

        if (createSpouse) {
            this.props.stepCompletion(data, 'coupleDetails');
        }

        return true;
    };

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.stepperWrapper}>
                <Text
                    align="center"
                    largest
                    bold
                    // margin="0px 0px 10px 0px"
                    display="block"
                >
                    Next, tell us about your spouse to be....
                </Text>

                <div className={classes.stepperForm}>
                    <TextInput
                        variant="outlined"
                        label="First Name"
                        autoFocus
                        value={this.state.firstName}
                        onBlur={() => this._onBlur('firstName')}
                        onChange={(value) => this._handleInputChange('firstName', value)
                        }
                        error={Boolean(this.state.firstnameError)}
                        helperText={this.state.firstnameError}
                        fullWidth
                    />

                    <TextInput
                        variant="outlined"
                        label="Last Name"
                        value={this.state.lastName}
                        onBlur={() => this._onBlur('lastName')}
                        onChange={(value) => this._handleInputChange('lastName', value)
                        }
                        error={Boolean(this.state.lastnameError)}
                        helperText={this.state.lastnameError}
                        fullWidth
                    />

                    <TextInput
                        variant="outlined"
                        label="Email"
                        value={this.state.email}
                        onBlur={() => this._onBlur('email')}
                        onChange={(value) => this._handleInputChange('email', value)}
                        error={Boolean(this.state.emailError)}
                        helperText={this.state.emailError}
                        fullWidth
                    />

                    <div className={classes.stepperBtnContainer}>
                        <Button
                            className={classes.stepperNextBtn}
                            onClick={this._isFormEmpty}
                            color="secondary"
                        >
                            Next
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

/**
 *
 * @param {*} theme
 */
const styles = (theme) => ({
    dependentIcon: {
        cursor: 'pointer',
        backgroundColor: colors.surface,
        padding: '2.5px 10px 2.5px 10px',
        borderRadius: '4px',
        '&:hover': {
            filter: 'brightness(75%)'
        }
    },
    stepperWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around'
    },
    saveContainer: {
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'space-between'
    },
    stepperBackBtn: {
        margin: '0px auto 0px 0px !important'
    },
    stepperBtnContainer: globalStyles.stepperBtnContainer,
    stepperForm: globalStyles.stepperForm,
    stepperNextBtn: globalStyles.stepperNextBtn
});

Couple.propTypes = {
    wedding: PropTypes.object
};

/**
 *
 * @param {*} state
 */
const mapStateToProps = (state) => {
    console.log(
        // "Couple" 
        // state
    );

    return {
        wedding: state.signup.wedding
    };
};

export default withStyles(styles)(connect(mapStateToProps)(Couple));