import Grid from '@material-ui/core/Grid/Grid';
import Icon from '@material-ui/core/Icon';
import Modal from '@material-ui/core/Modal';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { colors, globalStyles } from '../../theme';
import Button from '../Button';
import Loader from '../Loader';
import Text from '../Text';


class FlagModal extends Component {
    state = {
        showModal: false,
        notifyText: false,
        notifyTextColor: '',
        loading: false
    };

    _blockPost = () => {
        this.props.blockPost();
        this.props.backToMain();

        this.props.hideModal();
    };

    _blockUser = () => {
        this.props.blockUser();
        this.props.backToMain();

        this.props.hideModal();
    };

    render() {
        const { classes, showModal, hideModal } = this.props;
    
        return (
            <Modal
                open={showModal}
                onClose={hideModal}
                style={{ overflow: 'auto', 'WebkitOverflowScrolling': 'touch' }}
            >
                <div className={`${ classes.content } ${ classes.modal }`} >
                    <div className={classes.modalBody}>
                        <div className={classes.titleIconWrapper}>
                            <Icon
                                className={classes.closeModalIcon}
                                onClick={hideModal}
                            >
                                close
                            </Icon>

                            <Text
                                align="center"
                                color="mediumDarkGrey"
                                bold
                                mediumLarge
                                display="inline-block"
                                margin="0"
                            >
                                Inappropriate Content
                            </Text>
                        </div>

                        <Grid
                            container
                            direction="column"
                            alignItems="center"
                            justify="center"
                            spacing={1}
                        >
                            <Grid 
                                container 
                                direction="row" 
                                justify="space-between"
                                alignItems="center"
                            >
                                <Text
                                    align="center"
                                    small
                                    display="inline-block"
                                >
                                    Block only this post?
                                </Text>
                           
                                <Button
                                    onClick={this._blockPost}
                                    color="primary"
                                >
                                    Confirm
                                </Button>
                            </Grid>

                            <Grid item xs={12} >
                                <Text
                                    align="center"
                                    small
                                    display="inline-block"
                                >
                                    or
                                </Text>
                            </Grid>

                            <Grid 
                                container 
                                direction="row" 
                                justify="space-between"
                                alignItems="center"
                            >
                                <Text
                                    align="center"
                                    small
                                    display="inline-block"
                                >
                                    Block all of this users content?
                                </Text>
                            
                                <Button
                                    onClick={this._blockUser}
                                    color="primary"
                                >
                                    Confirm
                                </Button>
                            </Grid>
                        </Grid>
                        
                        {this.state.loading && <Loader />}
                    </div>
                </div>
            </Modal>
        );
    }
}


/**
 * 
 * @param {*} theme 
 */
const styles = (theme) => ({
    closeModalIcon: globalStyles.closeModalIcon,
    content: {
        [theme.breakpoints.down('xs')]: {
            width: '95%'
        },
        margin: '0 auto',
        width: 500
    },
    modal: {
        height: '90%',
        left: '50%',
        margin: '0',
        outline: 'none',
        overflow: 'auto',
        position: 'absolute',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        WebkitTransform: 'translate(-50%, -50%)'
        
    },
    modalBody: {
        alignItems: 'center',
        backgroundColor: colors.white,
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        padding: '20px',
        borderRadius: 12
    },
    titleIconWrapper: globalStyles.titleIconWrapper
});

export default withStyles(styles)(FlagModal);