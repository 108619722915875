import React from 'react';
import { withStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Chip from '@material-ui/core/Chip';

import concatTokens from '../services/concatTokens';

/**
 * @param props
 * @returns {*}
 * @constructor
 */
const Chips = (props) => {
    const { classes, chipData, onDelete, variant, customStyle } = props;

    return (
        <div className={`${ classes.chipBlock } ${ customStyle }`}>
            {chipData.length > 0 &&
                chipData.map((chip) => (
                    <Chip
                        key={concatTokens(chip, chip)}
                        label={chip}
                        color="primary"
                        deleteIcon={
                            <CloseIcon className={classes.closeChipIcon} />
                        }
                        variant={variant || 'default'}
                        onDelete={() => onDelete(chip)}
                        className={`${ classes.chip }`}
                    />
                ))}
        </div>
    );
};

/**
 * @param theme
 */
const styles = (theme) => ({
    // Todo: Add global styles from UI library
    chipBlock: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexWrap: 'wrap'
    },
    closeChipIcon: {
        color: 'inherit',
        fontSize: '18px',
        '&:hover': {
            filter: 'brightness(75%)'
        }
    },
    chip: {
        margin: '7.5px',
        marginLeft: '2.5px',
        marginRight: '2.5px',
        boxShadow:
			'0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)'
    }
});

export default withStyles(styles)(Chips);