import request from './request';

/**
 * @param {*} weddingId
 */
function getGuests(weddingId) {
    const options = {
        endpoint: `/guests?weddingId=${ weddingId }`,
        method: 'GET'
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param {*} body
 */
function addGuest(body) {
    const options = {
        endpoint: '/addGuest',
        method: 'POST',
        body
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * Add guests form
 * @param body
 * @param weddingId
 * @returns {Promise<any>}
 */
function uploadGuestForm(body, weddingId) {
    const options = {
        endpoint: `/addGuests/${ weddingId }`,
        method: 'POST',
        noJson: true,
        body
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

export {
    getGuests,
    addGuest,
    uploadGuestForm
};