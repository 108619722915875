import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles } from '@material-ui/core/styles';
import { colors } from '../theme';

class SearchBar extends Component {
    state = {
        query: ''
    };

    _handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            this.props.setState(event.target.value);
        }

        this.props.setState(event.target.value);
    };

    _onChange = (event) => {
        this.setState({
            query: event.target.value
        });
    };

    render() {
        let { onBlur, customStyle, placeholder, iconColor } = this.props;
        customStyle = customStyle || {};

        return (
            <TextField
                variant="outlined"
                value={this.state.query}
                label={placeholder || 'Search'}
                style={{
                    ...customStyle
                }}
                onBlur={onBlur}
                // className={classes.root}
                onChange={this._onChange}
                onKeyDown={this._handleKeyDown}
                onFocus={(e) => e.target.select()}
                type="text"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                // onClick={() => search(this.state.query)}
                                aria-label="Search"
                            >
                                <SearchIcon
                                    style={{ color: iconColor || null }}
                                />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
        );
    }
}

/**
 *
 * @param {*} theme
 */
const styles = (theme) => ({
    root: {
        background: colors.surface
    }
});

SearchBar.propTypes = {
    classes: PropTypes.object
};

export default withStyles(styles)(SearchBar);