import request from './request';

/**
 * @param {*} body
 */
function signup(body) {
    const options = {
        endpoint: '/signup',
        method: 'POST',
        skipAuth: true,
        body
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param {*} body
 */
function addWeddingDetails(body) {
    const options = {
        endpoint: '/updateWedding',
        method: 'POST',
        body
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param userID
 * @param accountID
 * @returns {Promise<any>}
 */
function getSignupData(userID, accountID) {
    const options = {
        endpoint: `/user/signup/${ accountID }/${ userID }`,
        method: 'GET',
        skipAuth: true
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

export {
    signup,
    getSignupData,
    addWeddingDetails
};