/* eslint-disable camelcase */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { DateTimePicker } from "@material-ui/pickers";

import {
    updateFormField,
    addWeddingDetails
} from '../../../actions/signup';
import { TextInput, Text, Button } from '../../../components';
import { globalStyles, colors } from '../../../theme';
import validateInput from '../../../services/FormValidation/validateInput';

class Wedding extends PureComponent {
    state = {
        weddingId: this.props.wedding.id || '',
        date: '',
        name: '',
        theme: 'Basic',
        notifyText: false,
        notifyTextColor: ''
    };

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            weddingId: nextProps.wedding.id
        });
    }

    _onBlur = (type) => {
        // update state with new form field value so it sticks when switching between views
        this.props.dispatch(updateFormField('wedding', type, this.state[type]));
    };

    _handleInputChange = (type, event) => {
        this.setState({
            [type]: event.target.value
        });
    };

    _handleDateChange = async (type, moment) => {
        await this.setState({
            [type]: moment._d
        });
    };

    _isFormEmpty = () => {
        const { weddingId, date, name, theme } = this.state;

        if (weddingId === '' && date === '' && name === '' && theme === '') {
            return false;
        }

        return this._saveWedding();
    };

    _validateForm = () => {
        const { name, date, weddingId, theme } = this.state;

        const nameError = validateInput('name', name);
        const dateError = validateInput('date', date);
        const weddingIdError = validateInput('presence', weddingId);
        const themeError = validateInput('presence', theme);

        this.setState({
            nameError,
            dateError,
            weddingIdError,
            themeError
        });

        if (nameError || dateError || weddingIdError || themeError) {
            return false;
        }

        return true;
    };

    /**
     * Called after age validation
     * @private
     */
    _saveWedding = async () => {
        const isValid = await this._validateForm();
        if (!isValid) {
            return null;
        }

        const { name, date, weddingId, theme } = this.state;

        const data = {
            name,
            date,
            weddingId, 
            theme
        };

        const createWedding = await this.props.dispatch(addWeddingDetails(data));

        if (createWedding) {
            this.props.stepCompletion(data, 'weddingDetails');
        }

        return true;
    };

    _goBack = () => {
        this.props.goBack();
    };

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.stepperWrapper}>
                <Text
                    align="center"
                    largest
                    bold
                    // margin="0px 0px 10px 0px"
                    display="block"
                >
                    Tell Us About Your Special Day...
                </Text>

                <div className={classes.stepperForm}>
                    <TextInput
                        variant="outlined"
                        label="Name of the Wedding"
                        autoFocus
                        value={this.state.name}
                        onBlur={() => this._onBlur('name')}
                        onChange={(value) => this._handleInputChange('name', value)}
                        error={Boolean(this.state.nameError)}
                        helperText={this.state.nameError}
                        fullWidth
                    />

                    <DateTimePicker
                        inputVariant="outlined"
                        label="Service Date"
                        value={this.state.date}
                        onBlur={() => this._onBlur('date')}
                        onChange={(value) => this._handleDateChange('date', value)}
                        error={Boolean(this.state.dateError)}
                        helperText={this.state.dateError}
                        style={{ marginTop: 16, marginBottom: 8 }}
                        fullWidth
                    />

                    <TextInput
                        variant="outlined"
                        label="Select Theme"
                        value={this.state.theme}
                        onBlur={() => this._onBlur('theme')}
                        onChange={(value) => this._handleInputChange('theme', value)}
                        error={Boolean(this.state.themeError)}
                        helperText={this.state.themeError}
                        select 
                        selectOptions={
                            [
                                'Basic',
                                'Pink',
                                'Gothic',
                                'Custom'
                            ]
                        } 
                        fullWidth
                    />

                    <div className={classes.stepperBtnContainer}>
                        <Button
                            className={classes.stepperNextBtn}
                            onClick={this._isFormEmpty}
                            color="secondary"
                        >
                            Next
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

/**
 *
 * @param {*} theme
 */
const styles = (theme) => ({
    dependentIcon: {
        cursor: 'pointer',
        backgroundColor: colors.surface,
        padding: '2.5px 10px 2.5px 10px',
        borderRadius: '4px',
        '&:hover': {
            filter: 'brightness(75%)'
        }
    },
    stepperWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around'
    },
    saveContainer: {
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'space-between'
    },
    stepperBackBtn: {
        margin: '0px auto 0px 0px !important'
    },
    stepperBtnContainer: globalStyles.stepperBtnContainer,
    stepperForm: globalStyles.stepperForm,
    stepperNextBtn: globalStyles.stepperNextBtn
});

Wedding.propTypes = {
    wedding: PropTypes.object
};

/**
 *
 * @param {*} state
 */
const mapStateToProps = (state) => {
    console.log(
        // "Wedding",
        // state
    );

    return {
        wedding: state.signup.wedding
    };
};

export default withStyles(styles)(connect(mapStateToProps)(Wedding));