import initialState from '../initialState';
import {
    SHOW_NOTIFICATION,
    HIDE_NOTIFICATION
} from '../../actions/types/notifier';

export default (state = initialState.notifier, action) => {
    switch (action.type) {
        case SHOW_NOTIFICATION: {
            return {
                ...state,
                showing: true,
                verbiage: action.verbiage || 'Something failed, please try again.',
                type: action.notifierType || 'error'
            };
        }

        case HIDE_NOTIFICATION: {
            return {
                ...state,
                showing: false
            };
        }

        default:
            return state;
    }
};