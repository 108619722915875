import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import AddGuestModal from './AddGuestModal.js';
import { globalStyles } from '../../theme';
import { Text, Loader, SearchBar, Title, Button } from '../../components';


class GuestList extends PureComponent {
    state = {
        // expanded: [],
        addGuestModal: false,
        filter: ''
    };

    _search = (query) => {
        // this.props.dispatch(sendQuery(query));
    };

    _handleFilter = (value) => {
        this.setState({ filter: value });
    };

    _handleChange = (panel) => (event, expanded) => {
        this.setState({
            expanded: this.state.expanded.push(panel)
        });

        console.log(this.state.expanded);
    };

    _toggleAddGuest = () => {
        this.setState({
            addGuestModal: !this.state.addGuestModal
        });
    };

    render() {
        const { classes, guests } = this.props;
        
        const filterString = this.state.filter.toLowerCase();

        return (
            <div className={classes.container}>
                <div className={classes.titleButton}>
                    <Title title="Guest Portal" />
                    
                    <Button
                        onClick={this._toggleAddGuest}
                        color="secondary"
                    >
                        Add Guest(s)
                    </Button>
                </div>

                <div className={classes.content}>
                    {/* <Text medium bold margin={'20px 0 0 0'}>What is the status of my guest?</Text> */}

                    <SearchBar
                        setState={this._handleFilter}
                        placeholder="Search..."
                        // iconColor={colors.skyBlue}
                        customStyle={{
                            margin: '20px auto',
                            width: '100%'
                        }}
                    />

                    {guests.map((guest, i) => {
                        if (this.state.filter !== '') {
                            if ((guest.firstName.toLowerCase().indexOf(filterString) > -1) ||
                                (guest.lastName.toLowerCase().indexOf(filterString) > -1) ||
                                (guest.role.role.toLowerCase().indexOf(filterString) > -1) ||
                                (guest.email.toLowerCase().indexOf(filterString) > -1)) {
                                return (
                                    <ExpansionPanel key={guest.email}>
                                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                            <Text color="inherit" large>{i + 1}. {guest.firstName} {guest.lastName}</Text>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails className={classes.panelDetails}>
                                            <div className={classes.row}>
                                                <Text mediumLarge customStyle={{ flex: 1 }}>Email: {guest.email}</Text>
                                            </div>
                                            <div className={classes.row}>
                                                <Text mediumLarge customStyle={{ flex: 1 }}>Role: {guest.role.role}</Text>
                                            </div>
                                            <div className={classes.row}>
                                                <Text mediumLarge customStyle={{ flex: 1 }}>Enrolled: {guest.enrolled ? 'Yes' : 'No'}</Text>
                                            </div>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                );
                            }
         
                            return null;
                        }
                        
                        return (
                            <ExpansionPanel key={guest.email}>
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                    <Text color="inherit" large>{i + 1}. {guest.firstName} {guest.lastName}</Text>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails className={classes.panelDetails}>
                                    <div className={classes.row}>
                                        <Text mediumLarge customStyle={{ flex: 1 }}>Email: {guest.email}</Text>
                                    </div>
                                    <div className={classes.row}>
                                        <Text mediumLarge customStyle={{ flex: 1 }}>Role: {guest.role.role}</Text>
                                    </div>
                                    <div className={classes.row}>
                                        <Text mediumLarge customStyle={{ flex: 1 }}>Enrolled: {guest.enrolled ? 'Yes' : 'No'}</Text>
                                    </div>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        );
                    }
                    )}

                    {/* {
                        this.props.guests && this.props.guests.map((guest, i) => (
                            <ExpansionPanel key={guest.email}>
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                    <Text color="inherit" large>{i + 1}. {guest.firstName} {guest.lastName}</Text>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails className={classes.panelDetails}>
                                    <div className={classes.row}>
                                        <Text mediumLarge customStyle={{ flex: 1 }}>Email: {guest.email}</Text>
                                    </div>
                                    <div className={classes.row}>
                                        <Text mediumLarge customStyle={{ flex: 1 }}>Role: {guest.role.role}</Text>
                                    </div>
                                    <div className={classes.row}>
                                        <Text mediumLarge customStyle={{ flex: 1 }}>Enrolled: {guest.enrolled ? 'Yes' : 'No'}</Text>
                                    </div>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        ))
                    } */}

                    {this.state.addGuestModal && (
                        <AddGuestModal
                            close={() => this._toggleAddGuest()}
                        />
                    )}

                    <div className={classes.spacer}>
                        <Text
                            tiny
                        >
                        Copyright © The Moseley Bros {new Date().getFullYear()}{'.'}
                        </Text>
                    </div>
                 
                </div>

                {this.props.loading && <Loader />}

                <span id="coverage"></span>
            </div>
        );
    }
}

const styles = (theme) => ({
    container: globalStyles.container,
    content: {
        
    },
    panelDetails: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column'
    },
    titleButton: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        flexDirection: 'row'
    },
    row: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center'
    },
    title: {
        flex: 1
    },
    spacer: {
        height: 100,
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end'
    }
});

GuestList.propTypes = {
    loading: PropTypes.bool
};

const mapStateToProps = (state) => ({
    loading: state.guests.loading,
    guests: state.guests.items
});

export default withStyles(styles)(connect(mapStateToProps)(GuestList));