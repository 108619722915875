import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu/Menu';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import FavoriteIcon from '@material-ui/icons/Favorite';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { addLike, blockPost, blockUser, removeLike, submitComment } from '../actions/posts';
import { Button, FlagModal, Text, TextInput } from '../components';
import validateInput from '../services/FormValidation/validateInput';
import { generateUserName } from '../services/helpers';
import { colors } from '../theme';


class PostCard extends PureComponent {
    state = {
        comment: false,
        reply: false,
        likes: false,
        viewComments: true,
        anchorEl: null,
        showFlagModal: false
    };

    componentDidMount() {
        let newArr = [];

        this.props.likes && this.props.likes.map((item) => (
            newArr.push(item.userId)
        ));

        this.setState({
            likes: newArr
        });
    }

    _handleMenuClick = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    _handleCloseMenu = () => {
        this.setState({ anchorEl: null });
    };

    _toggleModal = () => {
        this.setState({
            showFlagModal: !this.state.showFlagModal
        });
    };

    _toggle = () => {
        this.setState({
            comment: ''
        });
    };

    _toggleComments = () => {
        this.setState({
            viewComments: !this.state.viewComments
        });
    };

    _onBlur = (type) => {
        this.setState({
            [`${ type }Error`]: validateInput(type, this.state[type])
        });
    };

    _handleInputChange = (type, event) => {
        this.setState({
            [type]: event.target.value
        });
    };

    lastTap = null;

    _handleDoubleTap = (user) => {
        const now = Date.now();
        const delay = 300;
        if (this.lastTap && now - this.lastTap < delay) {
            this._onClickHeart(user);
        } else {
            this.lastTap = now;
        }
    };

    _onClickHeart = async (user) => {
        if (this.state.likes.includes(user.id)) {
            const newArray = this.state.likes.filter((userId) => userId !== user.id);

            await this.setState({
                likes: newArray
            });

            await this.props.dispatch(removeLike(this.props.postId));
        } else {
            await this.setState({
                likes: [ ...this.state.likes, user.id ]
            });

            await this.props.dispatch(addLike(this.props.postId));
        }
    };

    _validateForm = () => {
        const commentError = validateInput('comment', this.state.comment);

        this.setState({
            commentError
        });

        if (
            commentError
        ) {
            return false;
        }

        return true;
    };

    _submit = async () => {
        const isFormValid = await this._validateForm();

        const body = {
            postId: this.props.postId,
            comment: this.state.comment
        };

        let submitted = false;

        if (isFormValid) {
            submitted = await this.props.dispatch(submitComment(body));
        }

        if (submitted) {
            this.setState({
                comment: false
            });
        }
    }

    _editPost = (postId, userId) => {
        const body = {
            postId
        };

        return body;
    }
    

    _deleteComment = (commentId) => {
        // const body = {
        //     commentId: commentId
        // };

        window.alert(
            'Delete Comment?',
            'Are you sure?',
            [
                {
                    text: 'Cancel',
                    style: 'cancel'
                },
                { text: 'OK', onPress: async () => await console.log('this.props.dispatch(deleteComment(body))') }
            ]
        );

        return null;
    }

    render() {
        const { classes, image, content, user, blurb, guests, postId, posts, userId, wedding } = this.props;
        const { likes, viewComments, showFlagModal } = this.state;

        const current = posts.filter((post) => post.id === postId);
        const comments = current[0].comments;

        return (
            
            <div>
                <div className={classes.imageContainer} onClick={() => this._handleDoubleTap(user)}>
                    <img
                        className={classes.image}
                        // onClick={() => this._handleDoubleTap(user)}
                        src={image}
                        alt={content}
                    />
                </div>

                <div className={classes.container}>
                    <div className={classes.contentContainer}>
                        <Text
                            mediumLarge
                            marginTop={10}
                            paddingRight={20}
                            color="white"
                        >
                            {content}
                        </Text>

                        <IconButton
                            aria-label="Edit"
                            onClick={this._handleMenuClick}
                        >
                            <MoreVertIcon color="secondary" />
                        </IconButton>
                    </div>

                    {
                        blurb && 
                            <Text
                                small
                                light
                                marginBottom={10}
                                color="white"
                            >
                                {blurb}
                            </Text>
                    }
                    
                    {likes.length > 0 &&
                    <div className={classes.likesContainer}>
                        {likes && (
                            <React.Fragment>
                                <IconButton 
                                    aria-label="Edit"
                                    onClick={() => this._onClickHeart(user)}
                                >
                                    <FavoriteIcon color="error" />
                                </IconButton>
                                <Text
                                    tiny
                                    light
                                    color="white"
                                >
                                    {` ${ likes.length }     Liked by: `}
                                </Text>
                            </React.Fragment>
                        )}

                        {likes && likes.map((like, i) => {
                            let likedByName = '';

                            guests.map((guest) => {
                                if (guest.id === like) {
                                    // likedByName = `${ guest.firstName } ${ guest.lastName }`;
                                    likedByName = guest.firstName;

                                    return true;
                                }

                                return true;
                            });

                            if (i < 1) {
                                return (
                                    <Text
                                        key={like}
                                        tiny
                                        light
                                        color="white"
                                    >
                                        {` ${ likedByName }, `}
                                    </Text>
                                );
                            }

                            if (i < 2) {
                                return (
                                    <Text
                                        key={like}
                                        tiny
                                        light
                                        color="white"
                                    >
                                        {` ${ likedByName }, `}
                                    </Text>
                                );
                            }

                            return true;
                        })}

                        {likes && likes.length > 2 && (
                            <Text 
                                tiny 
                                light
                                color="white"
                            >
                                and more...
                            </Text>
                        )}
                    </div>
                    }

                    {comments && comments.map((comment, i) => {
                        let index = comments.length;

                        if (viewComments) {
                            return (
                                <div onClick={
                                    comment.userId === user.id ? 
                                        () => this._deleteComment(comment.id)
                                        : 
                                        null
                                }
                                key={comment.id}
                                className={classes.comments}
                                >
                                    <Text
                                        tiny
                                        color="white"
                                    >
                                        {generateUserName(guests, comment.userId)}:&nbsp;
                                    </Text>
                                    <Text
                                        tiny
                                        color="white"
                                    >
                                        {comment.content}
                                    </Text>
                                </div>
                            ); 
                        }

                        if (i === 0) {
                            return (
                                <Text
                                    key={comment.id}
                                    onClick={this._toggleComments}
                                    tiny
                                    light
                                    color="white"
                                >
                                    {
                                        index === 0 ? 
                                            `View ${ index } more comment` 
                                            : 
                                            `View ${ index } more comments`
                                    }
                                </Text>
                            );
                        }

                        return null;
                    })}

                    <div className={classes.commentContainer}>
                        {
                            this.state.comment !== false ?
                                <React.Fragment>
                                    <TextInput
                                        label="comment"
                                        type="text"
                                        autoFocus
                                        value={this.state.comment}
                                        onBlur={() => this._onBlur('comment')}
                                        onChange={(value) => this._handleInputChange('comment', value)}
                                        error={this.state.commentError}
                                        customInputStyle={classes.commentInput}
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                    />
                                    <Button
                                        variant="text"
                                        color="primary"
                                        onClick={this._submit}
                                    >
                                        Submit
                                    </Button>
                                </React.Fragment>
                                : 
                                <div onClick={this._toggle}>
                                    <Text
                                        tiny
                                        marginTop={10}
                                        paddingRight={20}
                                        color="white"
                                    >
                                        Leave a comment....
                                    </Text>
                                </div>
                        }

                        <Menu
                            anchorEl={this.state.anchorEl}
                            open={Boolean(this.state.anchorEl)}
                            onClose={this._handleCloseMenu}
                            disableAutoFocusItem={true}
                        >
                            {
                                user.id === userId ? 
                                    <MenuItem onClick={() => this._editPost(postId, userId)}>
                                        <Text color="inherit" medium display="inline">
                                            Edit
                                        </Text>
                                    </MenuItem>
                                    :
                                    <MenuItem onClick={this._toggleModal}>
                                        <Text color="inherit" medium display="inline">
                                            Report.....
                                        </Text>
                                    </MenuItem>
                            }
                        </Menu>

                        {
                            showFlagModal &&
                                <FlagModal
                                    hideModal={this._toggleModal}
                                    showModal={showFlagModal}
                                    blockPost={() => this.props.dispatch(blockPost(postId, wedding.weddingId))}
                                    blockUser={() => this.props.dispatch(blockUser(userId, wedding.weddingId))}
                                    backToMain={this.props.close}
                                />
                        }
                    </div>
                </div>
            </div>
        );
    }
}

/**
 *
 * @param {*} theme
 */
const styles = (theme) => ({
    container: {
        padding: 10,
        flexDirection: 'column',
        backgroundColor: colors.imageTextBG,
        width: 'fit-content',
        position: 'absolute',
        top: '30%'

    },
    imageContainer: {
        width: '100%',
        height: '40vh'
    },
    contentContainer: {
        width: '100%', 
        display: 'flex', 
        flexDirection: 'row', 
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    likesContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: '10px'
    },
    commentContainer: {
        marginTop: 10,
        marginBottom: 30
    },
    comments: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    commentInput: {
        color: colors.white
    },
    image: {
        // width: '100%',
        // height: 400,
        objectFit: 'cover'
    }
});

/**
 *
 * @param {*} state
 */
const mapStateToProps = (state) => ({
    loading: state.user.loading,
    posts: state.posts.items,
    wedding: state.wedding,
    guests: state.guests.items,
    user: state.user.meta
});

export default withStyles(styles)(connect(mapStateToProps)(PostCard));