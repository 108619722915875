import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { colors } from '../../theme';
import { Text } from '../../components';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center" >
            {'Copyright © '}
            <Link color="inherit" href="https://hitchedagram.com/">
              The Moseley Bros
            </Link>{' '}
            {new Date().getFullYear()}
            {'. | '}
            <Link color="inherit" href="/terms_of_service.html">
              Terms of Service
            </Link>
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    footer: {
        backgroundColor: colors.darkGrey,
        // marginTop: theme.spacing(8),
        padding: theme.spacing(6, 0)
    }
}));

export default function Footer(props) {
    const classes = useStyles();
    const { description, title } = props;

    // console.log(theme);

    return (
        <footer className={classes.footer}>
            <Container maxWidth="lg">
                <Text
                    align="center"
                    largest
                    customStyle={{ fontFamily: 'Billabong', color: colors.white }}
                >
                    {title}
                </Text>
                <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
                    {description}
                </Typography>
                <Copyright />
            </Container>
        </footer>
    );
}

Footer.propTypes = {
    description: PropTypes.string,
    title: PropTypes.string
};