import React, { Component } from 'react';
import { connect } from 'react-redux';

import { TextInput, FormModal, DropZoneModal, Button } from '../../components';
import validateInput from '../../services/FormValidation/validateInput';

import { addGuest, uploadGuestForm } from '../../actions/guests';

class AddGuestModal extends Component {
    state = {
        firstName: '',
        lastName: '',
        email: '',
        weddingId: '',
        role: '',
        showDropZone: false
    };

    async componentDidMount() {
        await this.setState({ 
            weddingId: this.props.wedding.weddingId
        });
    }

    _submitFile = async (files) => {
        const form = new FormData();
        form.append('file', files[0]);

        console.log(files);

        if (files[0].type === 'text/csv' || files[0].type === 'text/xls') {
            await this.props.dispatch(uploadGuestForm(form, this.state.weddingId));
        } else {
            alert('Must be a .csv or a .xls.');
        }
    };

    _toggle = (type) => {
        this.setState({
            [type]: !this.state[type]
        });
    }

    _onBlur = (type) => {
        this.setState({
            [`${ type }Error`]: validateInput(type, this.state[type])
        });
    };

    _handleInputChange = (type, event) => {
        this.setState({
            [type]: event.target.value
        });
    };

    _isFormEmpty = () => {
        const { firstName, lastName, email, role, weddingId } = this.state;

        if (firstName === '' && lastName === '' && email === '' && role === '' && weddingId === '') {
            return false;
        }

        return this._saveGuest();
    };

    _validateForm = () => {
        const { firstName, lastName, email, role, weddingId } = this.state;

        const firstnameError = validateInput('name', firstName);
        const lastnameError = validateInput('name', lastName);
        const emailError = validateInput('email', email);
        const roleError = validateInput('presence', role);
        const weddingIdError = validateInput('presence', weddingId);

        this.setState({
            firstnameError,
            lastnameError,
            emailError,
            roleError, 
            weddingIdError
        });

        if (firstnameError || lastnameError || emailError || roleError || weddingIdError) {
            return false;
        }

        return true;
    };

    _saveGuest = async () => {
        const isValid = await this._validateForm();
        if (!isValid) {

            console.log('Invalid form');
            return null;
        }

        const { firstName, lastName, email, role, weddingId } = this.state;

        const data = {
            firstName: firstName.trim(),
            lastName: lastName.trim(),
            email: email.trim(),
            weddingId,
            role
        };

        const success = await this.props.dispatch(addGuest(data));

        if (success) {
            this.props.close();
        }

        return true;
    };

    render() {
        return (
            <FormModal
                open={true}
                close={this.props.close}
                // title="Add Guest"
                disableBackdropClick
                disableEscapeKeyDown
                onSave={this._isFormEmpty}
            >
                {
                    this.state.showDropZone &&
                        <DropZoneModal
                            title="Select .csv or .xls files"
                            hideModal={() => this._toggle('showDropZone')}
                            showModal={this.state.showDropZone}
                            maxItems={1}
                            type="Guest List Form"
                            submitFile={this._submitFile}
                            acceptedFiles="application/vnd.ms-excel, text/csv, application/xlsx"
                        />
                }

                <Button
                    onClick={() => this._toggle('showDropZone')}
                    transparent
                >
                    Upload .csv
                </Button>

                <TextInput
                    variant="outlined"
                    label="First Name"
                    autoFocus
                    value={this.state.firstName}
                    onBlur={() => this._onBlur('firstName')}
                    onChange={(value) => this._handleInputChange('firstName', value)}
                    error={Boolean(this.state.firstnameError)}
                    helperText={this.state.firstnameError}
                    fullWidth
                />

                <TextInput
                    variant="outlined"
                    label="Last Name"
                    value={this.state.lastName}
                    onBlur={() => this._onBlur('lastName')}
                    onChange={(value) => this._handleInputChange('lastName', value)}
                    error={Boolean(this.state.lastnameError)}
                    helperText={this.state.lastnameError}
                    fullWidth
                />

                <TextInput
                    variant="outlined"
                    label="Email"
                    value={this.state.email}
                    onBlur={() => this._onBlur('email')}
                    onChange={(value) => this._handleInputChange('email', value)}
                    error={Boolean(this.state.emailError)}
                    helperText={this.state.emailError}
                    fullWidth
                />

                <TextInput
                    variant="outlined"
                    label="Select Role(Default is Guest)"
                    value={this.state.role}
                    onBlur={() => this._onBlur('role')}
                    onChange={(value) => this._handleInputChange('role', value)}
                    error={Boolean(this.state.roleError)}
                    helperText={this.state.roleError}
                    select 
                    selectOptions={
                        [
                            'guest',
                            'admin',
                            'owner'
                        ]
                    } 
                    fullWidth
                />
            </FormModal>
        );
    }
}

/**
 *
 * @param {*} state
 */
const mapStateToProps = (state) => ({
    loading: state.user.loading,
    meta: state.user.meta,
    wedding: state.wedding
});

export default connect(mapStateToProps)(AddGuestModal);