import { colors, fonts } from './index';

// This file is for a reusable grouping of Theme items.

const globalStyles = {
    arrowIcon: {
        color: 'inherit',
        fontSize: '16px',
        lineHeight: 1.4
    },
    blockRow: {
        alignItems: 'center',
        backgroundColor: colors.subheaderGrey,
        cursor: 'pointer',
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        height: 60,
        marginBottom: 5
    },
    btnBlock: {
        alignItems: 'flex-end',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    cardBlock: {
        '&:hover': {
            borderLeft: `${ colors.mediumBlue } solid 5px`
        },
        backgroundColor: colors.surface,
        borderLeft: `${ colors.surface } solid 5px`,
        boxShadow: '0 1px 2px rgba(0, 0, 0, .15)',
        cursor: 'pointer',
        height: 'auto',
        marginBottom: '8px',
        minHeight: '90px',
        padding: '20px 30px 2px 25px',
        position: 'relative'
    },
    cardTitle: {
        display: 'block',
        fontFamily: fonts.type.base,
        fontSize: '16px',
        lineHeight: '24px',
        margin: '0',
        padding: '0',
        textTransform: 'capitalize'
    },
    closeModalIcon: {
        color: colors.mainTextGrey,
        cursor: 'pointer',
        display: 'inline',
        float: 'right',
        fontSize: '22px',
        marginTop: '5px'
    },
    container: {
        backgroundColor: colors.surface,
        height: 'auto',
        margin: '0 auto',
        minHeight: '100vh',
        padding: 30,
        paddingBottom: 1,
        paddingTop: 30,
        position: 'relative',
        width: '100%',
        maxWidth: 900
    },
    containerControlSite: {
        // backgroundColor: colors.backgroundGrey,
        backgroundColor: colors.surface,
        height: 'auto',
        margin: '0',
        minHeight: '100vh',
        padding: '0',
        paddingBottom: '1px',
        position: 'relative',
        width: '100%'
    },
    content: {
        '@media (max-width: 400px)': {
            width: '98%'
        },
        '@media (max-width: 600px)': {
            width: '90%'
        },
        '@media (max-width: 1500px)': {
            width: '80%'
        },
        '@media (max-width: 1900px)': {
            width: '75%'
        },
        margin: '0 auto',
        width: '55%',
        minHeight: '75vh'
    },
    contentSidebarSite: {
        backgroundColor: colors.surface,
        '@media (max-width: 1500px)': {
            width: '90%'
        },
        '@media (max-width: 600px)': {
            width: '95%'
        },
        '@media (max-width: 400px)': {
            width: '98%'
        },
        margin: '20px auto',
        width: '75%'
    },
    contentPublicSite: {
        '@media (max-width: 400px)': {
            width: '98%'
        },
        '@media (max-width: 600px)': {
            width: '95%'
        },
        '@media (max-width: 1500px)': {
            width: '90%'
        },
        margin: '20px auto',
        width: '75%'
    },
    contentBlock: {
        backgroundColor: colors.surface,
        boxShadow: '0 1px 2px rgba(0, 0, 0, .15)',
        display: 'block',
        margin: 'auto auto 30px auto',
        padding: '30px',
        position: 'relative'
    },
    contentMargin: {
        marginTop: '30px'
    },
    contentSmall: {
        '@media (max-width: 400px)': {
            width: '98%'
        },
        '@media (max-width: 600px)': {
            width: '95%'
        },
        '@media (max-width: 1500px)': {
            width: '75%'
        },
        '@media (max-width: 2000px)': {
            width: '60%'
        },
        margin: '20px auto',
        width: '45%'
    },
    expansionRoot: {
        boxShadow: 'none'
    },
    expansionSummary: {
        padding: '0'
    },
    flexCenter: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    flexCenterRow: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    flexWrap: {
        flexWrap: 'wrap'
    },
    forgotBtn: {
        color: colors.mediumDarkGrey,
        fontFamily: fonts.type.medium,
        fontSize: '12px',
        paddingBottom: '1px',
        paddingLeft: '0',
        paddingRight: '0',
        paddingTop: '0',
        textTransform: 'capitalize'
    },
    form: {
        backgroundColor: colors.surface,
        // boxShadow: `0 1px 2px ${ colors.gradientBlue }`,
        margin: '20px auto 50px auto',
        maxWidth: '100%',
        padding: '20px',
        position: 'relative',
        width: 384,
        borderRadius: 8,
        display: 'flex',
        flexDirection: 'column'
    },
    inputWithAdornment: {
        width: '100%'
    },
    logo: {
        display: 'block',
        margin: '0 auto 10px',
        maxHeight: '220px',
        maxWidth: '350px',
        paddingBottom: '25px',
        paddingTop: '35px'
    },
    memberCard: {
        cursor: 'pointer',
        marginBottom: '0',
        minHeight: '50px',
        padding: '10px 10px 10px 10px'
    },
    modal: {
        height: '90%',
        left: '50%',
        margin: '0',
        outline: 'none',
        overflow: 'auto',
        position: 'absolute',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        WebkitTransform: 'translate(-50%, -50%)'
    },
    modalBody: {
        alignItems: 'center',
        backgroundColor: colors.white,
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        padding: '20px'
    },
    modalBtn: {
        '&:hover': {
            backgroundColor: colors.clear
        },
        backgroundColor: colors.clear,
        boxShadow: 'none',
        color: colors.skyBlue,
        fontSize: fonts.size.label,
        paddingLeft: '0',
        paddingRight: '0',
        textAlign: 'right',
        width: '50px'
    },
    modalTitleBlock: {
        textAlign: 'center',
        width: '100%'
    },
    scrollbar: {
        '&::-webkit-scrollbar': {
            height: '15px',
            width: '15px'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: colors.surface,
            borderRadius: '10px',
            boxShadow: `inset 0 0 2px ${ colors.scrollbarGrey }`
        },
        '&::-webkit-scrollbar-track': {
            // backgroundColor: colors.backgroundGrey,
            backgroundColor: colors.surface,
            borderRadius: '10px',
            boxShadow: `inset 0 0 2px ${ colors.scrollbarLightGrey }`
        }
    },
    singleLineCard: {
        cursor: 'default',
        marginBottom: '0',
        minHeight: '30px',
        padding: '10px'
    },
    stepper: {
        '@media (max-width: 500px)': {
            boxShadow: 'none',
            padding: 0
        },
        backgroundColor: colors.surface,
        boxShadow: '0 1px 2px rgba(0,0,0,.15)',
        // margin: "0 auto 50px auto",
        maxWidth: '50%',
        padding: 30,
        position: 'relative',
        width: 480
    },
    stepperBackBtn: {
        float: 'left'
    },
    stepperBtnContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 40
    },
    stepperFinishBtn: {
        float: 'right'
    },
    stepperForm: {
        backgroundColor: colors.surface,
        boxShadow: 'none',
        // margin: '0 auto 0 auto',
        // maxWidth: '50%',
        padding: '0 0 20px 0',
        position: 'relative',
        width: 384
    },
    stepperNextBtn: {
        float: 'right',
        marginRight: '5px !important'
    },
    table: {
        display: 'block',
        overflowX: 'auto',
        paddingBottom: '20px',
        width: '100%'
    },
    tableHeading: {
        fontFamily: fonts.type.medium
    },
    tableRow: {
        '&:nth-of-type(odd)': {
            backgroundColor: colors.backgroundGrey
        }
    },
    tabRoot: {
        '&:focus': {
            color: colors.skyBlue
        },
        '&:hover': {
            color: colors.skyBlue,
            opacity: 1
        },
        fontSize: 16,
        textTransform: 'initial'
    },
    tabSelected: {
        color: colors.skyBlue
    },
    tabsIndicator: {
        backgroundColor: colors.mediumBlue
    },
    tabsRoot: {
        borderBottom: `1px solid ${ colors.borderGrey }`
    },
    themeBlueBtn: {
        '&:hover': {
            backgroundColor: colors.skyBlue
        },
        backgroundColor: colors.skyBlue,
        color: colors.surface,
        fontSize: fonts.size.body
    },
    title: {
        color: colors.mainTextGrey,
        fontFamily: fonts.type.medium,
        fontSize: '20px',
        lineHeight: '40px',
        margin: 0,
        textAlign: 'center'
    },
    titleDivider: {
        borderBottom: `1px solid ${ colors.borderGrey }`,
        color: colors.mediumDarkGrey,
        fontFamily: fonts.type.base,
        fontSize: '16px',
        lineHeight: '40px',
        marginTop: '20px',
        width: '100%'
    },
    titleIconWrapper: {
        display: 'block',
        paddingBottom: '15px',
        verticalAlign: 'center',
        width: '100%'
    },
    transparentBtn: {
        '&:hover': {
            backgroundColor: colors.surface
        },
        backgroundColor: colors.clear,
        boxShadow: 'none',
        color: colors.darkBlue,
        fontSize: fonts.size.label,
        paddingLeft: '10px',
        paddingRight: '10px',
        width: 'fit-content'
    }
};

export default globalStyles;