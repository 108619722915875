import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import InputAdornment from '@material-ui/core/InputAdornment';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { withStyles } from '@material-ui/core/styles';

import { TextInput, Button, Text } from '../../../components';
import * as notifier from '../../../actions/notifier';
import { updateFormField, signupOwner } from '../../../actions/signup';
import { globalStyles } from '../../../theme';
// import * as Dates from '../../../services/Dates';
import validateInput from '../../../services/FormValidation/validateInput';

class UserDetails extends Component {
    state = {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        // gender: '',
        visibility: false
    };

    // componentDidMount() {}

    _toggleVisibility = () => {
        this.setState({
            visibility: !this.state.visibility
        });
    };

    _onBlur = (type) => {
        this.props.dispatch(updateFormField('userDetails', type, this.state[type]));
    };

    _handleInputChange = (type, event) => {
        this.setState({
            [type]: event.target.value
        });
    };

    _handleDateChange = (birthdate) => {
        this.setState({
            birthdate
        });
    };

    _saveDetails = async () => {
        const emailError = validateInput('email', this.state.email);
        const passwordError = validateInput('password', this.state.password);
        const confirmPasswordError = validateInput('confirmPassword', this.state.confirmPassword);
        const firstnameError = validateInput('firstName', this.state.firstName);
        const lastnameError = validateInput('lastName', this.state.lastName);
        // const provinceError = validateInput('province', this.state.province);
        // const genderError = validateInput('gender', this.state.gender);

        this.setState({
            passwordError,
            confirmPasswordError,
            firstnameError,
            lastnameError,
            emailError
            // provinceError,
            // genderError
        });

        if (
            emailError ||
            passwordError ||
            confirmPasswordError ||
            firstnameError ||
            lastnameError
            // provinceError ||
            // genderError ||
        ) {
            return false;
        }

        if (this.state.password !== this.state.confirmPassword) {
            this.props.dispatch(notifier.displayError({ verbiage: 'Please confirm that your passwords match.' }));

            this.setState({
                confirmPasswordError: 'Please confirm that your passwords match.',
                passwordError: 'Please confirm that your passwords match.'
            });

            return false;
        }

        const userDetails = { 
            userDetails: {
                firstName: this.state.firstName.trim(),
                lastName: this.state.lastName.trim(),
                email: this.state.email.trim(),
                password: this.state.password.trim()
            // province: this.state.province.trim(),
            // gender: this.state.gender.trim(),
            }
        };

        const createUser = await this.props.dispatch(signupOwner(userDetails));

        if (createUser) {
            this.props.stepCompletion({ ...userDetails }, 'userDetails', false);
        }

        return false;
    };

    render() {
        const { classes } = this.props;
        const {
            confirmPassword,
            email,
            firstName,
            // gender,
            lastName,
            password
            // province,
        } = this.state;

        return (
            <div className={classes.stepperWrapper}>
                <Text
                    align="center"
                    largest
                    bold
                    display="block"
                >
                    First, tell us about yourself....
                </Text>
           
                <div className={classes.stepperForm}>
                    <TextInput
                        variant="outlined"
                        label="Email"
                        type="email"
                        value={email}
                        onBlur={() => this._onBlur('email')}
                        onChange={(value) => this._handleInputChange('email', value)}
                        error={Boolean(this.state.emailError)}
                        helperText={this.state.emailError}
                        fullWidth
                        autoFocus
                        autoComplete="email"
                    />

                    <TextInput
                        variant="outlined"
                        label="First Name"
                        type="text"
                        value={firstName}
                        onBlur={() => this._onBlur('firstName')}
                        onChange={(value) => this._handleInputChange('firstName', value)}
                        error={Boolean(this.state.firstnameError)}
                        helperText={this.state.firstnameError}
                        fullWidth
                        autoComplete="given-name"
                    />

                    <TextInput
                        variant="outlined"
                        label="Last Name"
                        type="text"
                        value={lastName}
                        onBlur={() => this._onBlur('lastName')}
                        onChange={(value) => this._handleInputChange('lastName', value)}
                        error={Boolean(this.state.lastnameError)}
                        helperText={this.state.lastnameError}
                        fullWidth
                        autoComplete="family-name"
                    />

                    <TextInput
                        variant="outlined"
                        label="Password"
                        value={password}
                        inputProps={{
                            endAdornment: (
                                <InputAdornment>
                                    {
                                        this.state.visibility ?
                                            <VisibilityIcon
                                                style={{ cursor: "pointer" }}
                                                onClick={this._toggleVisibility}
                                            />
                                            :
                                            <VisibilityOffIcon
                                                style={{ cursor: "pointer" }}
                                                onClick={this._toggleVisibility}
                                            />
                                    }
                                </InputAdornment>
                            )
                        }}
                        onBlur={() => this._onBlur('password')}
                        onChange={(value) => this._handleInputChange('password', value)}
                        error={Boolean(this.state.passwordError)}
                        helperText={this.state.passwordError}
                        secure={!this.state.visibility}
                        fullWidth
                        autoComplete="new-password"
                    />

                    <TextInput
                        variant="outlined"
                        label="Confirm Password"
                        value={confirmPassword}
                        inputProps={{
                            endAdornment: (
                                <InputAdornment>
                                    {
                                        this.state.visibility ?
                                            <VisibilityIcon
                                                style={{ cursor: "pointer" }}
                                                onClick={this._toggleVisibility}
                                            />
                                            :
                                            <VisibilityOffIcon
                                                style={{ cursor: "pointer" }}
                                                onClick={this._toggleVisibility}
                                            />
                                    }
                                </InputAdornment>
                            )
                        }}
                        onBlur={() => this._onBlur('confirmPassword')}
                        onChange={(value) => this._handleInputChange('confirmPassword', value)}
                        error={Boolean(this.state.confirmPasswordError)}
                        helperText={this.state.confirmPasswordError}
                        secure={!this.state.visibility}
                        fullWidth
                        autoComplete="new-password"
                    />

                    {/* <TextInput
                    label="Gender"
                    fullWidth
                    required
                    value={gender || ''}
                    onBlur={() => this._onBlur('gender')}
                    onChange={(value) => this._handleInputChange('gender', value)}
                    error={Boolean(this.state.genderError)}
                    helperText={this.state.genderError}
                    select
                    selectOptions={selectFieldOptions.genderOptions}
                /> */}

                    {/* <TextInput
                    label="Province"
                    fullWidth
                    required
                    value={province || ''}
                    onBlur={() => this._onBlur('province')}
                    onChange={(value) => this._handleInputChange('province', value)}
                    error={Boolean(this.state.provinceError)}
                    helperText={this.state.provinceError}
                    select
                    selectOptions={selectFieldOptions.provinceOptions}
                /> */}

                    <div className={classes.stepperBtnContainer}>
                        <Link to={`/login`} className={`${ classes.stepperBackBtn }`}>
                            {/* <Button
                                className={classes.stepperNextBtn}
                                transparent
                            >
                            Cancel
                            </Button> */}
                        </Link>

                        <Button
                            className={classes.stepperNextBtn}
                            onClick={this._saveDetails}
                            color="primary"
                        >
                            Get started for free
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

/**
 *
 * @param {*} theme
 */
const styles = (theme) => ({
    visibilityIcon: {
        position: 'absolute',
        marginLeft: '-30px'
    },
    stepperBackBtn: {
        margin: '0px auto 0px 0px !important'
    },
    stepperBtnContainer: globalStyles.stepperBtnContainer,
    stepperFinishBtn: globalStyles.stepperFinishBtn,
    stepperForm: globalStyles.stepperForm,
    stepperNextBtn: globalStyles.stepperNextBtn,
    stepperWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around'
        // [theme.breakpoints.down('800')]: {
        //     flexDirection: 'column'
        // }
    }
});

/**
 *
 * @param {*} state
 */
const mapStateToProps = (state) => ({
    userDetails: state.signup.userDetails
});

export default withStyles(styles)(connect(mapStateToProps)(UserDetails));