import React, { Component } from 'react';

import { Text, FormModal } from '..';

class NoticeModal extends Component {
    state = {
        message: ''
    };

    componentDidMount() {
        this.setState({
            message: this.props.message
        });
    }

    render() {

        return (
            <FormModal
                open={true}
                close={this.props.close}
                title={this.props.title}
            >

                <Text
                    align="center"
                    mediumLarge
                >
                    {this.state.message}
                </Text>

            </FormModal>
        );
    }
}

export default NoticeModal;