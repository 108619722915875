import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Dropzone from 'react-dropzone';
import { DateTimePicker } from "@material-ui/pickers";

import { TextInput, Text, FormModal, SinglePhotoGrid, Button } from '../../components';
import validateInput from '../../services/FormValidation/validateInput';
import { updateEvent, createEvent } from '../../actions/events';
import { createPhoto, markPhotoUploaded } from '../../actions/posts';
import { uploadPhoto } from '../../xhr/photo';
import { colors } from '../../theme';
import { formatOutgoingLong } from '../../services/Dates';

// id: "f7427628-1f1c-4b76-8b15-3f3bdb91cc00"
// weddingId: "4f19beca-80a5-4279-8ff1-b838b2dac007"
// name: "Better Event"
// createdAt: "2020-01-05 09:37:46"
// updatedAt: "2020-01-05 09:37:46"
// startTime: 1578769860000
// endTime: 1580638920000
// location: "the zoo"
// notes: "notes notes notes"
// creatorId: "32cf7371-8815-4121-aa48-3060dedd7635"
// photoId: "e48c94ab-dcfc-46da-8fd8-a7a34f2d91dc"
// description: "Diligent and descriptive explanation"
// blurb: "What a time, to be alive"
// guestsAttending: ["32cf7371-8815-4121-aa48-3060dedd7635"]
// photo:
// id: "e48c94ab-dcfc-46da-8fd8-a7a34f2d91dc"
// createdAt: "2020-01-05 09:37:32"
// updatedAt: "2020-01-05 09:37:32"
// creatorId: "32cf7371-8815-4121-aa48-3060dedd7635"
// weddingId: "4f19beca-80a5-4279-8ff1-b838b2dac007"
// storageKey: "KRQWFR/1578217052965_c1icgn.jpg"
// uploadComplete: 1
// url: "https://hitchedagram-development.sfo2.digitaloceanspaces.com/KRQWFR/1578217052965_c1icgn.jpg?AWSAccessKeyId=FXZFTG7TE5KL4PWGUTRA&Expires=1578552156&Signature=9uJVrY3eFLsYEtCLQCsNjKr8wU4%3D"
// urlExpiresAt: 1578552156842

class SingleEventModal extends Component {
    state = {
        photo: {},
        photoId: '',
        name: '',
        startTime: '',
        endTime: '',
        description: '',
        blurb: '',
        location: '',
        notes: '',
        comments: [],
        guestsAttending: [],
        notifyText: false,
        notifyTextColor: '',
        isEdit: false
    };

    componentDidMount() {
        const {
            photoId,
            name,
            startTime,
            endTime,
            description,
            blurb,
            location,
            notes,
            photo
        } = this.props.event;

        this.setState({
            photoId,
            name,
            startTime,
            endTime,
            description,
            blurb,
            location,
            notes,
            photo,
            isEdit: name !== ''
        });
    }

    _onBlur = (type) => {
        this.setState({
            [`${ type }Error`]: validateInput(type, this.state[type])
        });
    };

    _handleInputChange = (type, event) => {
        this.setState({
            [type]: event.target.value
        });
    };

    _handleDateChange = async (type, moment) => {
        await this.setState({
            [type]: moment._d
        });
    };

    _removeImage = (index) => {
        this.setState({
            photo: ''
        });

        // this.props.saveData([ ...this.state.photos ], 'supportingPhotos');
    };

    _selectImage = (files) => {
        this.setState({
            loading: true,
            notifyText: false,
            notifyTextColor: ''
        });

        const file = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = async () => {
            await this.setState({
                photo: reader.result
            });

            const photoData = await this.props.dispatch(createPhoto({ weddingId: this.props.event.weddingId }));

            const uploadUrl = photoData.url;

            const uploadStatus = await this.props.dispatch(uploadPhoto(uploadUrl, this.state.photo));

            if (uploadStatus) {
                await this.props.dispatch(markPhotoUploaded({ photoId: photoData.photo.id }));

                this.setState({
                    photoId: photoData.photo.id,
                    image: photoData.url,
                    loading: false
                });
            } else {
                this.setState({ 
                    loading: false,
                    notifyText: 'There was an issue uploading your image, please try again.',
                    notifyTextColor: 'red'
                });
            }
        };
    };

    _confirm = async () => {
        const {
            photoId,
            name,
            startTime,
            endTime,
            description,
            blurb,
            location,
            notes,
            isEdit
        } = this.state;

        // const photoIdError = validateInput('presence', photoId);
        const nameError = validateInput('name', name);
        const startTimeError = validateInput('date', startTime);
        const endTimeError = validateInput('date', endTime);
        const blurbError = validateInput('blurb', blurb);
        const descriptionError = validateInput('presence', description);
        const locationError = validateInput('presence', location);
        const notesError = validateInput('presence', notes);

        this.setState({
            // photoIdError,
            nameError,
            startTimeError,
            endTimeError,
            blurbError,
            descriptionError,
            locationError,
            notesError
        });

        if (
            // photoIdError ||
            nameError || 
            startTimeError ||  
            endTimeError || 
            blurbError || 
            descriptionError || 
            locationError ||
            notesError
        ) {
            return false;
        }
        

        let success = false;
        if (isEdit) {
            const event = {
                photoId,
                name: name.trim(),
                description: description.trim(),
                blurb: blurb.trim(),
                startTime: formatOutgoingLong(startTime),
                endTime: formatOutgoingLong(endTime),
                location: location.trim(),
                notes: notes.trim(),
                // guestsAttending: this.props.event.guestsAttending,
                guestsAttending: [ this.props.event.creatorId ]
            };

            console.log(event);

            success = await this.props.dispatch(updateEvent({ eventId: this.props.event.id, update: event }));
        } else {
            const event = {
                weddingId: this.props.event.weddingId,
                photoId,
                name: name.trim(),
                description: description.trim(),
                blurb: blurb.trim(),
                startTime: formatOutgoingLong(startTime),
                endTime: formatOutgoingLong(endTime),
                location: location.trim(),
                notes: notes.trim(),
                guestsAttending: [ this.props.event.creatorId ]
            };

            console.log(event);

            success = await this.props.dispatch(createEvent(event));
        }

        if (success) {
            this.setState({
                notifyText: false
            });

            this.props.close();
        }

        return true;
    };

    render() {
        const {
            photo,
            name,
            startTime,
            endTime,
            description,
            blurb,
            location,
            notes,
            isEdit
        } = this.state;

        const { classes } = this.props;

        return (
            <FormModal
                open={true}
                close={this.props.close}
                title={isEdit ? name : 'New'}
                disableBackdropClick
                disableEscapeKeyDown
                onSave={this._confirm}
                maxWidth="lg"
                fullWidth
            >
                {this.state.notifyText && (
                    <Text
                        align="center"
                        color={this.state.notifyTextColor}
                        small
                        display="inline-block"
                    >
                        {this.state.notifyText}
                    </Text>
                )}
                {
                    photo && photo.length > 0 ?
                        <React.Fragment>
                            <Text
                                align="center"
                                small
                            >
                                Click image to remove
                            </Text>
                            <SinglePhotoGrid
                                photo={this.state.photo}
                                onPressImage={this._removeImage}
                            />
                        </React.Fragment>
                        :
                        <Dropzone
                            onDrop={(files) => this._selectImage(files)}
                            accept="image/png, image/jpeg, image/jpg"
                        >
                            {({ getRootProps, getInputProps }) => (
                                <section className={classes.dropZone}> 
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <Button
                                            transparent
                                            fullWidth
                                        >
                                            Select or drag an image here
                                        </Button>
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                }

                <TextInput
                    variant="outlined"
                    label="Name"
                    value={this.state.name}
                    onBlur={() => this._onBlur('name')}
                    onChange={(value) => this._handleInputChange('name', value)}
                    error={Boolean(this.state.nameError)}
                    helperText={this.state.nameError}
                    fullWidth
                    autofocus
                />

                <DateTimePicker
                    inputVariant="outlined"
                    label="Start Time"
                    value={startTime}
                    onChange={(value) => this._handleDateChange('startTime', value)}
                    error={Boolean(this.state.startTimeError)}
                    helperText={this.state.startTimeError}
                    fullWidth
                />

                <DateTimePicker
                    inputVariant="outlined"
                    label="End Time"
                    value={endTime}
                    onChange={(value) => this._handleDateChange('endTime', value)}
                    error={Boolean(this.state.endTimeError)}
                    helperText={this.state.endTimeError}
                    fullWidth
                />

                <TextInput
                    variant="outlined"
                    label="Tag Line"
                    value={blurb}
                    onBlur={() => this._onBlur('blurb')}
                    onChange={(value) => this._handleInputChange('blurb', value)}
                    error={Boolean(this.state.blurbError)}
                    helperText={this.state.blurbError}
                    fullWidth
                />

                <TextInput
                    variant="outlined"
                    label="Description"
                    value={description}
                    onBlur={() => this._onBlur('description')}
                    onChange={(value) => this._handleInputChange('description', value)}
                    error={Boolean(this.state.descriptionError)}
                    helperText={this.state.descriptionError}
                    fullWidth
                    multiline
                />

                <TextInput
                    variant="outlined"
                    label="location"
                    value={location}
                    onBlur={() => this._onBlur('location')}
                    onChange={(value) => this._handleInputChange('location', value)}
                    error={Boolean(this.state.locationError)}
                    helperText={this.state.locationError}
                    fullWidth
                />

                <TextInput
                    variant="outlined"
                    label="Notes"
                    value={notes}
                    onBlur={() => this._onBlur('notes')}
                    onChange={(value) => this._handleInputChange('notes', value)}
                    error={Boolean(this.state.notesError)}
                    helperText={this.state.notesError}
                    fullWidth
                />

            </FormModal>
        );
    }
}

/**
 *
 * @param {*} theme
 */
const styles = (theme) => ({
    dropZone: {
        backgroundColor: colors.backgroundGrey,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100px'
    }
});

/**
 *
 * @param {*} state
 */
const mapStateToProps = (state) => ({
    loading: state.user.loading,
    user: state.user.meta
});

export default withStyles(styles)(connect(mapStateToProps)(SingleEventModal));