import request from './request';

/**
 * @returns {Promise<any>}
 */
function get() {
    const options = {
        endpoint: '/user/data',
        method: 'GET'
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param {*} body
 */
function login(body) {
    const options = {
        endpoint: "/login",
        method: 'POST',
        body,
        skipAuth: true
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param body
 * @returns {Promise<any>}
 */
function updateUserSettings(body) {
    const options = {
        endpoint: '/user/settings',
        method: 'PUT',
        body
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param body
 * @returns {Promise<any>}
 */
function sendTempPassword(body) {
    const options = {
        endpoint: '/generateResetPasswordCode',
        method: 'POST',
        body
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param body
 * @returns {Promise<any>}
 */
function changePassword(body) {
    const options = {
        endpoint: '/changePassword',
        method: 'POST',
        body
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param body
 * @returns {Promise<any>}
 */
function resetPassword(body) {
    const options = {
        endpoint: '/resetPassword',
        method: 'POST',
        body
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param id
 * @returns {Promise<any>}
 */
function removeNotification(id) {
    const options = {
        endpoint: `/notifications/${ id }`,
        method: 'DELETE'
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param body
 * @returns {Promise<any>}
 */
function savePaymentDetails(body) {
    const options = {
        endpoint: '/user/payments',
        method: 'POST',
        body
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

export {
    get,
    login,
    updateUserSettings,
    sendTempPassword,
    changePassword,
    resetPassword,
    removeNotification,
    savePaymentDetails
};