import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Header from './Header';
import MainFeatureBlock from './MainFeatureBlock';

import Features from './Features';
import Main from './Main';
import HotRecents from './HotRecents';
import Footer from './Footer';
import { globalStyles } from '../../theme';

// Hitched-A-Gram helps guests and the bridal party share photos,and see theme in a chronological timeline as they are taken during the wedding. This timeline will belong to the bride and grooms and guests forever.

// For the Bride and Groom, Hitched-A-Gram will let you see all the photos that were taken, and save full resolution versions for later, to have printed or just to see.

// For guests, Hitched-A-Gram lets you share the photos you are taking during the wedding, and let's you easily share them with the Bride and Groom right when you take them! No more tedious uploads to file sharing apps the day after. You also get to see the feed from all the other guests, and can comment and like the photos.

const sections = [
    { title: 'Whats New?', url: '#' },
    { title: 'Pricing', url: '#' },
    { title: 'Reviews', url: '#' },
    { title: 'For Photographers', url: '#' },
    { title: 'For Planners', url: '#' },
    { title: 'Support', url: '/support' },
    { title: 'User Guide', url: '#' }
];

const mainFeatureBlock = {
    titleA: 'Welcome to',
    titleB: 'Hitched-a-gram!',
    description:
    "Capture your special day from every angle. And hold onto it forever",
    // image: 'https://source.unsplash.com/random',
    image: 'https://source.unsplash.com/user/jeremywongweddings/1600x900',
    imgText: 'main image description'
};

const features = [
    {
        title: 'For everyone',
        description:
      'Hitched-A-Gram helps guests and the bridal party share photos, and see them in a chronological timeline as they are taken during the wedding. This timeline will belong to the bride and groom and guests forever.',
        image: 'https://source.unsplash.com/user/wedding_photography/1600x900',
        imageText: 'Image Text'
    },
    {
        title: 'For the Bride and Groom',
        description:
      'Hitched-A-Gram will let you see all the photos that were taken, and save full resolution versions for later, to have printed or just to see.',
        image: 'https://source.unsplash.com/user/aegeanweddingphotography/1600x900',
        imageText: 'Image Text'
    },
    {
        title: 'For guests',
        description:
      'Hitched-A-Gram lets you share the photos you are taking during the wedding, and lets you easily share them with the Bride and Groom right when you take them! No more tedious uploads to file sharing apps the day after. You also get to see the feed from all the other guests, and can comment and like the photos.',
        image: 'https://source.unsplash.com/user/aegeanweddingphotography/1600x900',
        imageText: 'Image Text'
    }
];

const posts = [ 'post1', 'post2', 'post3' ];

const sidebar = {
    title: 'Hot Recents',
    description:
    'Check out these great action shots from our previous users',
    archives: [
        { title: 'March 2020', url: '#' },
        { title: 'February 2020', url: '#' },
        { title: 'January 2020', url: '#' },
        { title: 'November 2019', url: '#' },
        { title: 'October 2019', url: '#' },
        { title: 'September 2019', url: '#' },
        { title: 'August 2019', url: '#' },
        { title: 'July 2019', url: '#' },
        { title: 'June 2019', url: '#' },
        { title: 'May 2019', url: '#' },
        { title: 'April 2019', url: '#' }
    ]
};

class Landing extends PureComponent {

    render() {
        const { classes } = this.props;

        return (
            <React.Fragment>
                <Container maxWidth="lg">
                    <Header history={this.props.history} title="Hitched-a-gram" sections={sections} />
                
                    <main>
                        <MainFeatureBlock post={mainFeatureBlock} />
                        <Grid container spacing={4}>
                            {features.map((post, i) => (
                                <Features index={i} key={post.title} post={post} />
                            ))}
                        </Grid>
                        <Grid container spacing={5} className={classes.mainGrid}>
                            <Main history={this.props.history} title="Help with your account" posts={posts} />
                            <HotRecents
                                title={sidebar.title}
                                description={sidebar.description}
                                archives={sidebar.archives}
                                social={sidebar.social}
                            />
                        </Grid>
                    </main>
                </Container>
                <Footer title="Hitched-a-gram" description="Capturing your big day from every angle!" />
            </React.Fragment>
        );
    }
}

const styles = (theme) => ({
    marginTop: theme.spacing(3),
    container: globalStyles.container,
    content: globalStyles.contentSidebarSite
});

const mapStateToProps = (state) => ({
    loading: state.user.loading,
    user: state.user.meta
});

export default withStyles(styles)(connect(mapStateToProps)(Landing));