import React from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Tile from './Tile';
import concatTokens from '../../services/concatTokens';

/**
 *
 * @param {*} param0
 */
const Tiles = ({ tiles, classes }) => (
    <div className={classes.container}>
        {tiles.map((tile) => (
            <Link
                key={concatTokens(tile.title, tile.description)}
                to={tile.link || ''}
                onClick={
                    !tile.link
                        ? (e) => {
                            e.preventDefault();

                            if (tile.disabled) {
                                return;
                            }

                            tile.onClick();
                        }
                        : null
                }
                style={{ textDecoration: 'none' }}
            >
                <Tile
                    img={tile.img}
                    title={tile.title}
                    description={tile.description}
                    icon={tile.icon}
                    step={tile.step}
                    disabled={tile.disabled}
                    customStyle={classes.tileWidth}
                />
            </Link>
        ))}
    </div>
);

/**
 *
 * @param {*} theme
 */
const styles = (theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        // flexWrap: 'wrap',
        justifyContent: 'center',
        maxWidth: '1120px',
        margin: '0 auto'
    },
    tileWidth: {
        // width: '280px'
        width: '100%'
    }
});

export default withStyles(styles)(Tiles);