import { history } from '../App';
import config from '../config';
import * as session from '../services/session';
require('es6-promise').polyfill();


/**
 * @param {*} req
 */
function request(req) {
    return new Promise((resolve, reject) => {
        if (!req.method) {
            console.error('method is required');
            reject(new Error('method is required'));
            return;
        }

        if (!req.endpoint) {
            console.error('endpoint is required');
            reject(new Error('endpoint is required'));
            return;
        }

        const token = session.getToken();
        const endpoint = `${ config.apiRequestUrl }${ req.endpoint }`;
        const options = {
            method: req.method.toUpperCase()
        };

        if (token && !req.skipAuth) {
            options.headers = {
                Authorization: `Bearer ${ token }`
            };
        }

        if (req.body) {
            if (req.noJson) {
                options.body = req.body;
            } else {
                options.body = JSON.stringify(req.body);
            }
        }

        fetch(endpoint, options)
            .then((res) => res.json())
            .then((res) => {
                // if the route failed for some reason, lets catch it
                if (res.statusCode) {
                    const error = {
                        verbiage: res.message,
                        code: 'INTERNAL_ERROR'
                    };

                    reject(error);
                    return;
                }

                if (res.status === 'Fail') {
                    reject(res);
                    if (res.code === 'INTERNAL_ERROR' && process.env.REACT_APP_ENVIRONMENT !== 'local') {
                        history.push('/error');
                    }
                } else {
                    resolve(res.data);
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export default request;