import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { Text } from '../../components';
import { colors } from '../../theme';

const useStyles = makeStyles((theme) => ({
    mainFeaturedPost: {
        position: 'relative',
        backgroundColor: theme.palette.grey[800],
        color: theme.palette.common.white,
        marginBottom: theme.spacing(4),
        backgroundImage: 'url(https://source.unsplash.com/random)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
    },
    overlay: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: 'rgba(0,0,0,.3)'
    },
    mainFeaturedPostContent: {
        position: 'relative',
        padding: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(6),
            paddingRight: 0
        }
    },
    downloadContainer: {
        margin: 20,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around'
    },
    symbol: {
        height: 60,
        width: 'auto',
        cursor: 'pointer'
    }
}));

export default function MainFeaturedPost(props) {
    const classes = useStyles();
    const { post } = props;

    return (
        <Paper className={classes.mainFeaturedPost} style={{ backgroundImage: `url(${ post.image })` }}>
            {/* Increase the priority of the hero background image */}
            {<img style={{ display: 'none' }} src={post.image} alt={post.imageText} />}
            <div className={classes.overlay} />
            <Grid container>
                <Grid item md={6}>
                    <div className={classes.mainFeaturedPostContent}>
                        <div style={{ display: 'inline' }}>
                            <Text
                                largest
                                bold
                                noWrap
                                customStyle={{ color: colors.white, fontSize: 60, margin: '0' }}
                            >
                                {post.titleA}
                            </Text>
                            <Text
                                largest
                                bold
                                noWrap
                                customStyle={{ color: colors.white, fontSize: 60, margin: '0' }}
                            >
                                {post.titleB}
                            </Text>
                        </div>
                        <Typography variant="h5" color="inherit" paragraph>
                            {post.description}
                        </Typography>
                    </div>
                </Grid>
            </Grid>
        </Paper>
    );
}

MainFeaturedPost.propTypes = {
    post: PropTypes.object
};