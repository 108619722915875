import initialState from '../initialState';
import {
    LOADING,
    COMPLETE_POST_SUCCESS,
    GET_ALL_POSTS,
    SUBMIT_COMMENT_SUCCESS,
    DELETE_COMMENT_SUCCESS
} from '../../actions/types/posts';

export default (state = initialState.posts, action) => {
    switch (action.type) {
        case LOADING: {
            return {
                ...state,
                loading: action.state
            };
        }

        case GET_ALL_POSTS: {
            return {
                ...state,
                items: [ ...action.items ]
            };
        }

        case COMPLETE_POST_SUCCESS: {
            return {
                ...state,
                items: [ action.item, ...state.items ]
            };
        }

        case SUBMIT_COMMENT_SUCCESS: {
            let items = [];

            state.items.map((stateItem) => {
                if (action.post.id === stateItem.id) {

                    return items.push(action.post);
                }
  
                return items.push(stateItem);
            });

            return {
                ...state,
                items
            };
        }

        case DELETE_COMMENT_SUCCESS: {
            let items = [];

            state.items.map((stateItem) => {
                if (action.post.id === stateItem.id) {

                    return items.push(action.post);
                }
  
                return items.push(stateItem);
            });

            return {
                ...state,
                items
            };
        }

        default:
            return state;
    }
};