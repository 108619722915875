import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import { colors } from '../theme/';

/**
 *
 * @param {*} props
 */
const Loader = (props) => {
    const { classes } = props;
    return (
        <div className={classes.loader}>
            <CircularProgress
                className={classes.spinner}
                color="primary"
                thickness={7}
            />
        </div>
    );
};

/**
 *
 * @param {*} theme
 */
const styles = (theme) => ({
    loader: {
        height: '100vh',
        width: '100%',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: colors.surface,
        opacity: '0.65',
        position: 'absolute',
        textAlign: 'center',
        zIndex: '999999999'
    },
    spinner: {
        position: 'absolute',
        top: '47%',
        color: `${ colors.gradientBlue } !important`
    }
});

export default withStyles(styles)(Loader);