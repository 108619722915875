import React from 'react';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InputAdornment from '@material-ui/core/InputAdornment';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import { colors } from '../../theme';

/**
 *
 * @param {*} props
 */
function CreditCardFormatCustom(props) {
	const { inputRef, onChange, ...other } = props;

	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={values => {
				onChange({
					target: {
						value: values.value
					}
				});
			}}
			format="#### #### #### ####"
		/>
	);
}

/*
 * _onBlurCCInput = async () => {
 *         await this.setState({
 *             creditCardError: validateInput('creditCard', this.state.creditCard)
 *         });
 *
 *         if (!this.state.creditCardError) {
 *             this.setState({
 *                 creditCardSuccess: true
 *             });
 *         }
 *     };
 */

const theme = createMuiTheme({
	typography: {
		useNextVariants: true,
		suppressDeprecationWarnings: true
	},
	palette: {
		primary: {
			main: colors.brandGreen
		}
	},
	overrides: {
		MuiOutlinedInput: {
			root: {
				'& fieldset': {
					borderColor: `${colors.brandGreen} !important`,
					borderWidth: 2,
					borderLeftWidth: 6
				}
			}
		}
	}
});

/**
 *
 * @param {*} props
 */
const CreditCardInput = ({
	id,
	classes,
	select,
	error,
	label,
	value,
	margin,
	onChange,
	onBlur,
	fullWidth,
	helperText,
	required,
	variant,
	disabled,
	creditCardSuccess
}) =>
	creditCardSuccess ? (
		<MuiThemeProvider theme={theme}>
			<TextField
				id={id}
				select={select}
				error={Boolean(error)}
				type="text"
				label={label}
				value={value}
				margin={margin || 'normal'}
				onChange={onChange}
				onBlur={onBlur}
				fullWidth={fullWidth}
				helperText={helperText || error}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<CreditCardIcon color="primary" />
						</InputAdornment>
					),
					startAdornment: (
						<InputAdornment position="start">
							<CheckCircleIcon color="primary" />
						</InputAdornment>
					),
					inputComponent: CreditCardFormatCustom
				}}
				required={required}
				variant="outlined"
				disabled={disabled}
			/>
		</MuiThemeProvider>
	) : (
		<TextField
			id={id}
			select={select}
			error={Boolean(error)}
			type="text"
			label={label}
			value={value}
			margin={margin || 'normal'}
			onChange={onChange}
			onBlur={onBlur}
			fullWidth={fullWidth}
			helperText={helperText || error}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<CreditCardIcon color="primary" />
					</InputAdornment>
				),
				inputComponent: CreditCardFormatCustom
			}}
			required={required}
			variant={variant || 'standard'}
			disabled={disabled}
		/>
	);

export default CreditCardInput;
