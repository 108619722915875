import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { globalStyles } from '../../theme';
import SingleEventModal from './SingleEventModal';
import EventBlock from './EventBlock';
import { Loader, Title, Button, Text, SearchBar } from '../../components';
import NoticeModal from '../../components/Modals/NoticeModal';
import { formatIncoming } from '../../services/Dates';

class Events extends Component {
    state = { 
        modalOpen: false,
        currentEvent: {},
        noticeOpen: false,
        expanded: false,
        message: '',
        filter: ''
    }

    _handleExpand = () => {
        this.setState({
            expanded: !this.state.expanded
        });
    };

    _handleFilter = (value) => {
        this.setState({ filter: value });
    };

    _toggleModal = (event) => {
        if (event === 'new') {
            event = {
                weddingId: this.props.wedding.weddingId,
                creatorId: this.props.user.id,
                photoId: undefined,
                name: '',
                startTime: '2020-12-12T08:00:00.000Z',
                endTime: '2020-12-12T16:00:00.000Z',
                description: 'this initial state data is in events/index',
                blurb: 'Test Blurb',
                location: 'Test Location',
                notes: 'Test notes'
            };
        }

        this.setState({
            modalOpen: !this.state.modalOpen,
            currentEvent: event
        });
    }

    _toggleNotice = (event) => {
        this.setState({
            noticeOpen: !this.state.noticeOpen,
            message: `You are not the creator of this event (${ event.name }), please ask the administrator for permission to edit`
        });
    }

    render() {
        const { classes, events, guests } = this.props;
        const { currentEvent, message } = this.state;

        const filterString = this.state.filter.toLowerCase();

        return (
            <div className={classes.container}>
                <div className={classes.titleButton}>
                    <Title title={ `${ events.length } Events`} />
                    <Button
                        onClick={() => this._toggleModal('new')}
                        color="secondary"
                    >
                        Create
                    </Button>
                </div>

                <SearchBar
                    setState={this._handleFilter}
                    placeholder="Search..."
                    customStyle={{
                        margin: '20px auto',
                        width: '100%'
                    }}
                />

                {events && events.map((event, i) => {
                    let guestList = [];

                    guests.map((guest) => {
                        if (guest.id === event.guestsAttending.id) {
                            guestList.push(guest);
                        }

                        // console.log(guest);
                        return guestList;
                    });

                    // console.log(event);

                    if (this.state.filter !== '') {
                        if (
                            (event.name.toLowerCase().indexOf(filterString) > -1) ||
                            // (event.creatorId.toLowerCase().indexOf(filterString) > -1) ||
                            (formatIncoming(event.startTime).readyDate.indexOf(filterString) > -1) ||
                            (formatIncoming(event.endTime).readyDate.indexOf(filterString) > -1) ||
                            (event.location.toLowerCase().indexOf(filterString) > -1) ||
                            (event.description.toLowerCase().indexOf(filterString) > -1) ||
                            (event.blurb.toLowerCase().indexOf(filterString) > -1)
                        ) {
                            return (
                                <ExpansionPanel defaultExpanded={i === 0} key={event.id}>
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                        <Text large>
                                            {event.name}
                                        </Text>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails className={classes.panelDetails}>
                                        <div 
                                            className={classes.editBox} 
                                            onClick={
                                                this.props.user.id === event.creatorId ?
                                                    () => this._toggleModal(event)
                                                    :
                                                    () => this._toggleNotice(event)
                                            }
                                        >

                                            {
                                                this.props.user.id === event.creatorId && 
                                                    <Text tiny customStyle={{ fontStyle: 'italic' }}>
                                                        (Click to Edit)
                                                    </Text>
                                            }
                                            
                                            <EventBlock
                                                name={event.name}
                                                eventId={event.id}
                                                description={event.description}
                                                blurb={event.blurb}
                                                endTime={event.endTime}
                                                startTime={event.startTime}
                                                location={event.location}
                                                notes={event.notes}
                                                comments={event.comments}
                                                guestsAttending={guestList}
                                                photoId={event.photoId}
                                                photo={event.photo}
                                            />
                                        </div>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            );
                        }
         
                        return null;
                    }
                        
                    return (
                        <ExpansionPanel defaultExpanded={i === 0} key={event.id}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Text large>
                                    {event.name}
                                </Text>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails
                                onClick={
                                    this.props.user.id === event.creatorId ?
                                        () => this._toggleModal(event)
                                        :
                                        () => this._toggleNotice(event)
                                } 
                                className={classes.editBox}
                            >
                                {
                                    this.props.user.id === event.creatorId && 
                                        <Text tiny customStyle={{ fontStyle: 'italic' }}>
                                            (Click to Edit)
                                        </Text>
                                }

                                <EventBlock
                                    name={event.name}
                                    eventId={event.id}
                                    description={event.description}
                                    blurb={event.blurb}
                                    endTime={event.endTime}
                                    startTime={event.startTime}
                                    location={event.location}
                                    notes={event.notes}
                                    comments={event.comments}
                                    guestsAttending={guestList}
                                    photoId={event.photoId}
                                    photo={event.photo}
                                />
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    );
                }
                )}

                <div className={classes.spacer}>
                    <Text
                        tiny
                    >
                        Copyright © The Moseley Bros {new Date().getFullYear()}{'.'}
                    </Text>
                </div>

                {
                    this.state.modalOpen && 
                        <SingleEventModal event={currentEvent} close={this._toggleModal} />
                }

                {
                    this.state.noticeOpen && 
                        <NoticeModal title="No edit permission" message={message} close={this._toggleNotice} />
                }

                {this.props.loading && <Loader />}
            </div>
        );
    }
}

/**
 *
 * @param {*} theme
 */
const styles = (theme) => ({
    container: globalStyles.container,
    content: globalStyles.content,
    titleContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'flex-start'
    },
    titleButton: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        flexDirection: 'row'
    },
    editBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    spacer: {
        height: 100,
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end'
    }
});

/**
 *
 * @param {*} state
 */
const mapStateToProps = (state) => ({
    loading: state.events.loading,
    events: state.events.items,
    guests: state.guests.items,
    role: state.user.role,
    user: state.user.meta,
    wedding: state.wedding
});

export default withStyles(styles)(connect(mapStateToProps)(Events));