
/**
 * @returns {Promise<any>}
 */
function uploadPhoto(uploadUrl, file) {
    return (_dispatch) => new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();

        xhr.onerror = (e) => {
            console.log('upload error', e);
            reject(e);
        };

        xhr.onload = (r) => {
            console.log('upload complete');
            resolve('success');
        };

        xhr.ontimeout = () => {
            console.log('upload timeout');
            reject(new Error('timeout'));
        };

        // TODO: Make the fileType dependant on the image type.
        xhr.open('PUT', uploadUrl);
        xhr.setRequestHeader('Content-Type', 'image/jpeg');
        xhr.send(file);
        if (xhr.upload) {
            xhr.upload.onprogress = (p) => {
                console.log(`upload progress ${ p.loaded / p.total }`);
            };
        }

        return true;
    });
}

export { uploadPhoto };