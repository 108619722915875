/* eslint-disable camelcase */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import InputAdornment from '@material-ui/core/InputAdornment';

import { updateFormField } from '../../../actions/signup';
import { TextInput, Text, Button } from '../../../components';
import { globalStyles } from '../../../theme';
import validateInput from '../../../services/FormValidation/validateInput';

class Guests extends PureComponent {
    state = {
        guests: [ 'a', 'b', 'c' ],
        email: ''
    };

    componentDidMount() {
        // this.setState({
        //     guests: this.props.guests || ''
        // });
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            guests: nextProps.guests || ''
        });
    }

    _toggleAdd = async () => {
        const isFormValid = await this._validateForm();

        if (!isFormValid) {
            return null;
        }

        this.setState({
            guests: [ ...this.state.guests, this.state.email ],
            email: ''
        });

        return true;
    };

    _onBlur = (type) => {
        // update state with new form field value so it sticks when switching between views
        this.props.dispatch(updateFormField('guests', type, this.state[type]));
    };

    _handleInputChange = (type, event) => {
        this.setState({
            [type]: event.target.value.trim()
        });
    };

    _validateForm = () => {
        // const guestsError = validateInput('presence', this.state.guests);
        const emailError = validateInput('email', this.state.email);

        this.setState({
            emailError
        });

        if (emailError) {
            return false;
        }

        return true;
    };

    _goBack = () => {
        this.props.goBack();
    };

    _finish = () => {
        this.props.stepCompletion(
            { guests: this.state.guests },
            'guests',
            true
        );

        return null;
    };

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.stepperWrapper}>
                <Text
                    align="center"
                    large
                    bold
                    // margin="0px 0px 10px 0px"
                    display="block"
                    customStyle={{ maxWidth: '30%' }}
                >
                    Add email address of your guests who you would like to
                    receive an invitation to download the App. (You can complete
                    this later)
                </Text>

                <div className={classes.stepperForm}>
                    <Text
                        align="center"
                        medium
                        bold
                        margin="10px 0px 10px 0px"
                        display="block"
                    >
                        Guest Emails Added:
                    </Text>

                    {/* {this.state.guests.map((guest, i) => (
                        <p>
                            {i + 1}: {guest}
                        </p>
                    ))} */}

                    <TextInput
                        variant="outlined"
                        label="Add Guest Email"
                        autoFocus
                        value={this.state.email}
                        inputProps={{
                            endAdornment: (
                                <InputAdornment>
                                    <AddIcon
                                        style={{ cursor: 'pointer' }}
                                        onClick={this._toggleAdd}
                                    />
                                </InputAdornment>
                            )
                        }}
                        onBlur={() => this._onBlur('email')}
                        onChange={(value) => this._handleInputChange('email', value)}
                        error={Boolean(this.state.emailError)}
                        helperText={this.state.emailError}
                        fullWidth
                    />

                    {/* <Button
                    className={classes.stepperBackBtn}
                    onClick={this._goBack}
                    transparent
                >
                    ADD
                </Button> */}

                    <div className={classes.stepperBtnContainer}>
                        {/* <Button
                            className={classes.stepperBackBtn}
                            onClick={this._goBack}
                            transparent
                        >
                        Back 
                        </Button> */}

                        <Button
                            className={classes.stepperFinishBtn}
                            onClick={this._finish}
                            color="secondary"
                        >
                            Finish
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

/**
 *
 * @param {*} theme
 */
const styles = (theme) => ({
    stepperBackBtn: { marginRight: 'auto' },
    stepperBtnContainer: globalStyles.stepperBtnContainer,
    stepperFinishBtn: globalStyles.stepperFinishBtn,
    stepperForm: globalStyles.stepperForm,
    stepperNextBtn: globalStyles.stepperNextBtn,
    stepperWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around'
    }
});

Guests.propTypes = {
    firstName: PropTypes.oneOfType([ PropTypes.string, PropTypes.bool ]),
    lastName: PropTypes.oneOfType([ PropTypes.string, PropTypes.bool ]),
    relationship: PropTypes.oneOfType([ PropTypes.string, PropTypes.bool ])
};

/**
 *
 * @param {*} state
 */
const mapStateToProps = (state) => ({
    wedding: state.signup.wedding,
    user: state.signup.userDetails
});

export default withStyles(styles)(connect(mapStateToProps)(Guests));