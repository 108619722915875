import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// import List from '@material-ui/core/List';

import { Text, Loader, Title } from '../../components';
import { globalStyles } from '../../theme/';
import { removeNotification } from '../../actions/user';

class Notifications extends PureComponent {
    _removeNotification = (notification) => {
        this.props.dispatch(removeNotification(notification.id));
    };

    render() {
        if (!this.props.notifications) {
            return <Loader />;
        }

        const { classes } = this.props;

        return (
            <div className={classes.container}>
                <div className={classes.contentSmall}>
                    <Title title="Notifications" />

                    {/* <List>
                        {
                            this.props.notifications.map((notification) => (
                                <Notification notification={ notification } onClick={ this._removeNotification } />
                            ))
                        }
                    </List> */}

                    {this.props.notifications.length === 0 && (
                        <Text light medium>
                            You have no notifications.
                        </Text>
                    )}
                </div>

                {this.props.loading && <Loader />}

                <span id="notifications" />
            </div>
        );
    }
}

/**
 *
 * @param {`*`} theme
 */
const styles = (theme) => ({
    container: globalStyles.container,
    contentSmall: globalStyles.contentSmall,
    listItemIcon: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: 55
    },
    listItem: {
        maxWidth: 800,
        borderRadius: 2,
        boxShadow: '0 1px 2px rgba(0, 0, 0, .15)',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: 5
    },
    listItemError: {
        paddingTop: 20,
        paddingBottom: 20
    },
    blue: {
        backgroundColor: '#E0F5F7'
    },
    yellow: {
        backgroundColor: '#F7F2C2'
    }
});

Notifications.propTypes = {
    loading: PropTypes.bool,
    notifications: PropTypes.array
};

/**
 *
 * @param {*} state
 */
const mapStateToProps = (state) => ({
    loading: state.user.loading,
    notifications: state.user.notifications
});

export default withStyles(styles)(connect(mapStateToProps)(Notifications));