import React from 'react';
import Avatar from 'react-avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';

import Text from '../Text';
import { colors } from '../../theme';

/**
 *
 * @param {*} param0
 */
const MasterMetaMenu = ({
	open,
	onClose,
	anchorEl,
	toggleModal,
	handleCloseMenu,
	logout,
	name,
	email,
	classes
}) => (
	<Menu
		anchorEl={anchorEl}
		open={open}
		onClose={onClose}
		disableAutoFocusItem={true}
	>
		<div className={classes.menuItemBorder} onClick={handleCloseMenu}>
			<div className={classes.navDropMenuItemMain}>
				<Avatar
					name={name}
					size="55"
					textSizeRatio={2.2}
					round={false}
					color={colors.skyBlue}
					style={{ marginBottom: 20 }}
				/>
				<Text align={'center'} medium bold display="block">
					{name}
				</Text>
				<Text align={'center'} medium light display="block">
					{email}
				</Text>
			</div>
		</div>

		<MenuItem
			className={classes.navDropMenuItem}
			onClick={() => toggleModal('showSettingsModal')}
		>
			<Text
				align="center"
				medium
				display="block"
				customLinkStyle={{ textDecoration: 'none' }}
			>
				Settings
			</Text>
		</MenuItem>

		{/* <MenuItem
            className={classes.navDropMenuItem}
            onClick={() => toggleModal('showPasswordModal')}
        >
            <Text
                align="center"
                medium
                display="block"
                customLinkStyle={{ textDecoration: 'none' }}
            >
                Change Password
            </Text>
        </MenuItem> */}

		<MenuItem className={classes.navDropMenuItem} onClick={handleCloseMenu}>
			<Text align="center" medium display="block">
				Resources
			</Text>
		</MenuItem>

		<MenuItem className={classes.navDropMenuItem} onClick={handleCloseMenu}>
			<Text align="center" medium display="block">
				Contact Support
			</Text>
		</MenuItem>

		<MenuItem className={classes.navDropMenuItem} onClick={logout}>
			<Text color={colors.red} align="center" medium display="block">
				Logout
			</Text>
		</MenuItem>
	</Menu>
);

/**
 *
 * @param {*} theme
 */
const styles = theme => ({
	// Todo: Add global styles from UI library
	menuItemBorder: {
		borderBottom: `${colors.surface} solid 1px`
	},
	navDropMenuItem: {
		height: 'auto',
		justifyContent: 'center',
		minWidth: '100%',
		padding: '5px',
		paddingTop: '10px'
	},
	navDropMenuItemMain: {
		padding: '30px',
		paddingTop: '20px',
		textAlign: 'center'
	}
});

export default withStyles(styles)(MasterMetaMenu);
