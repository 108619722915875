import { withStyles } from '@material-ui/core/styles';
import { DateTimePicker } from "@material-ui/pickers";
import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { createEvent, updateEvent } from '../../actions/events';
import { createPhoto, markPhotoUploaded } from '../../actions/posts';
import { Button, FormModal, Loader, SinglePhotoGrid, Text, TextInput } from '../../components';
import { formatOutgoingLong } from '../../services/Dates';
import validateInput from '../../services/FormValidation/validateInput';
import { colors } from '../../theme';
import { uploadPhoto } from '../../xhr/photo';


class SingleEventModal extends Component {
    state = {
        photo: false,
        photoId: '',
        name: '',
        startTime: '',
        endTime: '',
        description: '',
        blurb: '',
        location: '',
        notes: '',
        comments: [],
        guestsAttending: [],
        notifyText: false,
        notifyTextColor: '',
        isEdit: false,
        loading: false
    };

    componentDidMount() {
        const {
            name,
            startTime,
            endTime,
            description,
            blurb,
            location,
            notes,
            photoId,
            photo
        } = this.props.event;

        this.setState({
            photoId,
            name,
            startTime,
            endTime,
            description,
            blurb,
            location,
            notes,
            photo,
            isEdit: name !== ''
        });
    }

    _onBlur = (type) => {
        this.setState({
            [`${ type }Error`]: validateInput(type, this.state[type])
        });
    };

    _handleInputChange = (type, event) => {
        this.setState({
            [type]: event.target.value
        });
    };

    _handleDateChange = async (type, moment) => {
        await this.setState({
            [type]: moment._d
        });
    };

    _removeImage = (index) => {
        this.setState({
            photo: false
        });

        // TODO: Remove uploaded photo.

        // this.props.saveData([ ...this.state.photos ], 'supportingPhotos');
    };

    _selectImage = (files) => {
        this.setState({
            loading: true,
            notifyText: false,
            notifyTextColor: ''
        });

        const file = files[0];
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);

        reader.onload = async () => {
            this.setState({ 
                loading: true
            });

            // TODO: Show spinner for photo upload.
            const photoData = await this.props.dispatch(createPhoto({ weddingId: this.props.event.weddingId }));

            const uploadUrl = photoData.url;

            const uploadStatus = await this.props.dispatch(uploadPhoto(uploadUrl, reader.result));

            if (uploadStatus) {
                const completedPhotoData = await this.props.dispatch(markPhotoUploaded({ photoId: photoData.photo.id }));

                this.setState({
                    photoId: photoData.photo.id,
                    photo: completedPhotoData,
                    loading: false
                });
            } else {
                this.setState({ 
                    loading: false,
                    notifyText: 'There was an issue uploading your image, please try again.',
                    notifyTextColor: 'red'
                });
            }
        };
    };

    _confirm = async () => {
        const {
            name,
            startTime,
            endTime,
            description,
            blurb,
            location,
            notes,
            isEdit,
            photoId
        } = this.state;

        // const photoIdError = validateInput('presence', photoId);
        const nameError = validateInput('name', name);
        const startTimeError = validateInput('date', startTime);
        const endTimeError = validateInput('date', endTime);
        const blurbError = validateInput('blurb', blurb);
        const descriptionError = validateInput('presence', description);
        const locationError = validateInput('presence', location);
        const notesError = validateInput('presence', notes);

        this.setState({
            // photoIdError,
            nameError,
            startTimeError,
            endTimeError,
            blurbError,
            descriptionError,
            locationError,
            notesError
        });

        if (
            // photoIdError ||
            nameError || 
            startTimeError ||  
            endTimeError || 
            blurbError || 
            descriptionError || 
            locationError ||
            notesError
        ) {
            return false;
        }
        
        let success = false;

        if (isEdit) {
            const event = {
                name: name.trim(),
                description: description.trim(),
                blurb: blurb.trim(),
                startTime: formatOutgoingLong(startTime),
                endTime: formatOutgoingLong(endTime),
                location: location.trim(),
                notes: notes.trim(),
                photoId,
                // guestsAttending: this.props.event.guestsAttending,
                guestsAttending: [ this.props.event.creatorId ]
            };

            console.log(event);

            success = await this.props.dispatch(updateEvent({ eventId: this.props.event.id, update: event }));
        } else {
            const event = {
                weddingId: this.props.wedding.weddingId,
                name: name.trim(),
                description: description.trim(),
                blurb: blurb.trim(),
                startTime: formatOutgoingLong(startTime),
                endTime: formatOutgoingLong(endTime),
                location: location.trim(),
                notes: notes.trim(),
                photoId,
                guestsAttending: [ this.props.user.id ]
            };

            console.log(event);

            success = await this.props.dispatch(createEvent(event));
        }

        if (success) {
            this.setState({
                notifyText: false
            });

            this.props.close();
        }

        return true;
    };

    render() {
        const {
            photo,
            name,
            startTime,
            endTime,
            description,
            blurb,
            location,
            notes,
            isEdit
        } = this.state;

        const { classes } = this.props;

        // console.log("user", user);
        // console.log('photo', photo);

        return (
            <FormModal
                open={true}
                close={this.props.close}
                title={isEdit ? name : 'New'}
                disableBackdropClick
                disableEscapeKeyDown
                onSave={this._confirm}
                maxWidth="lg"
                fullWidth
            >
                <div className={classes.wrapper}>
                    <div className={classes.leftWrapper}>
                        {this.state.notifyText && (
                            <Text
                                align="center"
                                color={this.state.notifyTextColor}
                                small
                                display="inline-block"
                            >
                                {this.state.notifyText}
                            </Text>
                        )}
                        {
                            photo ?
                                <React.Fragment>
                                    <SinglePhotoGrid
                                        photo={photo}
                                        onPressImage={this._removeImage}
                                    />
                                    {/* <Text
                                align="center"
                                small
                            >
                                Loading onto servers, please continue...
                            </Text> */}
                                </React.Fragment>
                                :
                                <Dropzone
                                    onDrop={(files) => this._selectImage(files)}
                                    accept="image/png, image/jpeg, image/jpg"
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <section className={classes.dropZone}> 
                                            <div {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                <Button
                                                    fullWidth
                                                    color="primary"
                                                >
                                            Select or drag an image here
                                                </Button>
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>
                        }
                    </div>
                    <div className={classes.rightWrapper}>
                        <TextInput
                            variant="outlined"
                            label="Name"
                            value={this.state.name}
                            onBlur={() => this._onBlur('name')}
                            onChange={(value) => this._handleInputChange('name', value)}
                            error={Boolean(this.state.nameError)}
                            helperText={this.state.nameError}
                            fullWidth
                            autofocus
                        />

                        <DateTimePicker
                            inputVariant="outlined"
                            style={{ marginTop: 16, marginBottom: 8 }}
                            label="Start Time"
                            value={startTime}
                            onChange={(value) => this._handleDateChange('startTime', value)}
                            error={Boolean(this.state.startTimeError)}
                            helperText={this.state.startTimeError}
                            fullWidth
                        />

                        <DateTimePicker
                            inputVariant="outlined"
                            style={{ marginTop: 16, marginBottom: 8 }}
                            label="End Time"
                            value={endTime}
                            onChange={(value) => this._handleDateChange('endTime', value)}
                            error={Boolean(this.state.endTimeError)}
                            helperText={this.state.endTimeError}
                            fullWidth
                        />

                        <TextInput
                            variant="outlined"
                            label="Tag Line"
                            value={blurb}
                            onBlur={() => this._onBlur('blurb')}
                            onChange={(value) => this._handleInputChange('blurb', value)}
                            error={Boolean(this.state.blurbError)}
                            helperText={this.state.blurbError}
                            fullWidth
                        />

                        <TextInput
                            variant="outlined"
                            label="Description"
                            value={description}
                            onBlur={() => this._onBlur('description')}
                            onChange={(value) => this._handleInputChange('description', value)}
                            error={Boolean(this.state.descriptionError)}
                            helperText={this.state.descriptionError}
                            fullWidth
                            multiline
                        />

                        <TextInput
                            variant="outlined"
                            label="Location"
                            value={location}
                            onBlur={() => this._onBlur('location')}
                            onChange={(value) => this._handleInputChange('location', value)}
                            error={Boolean(this.state.locationError)}
                            helperText={this.state.locationError}
                            fullWidth
                        />

                        <TextInput
                            variant="outlined"
                            label="Notes"
                            value={notes}
                            onBlur={() => this._onBlur('notes')}
                            onChange={(value) => this._handleInputChange('notes', value)}
                            error={Boolean(this.state.notesError)}
                            helperText={this.state.notesError}
                            fullWidth
                        />

                        {/* {this.props.loading && <Loader />} */}
                        {this.state.loading && <Loader />}
                    </div>
                </div>
            </FormModal>
        );
    }
}

/**
 *
 * @param {*} theme
 */
const styles = (theme) => ({
    dropZone: {
        backgroundColor: colors.backgroundGrey,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 90
    },
    wrapper: {
        display: 'flex', 
        flexDirection: 'row'
    },
    leftWrapper: {
        width: '55%',
        marginRight: '5%'
    },
    rightWrapper: {
        width: '40%'
    }
});

/**
 *
 * @param {*} state
 */
const mapStateToProps = (state) => ({
    // loading: state.events.loading,
    user: state.user.meta,
    wedding: state.wedding
});

export default withStyles(styles)(connect(mapStateToProps)(SingleEventModal));