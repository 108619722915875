import React from 'react';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';

/**
 *
 * @param {*} props
 */
function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        value: values.value
                    }
                });
            }}
            fixedDecimalScale={true}
            allowNegative={false}
        />
    );
}

/**
 *
 * @param {*} props
 */
function NumberFormatCurrency(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        value: values.value
                    }
                });
            }}
            thousandSeparator
            prefix="$ "
            decimalScale={2}
            fixedDecimalScale={true}
            allowNegative={false}
        />
    );
}

/**
 *
 * @param {*} props
 */
function NumberFormatPercentage(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        value: values.value
                    }
                });
            }}
            suffix="%"
            allowNegative={false}
            format={(values) => {
                if (values <= 100) {
                    return `${ values }%`;
                }

                return `${ 100 }%`;
            }}
        />
    );
}

/**
 *
 * @param {*} props
 */
const NumberInput = (props) => {
    let NumberFormatType = NumberFormatCustom;

    if (props.prefix) {
        NumberFormatType = NumberFormatCurrency;
    }

    if (props.suffix) {
        NumberFormatType = NumberFormatPercentage;
    }

    return (
        <TextField
            id={props.id}
            select={props.select}
            error={Boolean(props.error)}
            helperText={props.helperText || props.error}
            label={props.label}
            value={props.value}

            /*
			 * Breaks Component with existing formatting
			 * type="number"
			 */
            margin="normal"
            min={props.min}
            onChange={props.onChange}
            onBlur={props.onBlur}
            fullWidth={props.fullWidth}
            InputProps={{
                inputComponent: NumberFormatType
            }}
            required={props.required}
            variant={props.variant || 'standard'}
            disabled={props.disabled}
            onKeyPress={props.onKeyPress}
        />
    );
};

export default NumberInput;