import React, { Component } from 'react';
import { connect } from 'react-redux';

import { updateUserSettings } from '../../actions/user';
// import { TextInput, PhoneInput, DateInput, FormModal } from '../../components';
import { TextInput, FormModal } from '../../components';
// import { colors } from '../../theme';
import * as Dates from '../../services/Dates.js';
import validateInput from '../../services/FormValidation/validateInput';
// import * as selectFieldOptions from '../../services/selectFieldOptions';

class Settings extends Component {
    state = {
        firstName: '',
        lastName: '',
        email: '',
        province: '',
        phone: '',
        birthdate: '',
        gender: ''
    };

    componentDidMount() {
        const {
            firstName,
            lastName,
            email,
            province,
            gender,
            phone,
            birthdate
        } = this.props.meta;

        this.setState({
            firstName: firstName || '',
            lastName: lastName || '',
            email: email || '',
            province: province || '',
            phone: phone || '',
            birthdate: Dates.formatIncoming(birthdate).readyDate || '',
            gender: gender || ''
        });
    }

    _onBlur = (type) => {
        this.setState({
            [`${ type }Error`]: validateInput(type, this.state[type])
        });
    };

    _handleInputChange = (type, event) => {
        this.setState({
            [type]: event.target.value.toString()
        });
    };

    _validateForm = () => {
        const firstnameError = validateInput('firstName', this.state.firstName);
        const lastnameError = validateInput('lastName', this.state.lastName);
        const emailError = validateInput('email', this.state.email);
        const birthdateError = validateInput('birthdate', this.state.birthdate);
        const phoneError = validateInput('phone', this.state.phone);
        const provinceError = validateInput('province', this.state.province);
        const genderError = validateInput('gender', this.state.gender);

        this.setState({
            firstnameError,
            lastnameError,
            emailError,
            birthdateError,
            phoneError,
            provinceError,
            genderError
        });

        if (
            firstnameError ||
            lastnameError ||
            emailError ||
            birthdateError ||
            phoneError ||
            provinceError ||
            genderError
        ) {
            return false;
        }

        this._confirm();

        return true;
    };

    _confirm = () => {
        const success = this.props.dispatch(
            updateUserSettings(
                {
                    firstName: this.state.firstName.trim(),
                    lastName: this.state.lastName.trim(),
                    email: this.state.email.trim(),
                    province: this.state.province.trim(),
                    birthdate: Dates.formatOutgoing(this.state.birthdate),
                    phone: this.state.phone.trim(),
                    gender: this.state.gender.trim()
                },
                this.props.meta.userID
            )
        );

        if (success) {
            this.props.close();
        }

        return true;
    };

    render() {
        const {
            firstName,
            lastName,
            email
            // birthdate,
            // province,
            // gender,
            // phone
        } = this.state;

        return (
            <FormModal
                open={true}
                close={this.props.close}
                // title="Your account details"
                disableBackdropClick
                disableEscapeKeyDown
                // onSave={this._validateForm}
            >
                <TextInput
                    variant="outlined"
                    label="First Name"
                    value={firstName}
                    onBlur={() => this._onBlur('firstName')}
                    onChange={(value) => this._handleInputChange('firstName', value)}
                    error={Boolean(this.state.firstnameError)}
                    helperText={this.state.firstnameError}
                    fullWidth
                    disabled
                />

                <TextInput
                    variant="outlined"
                    label="Last Name"
                    value={lastName}
                    onBlur={() => this._onBlur('lastName')}
                    onChange={(value) => this._handleInputChange('lastName', value)}
                    error={Boolean(this.state.lastnameError)}
                    helperText={this.state.lastnameError}
                    fullWidth
                    disabled
                />

                <TextInput
                    variant="outlined"
                    label="Email"
                    value={email}
                    onBlur={() => this._onBlur('email')}
                    onChange={(value) => this._handleInputChange('email', value)}
                    error={Boolean(this.state.emailError)}
                    helperText={this.state.emailError}
                    fullWidth
                    disabled
                />

                {/* <TextInput
                    label="Province"
                    value={province || ''}
                    onBlur={() => this._onBlur('province')}
                    onChange={(value) => this._handleInputChange('province', value)
                    }
                    error={Boolean(this.state.provinceError)}
                    helperText={this.state.provinceError}
                    select
                    selectOptions={selectFieldOptions.provinceOptions}
                    required
                    fullWidth
                />

                <PhoneInput
                    label="Phone"
                    value={phone}
                    onBlur={() => this._onBlur('phone')}
                    onChange={(value) => this._handleInputChange('phone', value)}
                    error={Boolean(this.state.phoneError)}
                    helperText={this.state.phoneError}
                    fullWidth
                /> */}

                {/* <TextInput
                    label="Gender"
                    fullWidth
                    required
                    value={gender || ''}
                    onBlur={() => this._onBlur('gender')}
                    onChange={(value) => this._handleInputChange('gender', value)}
                    error={Boolean(this.state.genderError)}
                    helperText={this.state.genderError}
                    select
                    selectOptions={selectFieldOptions.genderOptions}
                />

                <DateInput
                    label="Date of Birth"
                    value={birthdate || ''}
                    onBlur={() => this._onBlur('birthdate')}
                    onChange={(value) => this._handleInputChange('birthdate', value)
                    }
                    error={Boolean(this.state.birthdateError)}
                    helperText={this.state.birthdateError}
                    fullWidth
                /> */}
            </FormModal>
        );
    }
}

/**
 *
 * @param {*} state
 */
const mapStateToProps = (state) => ({
    loading: state.user.loading,
    meta: state.user.meta
});

export default connect(mapStateToProps)(Settings);