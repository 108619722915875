
export const generateUserName = (guests, id) => {
    let nom = '';

    guests.map((guest) => {
        if (guest.id === id) {
            // nom = `${ guest.firstName } ${ guest.lastName }`;
            nom = guest.firstName;
        }

        return null;
    });

    return nom;
};

export const generateUserEmail = (guests, id) => {
    let email = '';

    if (!guests || !id) {
        email = 'support@hitchedagram.com';

        return email;
    }

    guests.map((guest) => {
        if (guest.id === id) {
            email = guest.email;
        }

        return null;
    });
  

    return email;
};