/**
 * 
 * @param {*} token 
 */
function setToken(token) {
    if (typeof token === 'string' && token.length > 0) {
        localStorage.setItem('hitched-user-token', token);
    }
}

/**
 * 
 */
function getToken() {
    return localStorage.getItem('hitched-user-token') || null;
}

/**
 * 
 */
function removeToken() {
    localStorage.removeItem('hitched-user-token');
}

/**
 * 
 * @param {*} token 
 */
function setWeddingToken(token) {
    if (typeof token === 'string' && token.length > 0) {
        localStorage.setItem('hitched-wedding-token', token);
    }
}

/**
 * 
 */
function getWeddingToken() {
    return localStorage.getItem('hitched-wedding-token') || null;
}

/**
 * 
 */
function removeWeddingToken() {
    localStorage.removeItem('hitched-wedding-token');
}

export {
    setToken,
    getToken,
    removeToken,
    setWeddingToken,
    getWeddingToken,
    removeWeddingToken
};