import React from 'react';
import { Link } from 'react-router-dom';
import { colors } from '../theme';
import { withStyles } from '@material-ui/core';

/**
 *
 * @param {*} param0
 */
const Text = ({
    align,
    bold,
    light,
    classes,
    color,
    customLinkStyle,
    customStyle,
    display,
    isSelected,
    isSelectedColor,
    large,
    link,
    extraLarge,
    largest,
    margin,
    medium,
    mediumLarge,
    onClick,
    small,
    children,
    tiny,
    superTiny,
    uppercase
}) => {
    let className = '';
    customStyle = customStyle || {};

    if (light) {
        className += ` ${ classes.light }`;
    }

    if (bold || isSelected) {
        className += ` ${ classes.bold }`;
    }

    if (superTiny) {
        className += ` ${ classes.superTiny }`;
    }

    if (tiny) {
        className += ` ${ classes.tiny }`;
    }

    if (small) {
        className += ` ${ classes.small }`;
    }

    if (medium) {
        className += ` ${ classes.medium }`;
    }

    if (mediumLarge) {
        className += ` ${ classes.mediumLarge }`;
    }

    if (large) {
        className += ` ${ classes.large }`;
    }

    if (extraLarge) {
        className += ` ${ classes.extraLarge }`;
    }

    if (largest) {
        className += ` ${ classes.largest }`;
    }

    if (uppercase) {
        children = children.toUpperCase();
    }

    if (isSelected && isSelectedColor) {
        color = isSelectedColor;
    }

    if (typeof link !== 'string') {
        link = null;
    }

    const textBlock = (
        <p
            className={`${ className }`}
            onClick={onClick && (() => onClick())}
            style={{
                fontFamily:
					light || bold || isSelected ? null : '"Montserrat", sans-serif',
                textAlign: align || 'left',
                color: color === 'inherit'
                    ? 'inherit'
                    : color
                        ? color
                        : colors.mainTextGrey,
                margin: margin || '0px 0px 0px 0px',
                display: display || 'block',
                ...customStyle
            }}
        >
            {children}
        </p>
    );

    if (link) {
        return (
            <Link
                to={link}
                style={
                    customLinkStyle ? customLinkStyle : { textDecoration: 'none' }
                }
            >
                {textBlock}
            </Link>
        );
    }

    return textBlock;
};

/**
 *
 * @param {*} theme
 */
const styles = (theme) => ({
    light: {
        fontWeight: 300
    },
    bold: {
        fontWeight: 700
    },
    superTiny: {
        fontSize: '10px'
    },
    tiny: {
        fontSize: '12px'
    },
    small: {
        fontSize: '14px'
    },
    medium: {
        fontSize: '16px'
    },
    mediumLarge: {
        fontSize: '21px'
    },
    large: {
        fontSize: '24px'
    },
    extraLarge: {
        fontSize: '28px'
    },
    largest: {
        fontSize: '40px'
    }
});

export default withStyles(styles)(Text);