import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import * as session from './services/session';
import { withStyles } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import Sidebar from './components/Sidebar';
import { Loader } from './components';
import { colors } from './theme';

class PrivateRoute extends Component {
        state = {
            loading: true,
            isAuthenticated: false,
            mobileOpen: false
        };

        componentDidMount() {
            this._isUserAuthenticated().then((isAuthenticated) => {
                this.setState({
                    isAuthenticated,
                    loading: false
                });
            });
        }

        handleDrawerToggle = () => {
            this.setState((state) => ({ mobileOpen: !state.mobileOpen }));
        };

        /**
         * @returns {Promise<boolean>}
         * @private
         */
        _isUserAuthenticated = async () => {
            const token = await session.getToken();

            if (!token) {
                return false;
            }

            return true;
        };

        render() {
            const { component: Comp, classes, theme, ...rest } = this.props;

            if (this.state.loading) {
                return <Loader />;
            }

            return (
                <Route
                    {...rest}
                    render={(props) => this.state.isAuthenticated ? (
                        <React.Fragment>
                            <div className={classes.container}>
                                <Hidden mdUp >
                                    <Drawer
                                        variant="temporary"
                                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                                        open={this.state.mobileOpen}
                                        onClose={this.handleDrawerToggle}
                                    >
                                        <Sidebar closeSideBar={this.handleDrawerToggle} />
                                    </Drawer>
                                </Hidden>
                                <Hidden smDown >
                                    <Drawer
                                        classes={{ paper: classes.drawer }}
                                        variant="permanent"
                                        open
                                    >
                                        <Sidebar />
                                    </Drawer>
                                </Hidden>

                                <IconButton
                                    aria-label="Open drawer"
                                    onClick={this.handleDrawerToggle}
                                    className={classes.drawerIcon}
                                >
                                    <MenuIcon />
                                </IconButton>

                                <div className={classes.body}>
                                    <Comp {...props} />
                                </div>
                            </div>
                        </React.Fragment>
                    ) : (
                        <Redirect
                            to={{
                                pathname: '/signup',
                                state: { from: props.location }
                            }}
                        />
                    )
                    }
                />
            );
        }
}

/**
 *
 * @param {*} theme
 */
const styles = (theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: colors.surface
    },
    promotionBar: {
        height: 50,
        width: '100%',
        backgroundColor: colors.black,
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center'
    },
    promotionButton: {
        border: '1px solid white',
        height: 25,
        width: 100,
        borderRadius: 4
    },
    sideBar: {
     
    },
    drawer: {
        height: '100vh',
        position: 'relative',
        width: '100%'
    },
    body: {
        flex: 1,
        height: '100vh',
        overflow: 'auto'
    },
    drawerIcon: {
        display: 'none',
        position: 'relative',
        marginTop: 10,
        marginBottom: 'auto',
        zIndex: 1,
        width: 50
    },
    '@media (max-width: 959px)': {
        drawerIcon: {
            display: 'block'
        }
    }
});

export default withStyles(styles, { withTheme: true })(PrivateRoute);