import initialState from '../initialState';
import {
    LOADING,
    GET_ALL_EVENTS,
    SET_CURRENT_EVENT,
    CREATE_EVENT_SUCCESS,
    UPDATE_EVENT_SUCCESS
} from '../../actions/types/events';

export default (state = initialState.events, action) => {
    switch (action.type) {
        case LOADING: {
            return {
                ...state,
                loading: action.state
            };
        }

        case GET_ALL_EVENTS: {
            return {
                ...state,
                items: [ ...action.items, ...state.items ]
            };
        }

        case SET_CURRENT_EVENT: {
            return {
                ...state,
                currentEvent: action.eventId
            };
        }

        case CREATE_EVENT_SUCCESS: {
            let newArr = [];

            state.items.map((item) => {
                if (action.item.id === item.id) {

                    return null;
                }

                return newArr.push(item);
            });

            return {
                ...state,
                items: [ ...newArr, action.item ]
            };
        }

        case UPDATE_EVENT_SUCCESS: {
            let newArr = [];

            state.items.map((item) => {
                if (action.item.id === item.id) {

                    return null;
                }

                return newArr.push(item);
            });

            return {
                ...state,
                items: [ ...newArr, action.item ]
            };
        }
        
        default:
            return state;
    }
};