import initialState from '../initialState';
import { LOADING, SET_WEDDING } from '../../actions/types/wedding';

export default (state = initialState.wedding, action) => {
    switch (action.type) {
        case LOADING: {
            return {
                ...state,
                loading: action.state
            };
        }

        case SET_WEDDING: {
            return {
                ...state,
                weddingId: action.weddingId
            };
        }

        default:
            return state;
    }
};