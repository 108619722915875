import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import globalStyles from '../../theme/globalStyles';
import { Text } from '../../components';
import { colors } from '../../theme';
import { formatIncoming } from '../../services/Dates.js';

/**
 *
 * @param {*} param0
 */
const EventBlock = ({ 
    classes, 
    photo,
    startTime,
    endTime,
    description,
    blurb,
    location,
    notes
    // guestsAttending,
    // comments
}) => (
    <div className={classes.block}>
        <img alt="Event Hero" src={photo && photo.url} className={classes.image} />

        {
            blurb !== '' &&
                <div className={classes.blurb}>
                    <Text mediumLarge align="center">{`< ${ blurb } >`}</Text>
                </div>
        }
        
        {
            description !== '' &&
                <div className={classes.description}>
                    <Text small>{description}</Text>
                </div>
        }

        {
            notes !== '' &&
                <div className={classes.notes}>
                    <Text small>Notes: {notes}</Text>
                </div>
        }

        <div className={classes.dateBox}>
            <Text small>
                From: {formatIncoming(startTime).readyDate}, {formatIncoming(startTime).readyTime} {formatIncoming(startTime).AmPm}
            </Text>
            <Text small>
                To: {formatIncoming(endTime).readyDate}, {formatIncoming(endTime).readyTime} {formatIncoming(endTime).AmPm}
            </Text>
        </div>

        {
            location !== '' &&
                <div className={classes.location}>
                    <Text small>Located At: {location}</Text>
                    {/* Todo: google maps  */}
                </div>
        }

        {/* <div className={classes.guests}>
            <Text small>Guests Subscribed:</Text>
            {
                guestsAttending && guestsAttending.map((guest) => (
                    <div key={guest.email + eventId}>
                        <Text medium>{guest.firstName} {guest.lastName}</Text>
                    </div>
                ))
            }
        </div> */}
        {/* <div className={classes.comments}>
            <Text large>Leave a Comment:</Text>
            {
                comments && comments.map((comment) => (
                    <div key={comment.id} className={classes.comment}>
                        <Text medium>{comment.content}</Text>
                    </div>
                ))
            }
        </div> */}
    </div>
);

/**
 *
 * @param {*} theme
 */
const styles = (theme) => ({
    blockRow: globalStyles.blockRow,
    block: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        padding: 20,
        marginBottom: 20,
        cursor: 'pointer',
        // border: `1px solid ${ colors.turquoise }`,
        borderRadius: 8,
        backgroundColor: colors.lightCyan
        // background: 'repeating-linear-gradient(45deg, #fff, #fff 10px, #e57373 10px, #e57373 20px)'
    },
    image: {
        backgroundColor: 'transparent',
        height: 'auto',
        width: '100%',
        // objectFit: 'contain',
        objectFit: 'cover',
        maxHeight: 500
    },
    blurb: {
        marginTop: 20,
        width: '100%',
        fontStyle: 'italic'
    },
    description: {
        marginTop: 20
    },
    dateBox: {
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: '100%',
        paddingTop: 20
    },
    date: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'center',
        [theme.breakpoints.down('470')]: {
            flex: 0.6
        }
    },
    notes: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        paddingTop: 20
    },
    location: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        width: '100%',
        paddingTop: 20
    },
    comments: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    guests: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        width: '100%',
        paddingTop: 20
    }
});

export default withStyles(styles)(EventBlock);