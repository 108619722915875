// const standard = {
//   red: '#d93025',
//   pink: '#e57373',
//   purple: '#800080',
//   orange: '#FFA500',
//   yellow: '#F7F2C2',
//   teal: '#21CBBB',
//   clear: '#00000000',
//   surface: '#FFFFFF',
//   black: '#000'
// };

// export const blackAndWhite = {
//   surface: '#fff',
//   borders: '#000',
//   text: '#000',
//   lightAccent: '#e6e7e8',
//   darkAccent: '#6d6e71',
//   contrast: '#414042',
//   ...standard
// };

// export const lightTheme = {
//   surface: 'rgba(237, 213, 242, 1)',
//   borders: 'rgba(124, 117, 82, 1)',
//   text: 'rgba(124, 117, 82, 1)',
//   lightAccent: 'rgba(204, 197, 201, 1)',
//   mediumAccent: 'rgba(173, 170, 161, 1)',
//   darkAccent: 'rgba(124, 117, 82, 1)',
//   contrast: 'rgba(237, 213, 242, 1)',
//   ...standard
// };

// export const darkTheme = {
//   surface: 'rgba(237, 213, 242, 1)',
//   borders: 'rgba(124, 117, 82, 1)',
//   text: 'rgba(124, 117, 82, 1)',
//   lightAccent: 'rgba(204, 197, 201, 1)',
//   mediumAccent: 'rgba(173, 170, 161, 1)',
//   darkAccent: 'rgba(124, 117, 82, 1)',
//   contrast: 'rgba(237, 213, 242, 1)',
//   ...standard
// };

export const allColors = {
    //  Scheme
    shampoo: 'rgba(237, 213, 242, 1)',
    lavender: 'rgba(204, 197, 201, 1)',
    darkMediumGrey: 'rgba(173, 170, 161, 1)',
    goldFusion: 'rgba(124, 117, 82, 1)',
    // Brand Blues
    lightBlue: '#e0f5f7',
    mediumLightBlue: '#b6e3ec',
    mediumBlue: '#86cde0',
    mediumDarkBlue: '#005d75',
    darkBlue: '#003f4f',
    skyBlue: '#56bfd8',
    inputBlue: '#438398',
    backgroundBlue: '#e0f5f7',
    gradientBlue: '#3499B1',
    gradientTurquoise: 'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(64,224,208,0.3435749299719888) 100%);',


    // Brand Greys
    lightGrey: '#e6e7e8',
    mediumLightGrey: '#d1d3d4',
    mediumGrey: '#939598',
    mediumDarkGrey: '#6d6e71',
    darkGrey: '#414042',
    hoverGrey: '#bfbfbf',
    mainTextGrey: '#000',

    // match MUI styles
    borderGrey: 'rgba(0, 0, 0, 0.12)',
    backgroundGrey: '#F7F9FA',

    // Brand Green
    lightGreen: '#aed581',
    brandGreen: '#6dc067',

    // Material UI Input Border grey
    muiBorderGrey: 'rgba(0, 0, 0, 0.23)',

    placeholder: '#666666',
    lightCyan: '#E0FFFF',
    turquoise: '#40E0D0',
    red: '#d93025',
    pink: '#e57373',
    purple: '#800080',
    orange: '#FFA500',
    yellow: '#faf13e',
    teal: '#21CBBB',
    clear: '#00000000',
    white: '#FFF',
    black: '#000',

    // Previous Colors
    subHeaderGrey: '#F2F4F5',
    // subTextGrey: '#656566',

    transparent: 'transparent',
    imageTextBG: 'rgba(0,0,0,0.5)'
};

let colors = allColors;

export { colors };