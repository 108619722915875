import React, { Component } from 'react';
import { Route, Switch, Router } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
    MuiThemeProvider,
    createMuiTheme,
    withStyles
} from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import PrivateRoute from './PrivateRoute';
import * as session from './services/session';
import store from './redux/store';

import { loadUserData } from './actions/user';
import Loader from './components/Loader';
import Notifier from './components/Notifier';
import Login from './containers/Login';
import Signup from './containers/Signup';
import NotFound from './containers/NotFound';
import InternalError from './containers/InternalError';
import Landing from './containers/Landing';
import Account from './containers/Account';
import Events from './containers/Events';
import Upload from './containers/Upload';
import Gallery from './containers/Gallery';
import GuestList from './containers/GuestList';
import Support from './containers/Support';
import Notifications from './containers/Notifications';
import createTheme from './theme/createTheme';

export const history = require("history").createBrowserHistory();

/**
 *
 * @param {*} theme
 */
const styles = (theme) => ({
    main: {
        height: 'auto',
        margin: '0',
        minHeight: '100vh',
        padding: '0',
        position: 'relative',
        zIndex: 1
    }
});

const theme = createMuiTheme(createTheme);

class App extends Component {
    state = {
        loading: true
    };

    componentDidMount() {
        this._pullData().then(() => {
            this.setState({
                loading: false
            });
        });
    }

    _pullData = async () => {
        const token = await session.getToken();

        if (token) {
            await store.dispatch(loadUserData());
        }
    };
   

    render() {
        const { classes } = this.props;

        if (this.state.loading) {
            return <Loader />;
        }

        return (
            <MuiThemeProvider theme={theme}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <CssBaseline />
                    <main className={classes.main}>
                        <Router history={history}>
                            <Switch>
                                <Route exact path="/signup" component={Signup} />
                                <Route exact path="/login" component={Login} />
                                <Route exact path="/" component={Landing} />
                                <Route exact path="/support" component={Support} />
                                <PrivateRoute
                                    theme={theme}
                                    exact
                                    path="/my-account"
                                    component={Account}
                                />
                                <PrivateRoute
                                    exact
                                    path="/events"
                                    component={Events}
                                />
                                <PrivateRoute
                                    exact
                                    path="/upload"
                                    component={Upload}
                                />
                                <PrivateRoute
                                    theme={theme}
                                    exact
                                    path="/gallery"
                                    component={Gallery}
                                />
                                <PrivateRoute
                                    exact
                                    path="/guest-list"
                                    component={GuestList}
                                />
                                <PrivateRoute
                                    exact
                                    path="/notifications"
                                    component={Notifications}
                                />
                                <Route path="/error" component={InternalError} />
                                <Route component={NotFound} />
                            </Switch>
                        </Router>
                        <Notifier />
                    </main>
                </MuiPickersUtilsProvider>
            </MuiThemeProvider>
        );
    }
}

export default withStyles(styles)(App);