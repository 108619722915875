import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Link from '@material-ui/core/Link';
import { colors } from '../../theme'; 
import { Button } from '../../components';

const useStyles = makeStyles((theme) => ({
    toolbar: {
        borderBottom: `1px solid ${ theme.palette.divider }`,
        paddingLeft: 0,
        paddingRight: 0
    },
    toolbarTitle: {
        flex: 1
    },
    toolbarSecondary: {
        justifyContent: 'space-between',
        overflowX: 'auto'
    },
    toolbarLink: {
        padding: theme.spacing(1),
        flexShrink: 0
    },
    loginBtn: {
        color: colors.gradientBlue
    },
    logo: { 
        height: 35,
        marginRight: 'auto',
        [theme.breakpoints.down('sm')]: {
            height: 25
        }
    }
}));

export default function Header(props) {
    const classes = useStyles();
    const { sections } = props;

    const _navigateTo = (link) => {
        props.history.push(link);
    };

    return (
        <React.Fragment>
            <Toolbar className={classes.toolbar}>
                {/* <Text
                    align="center"
                    largest
                    customStyle={{ fontFamily: 'Billabong', color: colors.mainTextGrey, margin: '0 auto' }}
                >
                    {title}
                </Text> */}
                <img src={require('../../assets/logo-full-color-medium.png')} className={classes.logo} alt="Hitchedagram Logo" />
                <IconButton>
                    <SearchIcon />
                </IconButton>
                <Button 
                    onClick={() => _navigateTo('/login')}
                    customStyle={classes.loginBtn}
                    color="primary"
                    variant="text"
                >
                    Login
                </Button>
                <Button 
                    onClick={() => _navigateTo('/signup')}
                    customStyle={classes.loginBtn}
                    color="secondary"
                >
                    Join Free
                </Button>
            </Toolbar>
            <Toolbar component="nav" variant="dense" className={classes.toolbarSecondary}>
                {sections.map((section) => (
                    <Link
                        color="inherit"
                        noWrap
                        key={section.title}
                        variant="body2"
                        href={section.url}
                        className={classes.toolbarLink}
                    >
                        {section.title}
                    </Link>
                ))}
            </Toolbar>
        </React.Fragment>
    );
}