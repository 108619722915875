import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import { globalStyles } from '../../theme';
import { Title, Loader, Text } from '../../components';
import Footer from '../Landing/Footer';

class Support extends Component {

    _goTo = (location) => {
        this.props.history.push(location);
    };

    render() {
        const { classes } = this.props;

        return (
            <React.Fragment>
                <Container maxWidth="lg">
                    <div className={`${ classes.content } ${ classes.contentFlex }`}>
                        <a href="/gallery"
                            rel="noopener noreferrer"
                        >
                            Home
                        </a>
                        <Title title="Support" />

                        <a 
                            href="mailto:support@hitchedagram.com" 
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            support@hitchedagram.com
                        </a>

                        <Text
                            large
                        >
                            Please email us if you are having any issues and we will get back to you shortly!
                        </Text>

                        <Grid item xs={12} alignment="row" md={8}>
                            <Text
                                small
                            >
                                Get the App!
                            </Text>

                            <a href="https://play.google.com/store/apps/details?id=com.hitchedagram.mobile" 
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img src={require('../../assets/play-store.png')} className={classes.symbol} alt="Google Play Symbol" />
                            </a>
                            <a href="https://apps.apple.com/app/id1492849993" 
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img src={require('../../assets/app-store.png')} className={classes.symbol} alt="App Store Symbol" />
                            </a>
                        </Grid>
                    </div>
                </Container>
                <Footer title="Hitched-a-gram" description="Capturing your big day from every angle!" />
                {this.props.loading && <Loader />}
            </React.Fragment>
        );
    }
}

/**
 * 
 * @param {*} theme 
 */
const styles = (theme) => ({
    container: globalStyles.container,
    content: globalStyles.content,
    contentFlex: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around'
    },
    symbol: {
        height: 60,
        width: 'auto',
        cursor: 'pointer'
    }
});

Support.propTypes = {
    loading: PropTypes.bool
};

/**
 * 
 * @param {*} state 
 */
const mapStateToProps = (state) => ({
    loading: state.user.loading
});

export default withStyles(styles)(connect(mapStateToProps)(Support));