import React from 'react';
import {withStyles} from '@material-ui/core';

import {colors} from '../theme/colors.js';

/**
 * @param props
 * @returns {*}
 * @constructor
 */
const OutlinedBox = props => {
	const {classes, children, customStyle} = props;

	return (
		<div className={`${classes.outlinedBox} ${customStyle}`}>
			{children}
		</div>
	);
};

/**
 * @param theme
 */
const styles = theme => ({
	outlinedBox: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
		width: '100%',
		minHeight: '56px',
		margin: '0',
		marginTop: '16px',
		marginBottom: '8px',
		padding: '0',
		paddingRight: '5px',
		paddingLeft: '5px',
		transition:
			'padding-left 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,border-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,border-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
		borderStyle: 'solid',
		borderWidth: '1px',
		borderRadius: '4px',
		borderColor: colors.muiBorderGrey,
		'&:hover': {
			borderColor: colors.darkBlue
			// borderColor: colors.skyBlue
		},
		'&:focus': {
			borderColor: colors.mediumDarkBlue
		},
		'&:focus-within': {
			borderColor: colors.skyBlue,
			backgroundColor: colors.lightGrey
		}
	}
});

export default withStyles(styles)(OutlinedBox);
