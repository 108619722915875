import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import { Button, Text } from '../../components';
import { colors } from '../../theme'; 

const useStyles = makeStyles((theme) => ({
    loginBtn: {
        color: colors.gradientBlue,
        width: 150
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        width: 300,
        height: 100
    },
    downloadContainer: {
        margin: 20,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    symbol: {
        height: 60,
        width: 'auto',
        cursor: 'pointer'
    }
}));

export default function Main(props) {
    const classes = useStyles();
    const { title } = props;

    const _navigateTo = (link) => {
        props.history.push(link);
    };

    return (
        <Grid item xs={12} alignment="column" md={8}>
            <Grid item xs={12} alignment="row" md={8}>
                <Text
                    small
                >
                    Get the App!
                </Text>

                <a href=" https://play.google.com/store/apps/details?id=com.hitchedagram.mobile" 
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img src={require('../../assets/play-store.png')} className={classes.symbol} alt="Google Play Symbol" />
                </a>
                <a href="https://apps.apple.com/app/id1492849993" 
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img src={require('../../assets/app-store.png')} className={classes.symbol} alt="App Store Symbol" />
                </a>
            </Grid>

            <Typography variant="h6" gutterBottom>
                {title}
            </Typography>

            <Divider />
            
            <div className={classes.container}>
                <Link display="block" variant="body1" href="mailto:ncmoseley@gmail.com,jmoseley@jeremymoseley.net" target="_blank">
                    Email Support
                </Link>

                <Button 
                    onClick={() => _navigateTo('/support')}
                    customStyle={classes.loginBtn}
                    color="secondary"
                >
                    Support Page
                </Button>
            </div>

        </Grid>
    );
}