import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange';
import CloseIcon from '@material-ui/icons/Close';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import 'react-dates/initialize';

import '../../../src/components/DatePicker/datepicker.css';
import { colors } from '../../theme';
import TextInput from '../TextFields/TextInput';

class DatePicker extends Component {
    state = {
        selectedOption: 'Last 7 days',
        customDateRange: false,
        startDate: this.props.startDate,
        endDate: this.props.endDate,
        anchorEl: null,
        focusedInput: 'startDate'
    };

    _setSelectedOption = () => {
        const startDate = this.state.startDate._d.toString();
        const endDate = this.state.endDate._d.toString();
        this.props.submitDate(startDate, endDate);
    };

    _onDatesChange = async (startDate, endDate) => {
        // TODO: check UX
        if (endDate === null) {
            await this.setState({
                startDate: moment(startDate),
                endDate: moment(startDate)
            });

            return;
        }

        await this.setState({
            startDate: moment(startDate),
            endDate: moment(endDate)
        });

        this._setSelectedOption();
    };

    _popupState = (event) => {
        this.setState({
            anchorEl: event.currentTarget
        });
    };

    /**
     * Toggle custom date picker
     * @private
     */
    _customDateRange = () => {
        this.setState({ customDateRange: !this.state.customDateRange });
    };

    _onBlur = (type, event) => {
        if (event.target.value === 'Custom Date Range') {
            this._customDateRange();
        }

        this.setState({
            selectedOption: type
        });

        if (event) {
            this.setState({ selectedOption: event.target.value });
        }

        this._handleInputChange(type, event);
    };

    _handleInputChange = async (type, event) => {
        if (event.target.value === 'Custom Date Range') {
            this._customDateRange();
            return;
        }

        if (event.target.value === 'Last 7 days') {
            await this.setState({
                startDate: moment().subtract(1, 'week'),
                endDate: moment(),
                selectedOption: event.target.value
            });
        }

        if (event.target.value === 'Last 30 days') {
            await this.setState({
                startDate: moment().subtract(1, 'month'),
                endDate: moment(),
                selectedOption: event.target.value
            });
        }

        if (event.target.value === 'Last 60 days') {
            await this.setState({
                startDate: moment().subtract(2, 'month'),
                endDate: moment(),
                selectedOption: event.target.value
            });
        }

        if (event.target.value === 'Last 6 months') {
            await this.setState({
                startDate: moment().subtract(6, 'month'),
                endDate: moment(),
                selectedOption: event.target.value
            });
        }

        this._setSelectedOption();
    };

    render() {
        const { classes } = this.props;

        // showing custom date picker
        if (this.state.customDateRange) {
            return (
                <div className={classes.dateRangeBorder}>
                    <DateRangeIcon
                        className={classes.calendarIcon}
                        // onClick={this._customDateRange}
                    />

                    <DateRangePicker
                        startDate={this.state.startDate}
                        startDateId={'START_DATE'}
                        endDate={this.state.endDate}
                        endDateId={'END_DATE'}
                        numberOfMonths={1}
                        enableOutsideDays={true}
                        isOutsideRange={(day) => moment().diff(day) < 0}
                        onClose={this._setSelectedOption}
                        // displayFormat={'DD/MMM/YYYY'}

                        onDatesChange={({ startDate, endDate }) => this._onDatesChange(startDate, endDate)
                        }
                        focusedInput={this.state.focusedInput}
                        onFocusChange={(focusedInput) => this.setState({ focusedInput })
                        }
                    />
                    <CloseIcon
                        className={classes.closeModalIcon}
                        onClick={this._customDateRange}
                    />
                </div>
            );
        }

        return (
            <TextInput
                variant="outlined"
                color="primary"
                aria-label="Select Date Range"
                customStyle={classes.datePicker}
                label="Dates"
                fullWidth
                select
                selectOptions={[
                    {
                        key: 0,
                        value: 'Last 7 days'
                    },
                    {
                        key: 1,
                        value: 'Last 30 days'
                    },
                    {
                        key: 2,
                        value: 'Last 60 days'
                    },
                    {
                        key: 3,
                        value: 'Last 6 months'
                    },
                    {
                        key: 4,
                        value: 'Custom Date Range'
                    }
                ]}
                onClick={this._popupState}
                value={this.state.selectedOption}
                onBlur={(value) => this._onBlur('selectedOption', value)}
                onChange={(value) => this._handleInputChange('selectedOption', value)
                }
            />
        );
    }
}

DatePicker.propTypes = {
    classes: PropTypes.object
};

/**
 * @param theme
 */
const styles = (theme) => ({
    closeModalIcon: {
        cursor: 'pointer',
        display: 'inline',
        fontSize: '16px',
        marginRight: '5px',
        color: colors.mediumDarkGrey,
        zIndex: '9'
    },
    calendarIcon: {
        display: 'inline',
        fontSize: '16px',
        marginLeft: '12px',
        color: colors.darkBlue,
        zIndex: '9'
    },
    datePicker: {
        marginTop: '0px',
        color: colors.skyBlue,
        backgroundColor: colors.surface,
        textTransform: 'capitalize',
        // width: '300px',
        '&:hover': {
            color: colors.surface
        }
    },
    dateBlock: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%'
    },
    dateField: {
        width: '50%',
        marginTop: '16px',
        marginBottom: '8px'
    },
    dateRangeBorder: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        margin: '0',
        padding: '0',
        transition:
			'padding-left 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,border-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,border-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderRadius: '4px',
        borderColor: colors.muiBorderGrey,
        marginTop: '16px',
        marginBottom: '8px',
        '&:hover': {
            borderColor: colors.darkBlue
        },
        '&:focus': {
            borderColor: colors.mediumDarkBlue
        },
        '&:focus-within': {
            borderColor: colors.skyBlue,
            backgroundColor: colors.lightGrey
        }
    }
});

export default withStyles(styles)(DatePicker);