import React from 'react';
import MUIButton from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

/**
 *
 * @param {*} props
 */
const Button = (props) => {
    const {
        classes,
        onClick,
        customStyle,
        variant,
        marginTop,
        children,
        disabled,
        fullWidth,
        color
    } = props;

    return (
        <MUIButton
            color={color}
            disabled={disabled}
            variant={variant || 'contained'}
            className={
                customStyle
                    ? `${ classes.btn } ${ customStyle }}`
                    : `${ classes.btn }`
            }
            style={{
                marginTop: marginTop || 0,
                width: fullWidth ? '100%' : 'auto'
            }}
            onClick={onClick}
        >
            {children}
        </MUIButton>
    );
};

/**
 *
 * @param {*} theme
 */
const styles = (theme) => ({
    btn: {
        transition: 'all 0.2s ease',
        float: 'right'
        // fontFamily: fonts.type.base
    }
    // transparent: {
    //     width: 'fit-content',
    //     color: colors.darkBlue,
    //     fontFamily: fonts.type.base,
    //     boxShadow: 'none',
    //     backgroundColor: colors.clear,
    //     paddingLeft: '10px',
    //     paddingRight: '10px',
    //     '&:hover': {
    //         backgroundColor: colors.clear
    //     }
    // }
});

export default withStyles(styles)(Button);