import React from 'react';
import Text from './Text';

/**
 *
 * @param {*} param0
 */
const Title = ({title}) => (
	<Text extraLarge bold align="center" display="block" margin="0 0 30px 0">
		{title}
	</Text>
);

export default Title;
