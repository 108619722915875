import initialState from '../initialState';
import {
    LOADING,
    SET_FORM_FIELD,
    // ADD_COUPLE_SUCCESS,
    SIGNUP_OWNER_SUCCESS
    // CLEAR_BENEFICIARY_FORM,
    // EDIT_DEPENDENT,
    // REMOVE_COUPLE,
    // REMOVE_BENEFICIARY,
    // EDIT_BENEFICIARY
} from '../../actions/types/signup';

export default (state = initialState.signup, action) => { 
    switch (action.type) {
        case LOADING: {
            return {
                ...state,
                loading: action.state
            };
        }

        case SET_FORM_FIELD: {
            return {
                ...state,
                [action.section]: {
                    ...state[action.section],
                    [action.key]: action.value
                }
            };
        }

        case SIGNUP_OWNER_SUCCESS: {
            return {
                ...state,
                userDetails: action.userAndWedding.user,
                wedding: action.userAndWedding.wedding
            };
        }

        // case ADD_COUPLE_SUCCESS: {
        //     console.log('ADD_COUPLE_SUCCESS', action.spouse);
        //     return {
        //         ...state,
        //         beneficiaries: {
        //             ...state.beneficiaries,
        //             items: [
        //                 ...state.beneficiaries.items,
        //                 action.beneficiary
        //             ]
        //         }
        //     };
        // }

        // case CLEAR_BENEFICIARY_FORM: {
        //     return {
        //         ...state,
        //         beneficiaries: {
        //             ...state.beneficiaries,
        //             firstName: false,
        //             lastName: false,
        //             relationship: false,
        //             birthdate: false,
        //             shares: 0
        //         }
        //     };
        // }

        // case EDIT_DEPENDENT: {
        //     return {
        //         ...state,
        //         dependents: {
        //             ...state.dependents,
        //             items: state.dependents.items.map((item, i) => {
        //                 if (i === action.index) {
        //                     return action.dependent;
        //                 }

        //                 return item;

        //             })
        //         }
        //     };
        // }

        // case REMOVE_COUPLE: {
        //     return {
        //         ...state,
        //         dependents: {
        //             ...state.dependents,
        //             items: state.dependents.items.filter((item, i) => i !== action.index)
        //         }
        //     };
        // }

        // case EDIT_BENEFICIARY: {
        //     return {
        //         ...state,
        //         beneficiaries: {
        //             ...state.beneficiaries,
        //             items: state.beneficiaries.items.map((item, i) => {
        //                 if (i === action.index) {
        //                     return action.beneficiary;
        //                 }

        //                 return item;

        //             })
        //         }
        //     };
        // }

        // case REMOVE_BENEFICIARY: {
        //     return {
        //         ...state,
        //         beneficiaries: {
        //             ...state.beneficiaries,
        //             items: state.dependents.items.filter((item, i) => i !== action.index)
        //         }
        //     };
        // }

        default:
            return state;
    }
};