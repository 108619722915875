import * as notifier from './notifier';
import * as guests from '../xhr/guests';
import * as types from './types/guests';

/**
 *
 * @param {*} dispatch
 * @param {*} state
 */
function setLoading(dispatch, state) {
    dispatch({
        type: types.LOADING,
        state
    });
}

/**
 * @param weddingId
 * @returns {function(*=): Promise<number | never | boolean>}
 * @constructor
 */
export function getGuests(weddingId) {
    return (dispatch) => {
        setLoading(dispatch, true);

        return guests
            .getGuests(weddingId)
            .then((data) => {
                dispatch({
                    type: types.GET_GUESTS,
                    items: data.guests
                });

                setLoading(dispatch, false);
                return data;
            })
            .catch((error) => {
                setLoading(dispatch, false);
                dispatch(notifier.displayError(error));
                return false;
            });
    };
}

/**
 * @param guest
 * @returns {function(*=)}
 */
export function addGuest(guest) {
    return (dispatch) => {
        setLoading(dispatch, true);

        return guests
            .addGuest(guest)
            .then((data) => {
                dispatch({
                    type: types.ADD_GUEST_SUCCESS,
                    guest
                });

                setLoading(dispatch, false);
                dispatch(notifier.displayMessage('Guest Added successfully!'));

                return true;
            })
            .catch((error) => {
                setLoading(dispatch, false);
                dispatch(notifier.displayError(error));
            });
    };
}

/**
 * @param weddingId
 * @param form
 * @returns {function(*=): Promise<boolean | never>}
 */
export function uploadGuestForm(form, weddingId) {
    return (dispatch) => {
        setLoading(dispatch, true);

        return guests
            .uploadGuestForm(form, weddingId)
            .then((data) => {
                dispatch({
                    type: types.UPLOAD_GUESTS_SUCCESS,
                    data
                });

                setLoading(dispatch, false);
                dispatch(notifier.displayMessage('Guests added successfully!'));
                return true;
            })
            .catch((error) => {
                setLoading(dispatch, false);
                dispatch(notifier.displayError(error));
            });
    };
}