import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import { withStyles } from '@material-ui/core/styles';

import { Loader, Button, SignUpModal } from '../../components';
import UserDetails from './Forms/UserDetails';
import Couple from './Forms/Couple';
import Wedding from './Forms/Wedding';
import Guests from './Forms/Guests';
import { globalStyles } from '../../theme';
import { createEvent } from '../../actions/events';
import { loadUserData, login } from '../../actions/user';

class Signup extends PureComponent {
    state = {
        activeStep: 0,
        userDetails: null,
        weddingDetails: null,
        coupleDetails: null,
        guests: []
    };

    /**
     * Pass this method to each tab view, store the data returned from each tab
     * @param data
     * @param step
     * @param isFinish
     * @private
     */
    _stepCompletion = (data, step, isFinish = false) => {
        this.setState({
            [step]: data
        });

        if (isFinish) {
            this._finishSignup();
        } else {
            this._handleNext();
        }
    };

    /**
     * Once all steps are complete call this method to send the data to the API and to create user
     * @private
     */
    _finishSignup = async () => {
        const data = {
            userDetails: { ...this.state.userDetails },
            weddingDetails: { ...this.state.weddingDetails },
            coupleDetails: { ...this.state.coupleDetails },
            guests: [ ...this.state.guests ]
        };

        const loggedIn = await this.props.dispatch(login(
            data.userDetails.userDetails.email, 
            data.userDetails.userDetails.password
        ));

        let success = false;

        const event = {
            weddingId: data.weddingDetails.weddingId,
            photoId: undefined,
            name: data.weddingDetails.name,
            description: '(Please add description about the big day)',
            blurb: '(Please add your catch phrase or #hashtag)',
            startTime: data.weddingDetails.date,
            endTime: data.weddingDetails.date,
            location: '(Please add the location)',
            notes: '(Add notes if necessary)',
            guestsAttending: [ loggedIn.user.id ]
        };

        success = await this.props.dispatch(createEvent(event));

        if (success) {
            await this.props.dispatch(loadUserData());

            this._navigateTo('/gallery');
        }

        return true;
    };

    _getStepContent = (index) => {
        switch (index) {
            case 1: {
                return (
                    <Couple
                        stepCompletion={this._stepCompletion}
                        goBack={this._handleBack}
                    />
                );
            }

            case 2: {
                return (
                    <Wedding
                        stepCompletion={this._stepCompletion}
                        goBack={this._handleBack}
                    />
                );
            }

            case 3: {
                return (
                    <Guests
                        guests={ this.state.guests }
                        stepCompletion={this._stepCompletion}
                        goBack={this._handleBack}
                    />
                );
            }

            default: {
                return (
                    <UserDetails
                        stepCompletion={this._stepCompletion}
                        goBack={this._handleBack}
                        // userID={ this.props.match.params.userID }
                        // weddingID={ this.props.match.params.accountID }
                    />
                );
            }
        }
    };

    _navigateTo = (link) => {
        this.props.history.push(link);
    }

    _handleNext = () => {
        this.setState((state) => ({
            activeStep: state.activeStep + 1
        }));
    };

    _handleBack = () => {
        this.setState((state) => ({
            activeStep: state.activeStep - 1
        }));
    };

    _handleReset = () => {
        this.setState((state) => ({
            activeStep: 0
        }));
    };

    render() {
        const { classes } = this.props;
        const { activeStep } = this.state;
        const steps = [
            'Your Details',
            'The Happy Couple',
            'The Wedding',
            'Add Guests'
        ];

        return (
            <SignUpModal
                open={true}
                close={() => this._navigateTo('/login')}
                maxWidth={'xl'}
                fullWidth
                disableBackdropClick
                disableEscapeKeyDown
                onSave={this._confirm}
            >
                <div className={classes.titleAndButtons}>
                    <img
                        alt="Hitchedagram Logo"
                        src={require('../../assets/logo-full-color-medium.png')}
                        className={classes.logo}
                    /> 

                    {
                        activeStep === 0 && 
                        <React.Fragment>
                            <Button 
                                onClick={() => this._navigateTo('/')}
                                customStyle={classes.homeBtn}
                                variant="text"
                            >
                                    Home
                            </Button>
                                
                            <Button 
                                onClick={() => this._navigateTo('/login')}
                                customStyle={classes.loginBtn}
                                variant="text"
                            >
                                    Login
                            </Button>
                        </React.Fragment>
                    }               
                </div>

                <div>
                    {activeStep === steps.length ? (
                        <div>
                            <Typography className={classes.instructions}>
                                All steps completed - you&apos;re finished
                            </Typography>
                            <Button onClick={this._handleReset} className={classes.button}>
                                Reset
                            </Button>
                        </div>
                    ) : (
                        <div>
                            <div className={classes.instructions}>
                                {this._getStepContent(activeStep)}
                            </div>
                            <Stepper activeStep={activeStep}>
                                {steps.map((label, index) => {
                                    const stepProps = {};
                                    const labelProps = {};

                                    return (
                                        <Step key={label} {...stepProps}>
                                            <StepLabel {...labelProps}>{label}</StepLabel>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                            {/* <div className={classes.stepperWrapper}>
                                <Button disabled={activeStep === 0} onClick={this._handleBack} className={classes.button}>
                                    Back
                                </Button>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this._handleNext}
                                    className={classes.button}
                                >
                                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                </Button>
                            </div> */}
                        </div>
                    )}
                </div>

                {this.props.loading && <Loader />}
            </SignUpModal>
        );
    }
}

/**
 *
 * @param {`*`} theme
 */
const styles = (theme) => ({
    container: globalStyles.container,
    titleAndButtons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around'
    },
    loginBtn: {
        // color: colors.white,
        textTransform: 'lowercase'
    },
    homeBtn: {
        // color: colors.white,
        textTransform: 'lowercase'
    },
    logo: {
        display: 'block',
        margin: '0 auto',
        maxHeight: 220,
        maxWidth: 195
        // paddingBottom: '25px',
        // paddingTop: '35px'
    },
    stepper: globalStyles.stepper,
    stepperWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around'
    },
    instructions: {
        // marginTop: 20,
        // marginBottom: 20
    }
});

/**
 *
 * @param {*} state
 */
const mapStateToProps = (state) => ({
    loading: state.signup.loading,
    initData: state.signup.initData,
    wedding: state.wedding,
    user: state.user.meta
});

export default withStyles(styles)(connect(mapStateToProps)(Signup));