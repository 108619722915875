import React, { Component } from 'react';
import { connect } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import InputAdornment from '@material-ui/core/InputAdornment';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import {
    TextInput,
    Text,
    Button,
    NumberInput,
    FormModal
} from '../../components';
import { savePaymentDetails } from '../../actions/user';
import { colors } from '../../theme';
import validateInput from '../../services/FormValidation/validateInput';

class Payments extends Component {
        state = {
            accountHolder: '',
            institutionName: '',
            institutionAddress: '',
            institutionNum: '',
            transitNum: '',
            accountNum: '',
            accepted: false,
            timestampOfAcceptance: null,
            password: '',
            confirmPasswordModalOpen: false,
            visibility: false
        };

        _onBlur = (type) => {
            this.setState({
                [`${ type }Error`]: validateInput('presence', this.state[type])
            });
        };

        _handleInputChange = (type, value) => {
            if (type === 'accepted' && value === true) {
                this.setState({
                    [type]: value,
                    timestampOfAcceptance: Math.floor(new Date().getTime() / 1000)
                });
            } else {
                this.setState({
                    [type]: value
                });
            }
        };

        _validateForm = () => {
            const accountHolderError = validateInput(
                'presence',
                this.state.accountHolder
            );
            const institutionNameError = validateInput(
                'presence',
                this.state.institutionName
            );
            const institutionAddressError = validateInput(
                'presence',
                this.state.institutionAddress
            );
            const accountNumError = validateInput(
                'presence',
                this.state.accountNum
            );
            const institutionNumError =
                this.state.institutionNum.toString().length !== 3
                    ? 'Financial Institution # must be exactly 3 digits.'
                    : null;
            const transitNumError =
                this.state.transitNum.toString().length !== 5
                    ? 'Branch/Transit # must be exactly 5 digits.'
                    : null;
            let acceptedError = this.state.accepted
                ? null
                : 'You must accept the terms to continue.';

            this.setState({
                accountHolderError,
                institutionNameError,
                institutionAddressError,
                institutionNumError,
                transitNumError,
                accountNumError,
                acceptedError
            });

            if (
                accountHolderError ||
                institutionNameError ||
                institutionAddressError ||
                institutionNumError ||
                transitNumError ||
                accountNumError ||
                acceptedError
            ) {
                return false;
            }

            this._showConfirmPasswordDialog();
            return true;
        };

        _toggleVisibility = () => {
            this.setState({
                visibility: !this.state.visibility
            });
        };

        _showConfirmPasswordDialog = () => {
            this.setState({
                confirmPasswordModalOpen: !this.state.confirmPasswordModalOpen
            });
        };

        _save = () => {
            const success = this.props.dispatch(
                savePaymentDetails({
                    accountHolder: this.state.accountHolder,
                    institutionName: this.state.institutionName,
                    institutionAddress: this.state.institutionAddress,
                    institutionNum: Number(this.state.institutionNum),
                    transitNum: Number(this.state.transitNum),
                    accountNum: Number(this.state.accountNum),
                    accepted: this.state.accepted,
                    timestampOfAcceptance: Number(this.state.timestampOfAcceptance),
                    password: this.state.password
                })
            );

            if (success) {
                this.props.close();
            }

            return true;
        };

        render() {
            const { meta } = this.props;
            const {
                accountHolder,
                institutionName,
                institutionAddress,
                institutionNum,
                transitNum,
                accountNum
            } = this.state;

            return (
                <FormModal
                    open={true}
                    close={this.props.close}
                    title="Payment Details"
                    disableBackdropClick
                    disableEscapeKeyDown
                >
                    {this.state.confirmPasswordModalOpen ? (
                        <React.Fragment>
                            <Text align="left" medium display="inline-block">
                                Please enter your account password below to confirm
                                your payment changes.
                            </Text>

                            <TextInput
                                label="Password"
                                value={this.state.password}
                                inputProps={{
                                    endAdornment: (
                                        <InputAdornment>
                                            {this.state.visibility ? (
                                                <VisibilityIcon
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={this._toggleVisibility}
                                                />
                                            ) : (
                                                <VisibilityOffIcon
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={this._toggleVisibility}
                                                />
                                            )}
                                        </InputAdornment>
                                    )
                                }}
                                onBlur={() => this._onBlur('password')}
                                onChange={(e) => this._handleInputChange(
                                    'password',
                                    e.target.value
                                )
                                }
                                error={Boolean(this.state.passwordError)}
                                helperText={this.state.passwordError}
                                secure={!this.state.visibility}
                                fullWidth
                                autofocus
                            />

                            <Button
                                onClick={this._save}
                                marginTop={40}
                                color="primary"
                                fullWidth
                            >
                                Finish
                            </Button>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            {Number(meta.paymentsSetup) === 1 && (
                                <Text medium bold color={colors.brandGreen}>
                                    You have already submitted valid banking
                                    details. If you would like to change them please
                                    fill out the form below and we will overwrite
                                    your existing details.
                                </Text>
                            )}

                            <TextInput
                                label="Account Holder Name"
                                value={accountHolder}
                                onBlur={() => this._onBlur('accountHolder')}
                                onChange={(e) => this._handleInputChange(
                                    'accountHolder',
                                    e.target.value
                                )
                                }
                                error={Boolean(this.state.accountHolderError)}
                                helperText={
                                    this.state.accountHolderError ||
                                    '(As it appears on your cheque)'
                                }
                                fullWidth
                            />

                            <TextInput
                                label="Financial Institution Name"
                                value={institutionName}
                                onBlur={() => this._onBlur('institutionName')}
                                onChange={(e) => this._handleInputChange(
                                    'institutionName',
                                    e.target.value
                                )
                                }
                                error={Boolean(this.state.institutionNameError)}
                                helperText={this.state.institutionNameError}
                                fullWidth
                            />

                            <TextInput
                                label="Financial Institution Address"
                                value={institutionAddress}
                                onBlur={() => this._onBlur('institutionAddress')}
                                onChange={(e) => this._handleInputChange(
                                    'institutionAddress',
                                    e.target.value
                                )
                                }
                                error={Boolean(this.state.institutionAddressError)}
                                helperText={this.state.institutionAddressError}
                                fullWidth
                            />

                            <NumberInput
                                label="Financial Institution #"
                                value={institutionNum}
                                onBlur={() => this._onBlur('institutionNum')}
                                onChange={(e) => this._handleInputChange(
                                    'institutionNum',
                                    e.target.value
                                )
                                }
                                error={Boolean(this.state.institutionNumError)}
                                helperText={
                                    this.state.institutionNumError || '(3 digits)'
                                }
                                fullWidth
                            />

                            <NumberInput
                                label="Branch/Transit #"
                                value={transitNum}
                                onBlur={() => this._onBlur('transitNum')}
                                onChange={(e) => this._handleInputChange(
                                    'transitNum',
                                    e.target.value
                                )
                                }
                                error={Boolean(this.state.transitNumError)}
                                helperText={
                                    this.state.transitNumError || '(5 digits)'
                                }
                                fullWidth
                            />

                            <NumberInput
                                label="Account #"
                                value={accountNum}
                                onBlur={() => this._onBlur('accountNum')}
                                onChange={(e) => this._handleInputChange(
                                    'accountNum',
                                    e.target.value
                                )
                                }
                                error={Boolean(this.state.accountNumError)}
                                helperText={
                                    this.state.accountNumError ||
                                    '(If it starts with zero, please include the zero.)'
                                }
                                fullWidth
                            />

                            <FormControl error={Boolean(this.state.acceptedError)}>
                                <FormGroup row style={{ marginTop: 20 }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.state.accepted}
                                                onChange={() => this._handleInputChange(
                                                    'accepted',
                                                    !this.state.accepted
                                                )
                                                }
                                                value="acceptedTerms"
                                            />
                                        }
                                        label="I hereby authorize Hitched-A-Gram to deposit funds directly to my personal bank account."
                                    />
                                </FormGroup>

                                {this.state.acceptedError && (
                                    <FormHelperText>
                                        {this.state.acceptedError}
                                    </FormHelperText>
                                )}
                            </FormControl>

                            <Button
                                onClick={this._validateForm}
                                marginTop={40}
                                color="primary"
                                fullWidth
                            >
                                Save
                            </Button>
                        </React.Fragment>
                    )}
                </FormModal>
            );
        }
}

/**
 *
 * @param {*} state
 */
const mapStateToProps = (state) => ({
    loading: state.user.loading,
    meta: state.user.meta
});

export default connect(mapStateToProps)(Payments);