import { colors, fonts } from './index';

// Apply Material Ui Defaults and overrides here
const createTheme = {
    typography: {
        useNextVariants: true,
        fontFamily: [
            'Montserrat',
            'sans-serif'
        ].join(',')
    },
    fontFamily: fonts.type.base,
    palette: {
        primary: {
            light: colors.lightCyan,
            main: colors.gradientBlue,
            contrastText: colors.white
        },
        secondary: {
            light: colors.lightCyan,
            main: colors.turquoise,
            contrastText: colors.white
        },
        error: {
            light: colors.pink,
            main: colors.red,
            contrastText: colors.surface
        }
    },
    overrides: {
        MuiTouchRipple: {
            root: {
                color: colors.gradientBlue
            }
        },
        MuiListItem: {
            root: {
                // backgroundColor: colors.white,
                '&$active': {
                    // backgroundColor: colors.lightCyan
                }
            }
        },
        MuiTable: {
            root: {
                fontFamily: fonts.type.base,
                minWidth: '100%'
            }
        },
        MuiTableCell: {
            head: {
                fontFamily: fonts.type.heading,
                fontSize: fonts.size.medium,

                /*
				 * backgroundColor: colors.skyBlue,
				 * background: `linear-gradient(to right, ${colors.gradientBlue} 0%, ${colors.skyBlue} 100%)`,
				 */
                color: colors.surface
            },
            body: {
                color: colors.mainTextGrey,
                fontFamily: fonts.type.base,
                fontSize: fonts.size.medium
            },
            root: {
                borderBottom: 'none',
                whiteSpace: 'nowrap',
                padding: '4px 2vw 4px 1.96vw'
            }
        },
        MuiPaper: {
            rounded: {
                borderRadius: 8
            }
        },
        MuiTableHead: {},
        MuiTableRow: {
            head: {
                fontFamily: fonts.type.heading,
                fontSize: fonts.size.medium,
                background: `linear-gradient(to right, ${ colors.skyBlue } 0%, ${ colors.skyBlue } 100%)`,
                // background: colors.skyBlue,
                color: colors.surface,
                borderTop: `2px solid ${ colors.surface }`
            },
            root: {
                backgroundColor: colors.backgroundGrey,
                '&:nth-of-type(odd)': {
                    backgroundColor: colors.surface
                }
            }
        },
        MuiDrawer: {
            paperAnchorDockedLeft: {
                border: 'none !important'
            }
        },
        MuiSelect: {
            select: {

                /*
				 * minWidth: 0,
				 * TODO: test style
				 * paddingLeft: '12px'
				 */
                '&:focus': {
                    background: `${ colors.clear } !important`
                }
            }
        },
        MuiButton: {
            root: {
                textTransform: 'unset'
                // '&:hover': {
                //     backgroundColor: `${ colors.turquoise } !important`,
                //     // transform: 'scale(1.5)',
                //     color: colors.white
                // }
            },
            label: {
                whiteSpace: 'nowrap'
                // color: colors.gradientBlue
            }
        },
        MuiIconButton: {
            root: {
                color: colors.mainTextGrey,
                '&:hover': {
                    // backgroundColor: `${ colors.hoverGrey } !important`
                    // backgroundColor: `${ colors.turquoise } !important`
                }
            }
        },
        MuiIcon: {
            root: {
                color: colors.mainTextGrey
            }
        },
        MuiFormLabel: {
            root: {
                fontFamily: fonts.type.light
            }
        },
        MuiInputBase: {
            root: {
                color: colors.mainTextGrey,
                fontFamily: fonts.type.base
            },
            input: {
                color: colors.mainTextGrey,
                fontFamily: fonts.type.base
            }
        },
        MuiOutlinedInput: {
            root: {
                '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
                    // borderColor: colors.pink
                }
            }
        },
        MuiInput: {
            underline: {
                '&:hover:not($disabled):before': {
                    // borderColor: `${ colors.gradientBlue } !important`
                }
            }
        },
        MuiStepIcon: {
            root: {
                '&$completed': {
                    color: colors.brandGreen
                },
                '&$active': {
                    // color: colors.red
                    // transform: 'scale(1.5)'
                }
            },
            active: {},
            completed: {},
            text: {
                fill: colors.surface,
                fontFamily: fonts.type.medium
            }
        },
        MuiStepLabel: {
            active: {
                // fontSize: '48px'
            }
        },
        MuiChip: {
            root: {
                borderRadius: '4px',
                fontFamily: fonts.type.medium
            },
            label: {
                color: colors.surface
            },
            deleteIcon: {
                fill: colors.surface,
                cursor: 'pointer'
            }
        },
        MuiExpansionPanel: {
            root: {
            },
            rounded: {
                '&:last-child': {
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0
                }
            }
        },
        MuiExpansionPanelSummary: {
            root: {
            },
            "& $expanded": {
                backgroundColor: colors.turquoise,
                color: colors.white
            },
            content: {
                // paddingLeft: '20px',
                // paddingRight: '20px',
                // color: colors.surface
            }
        },
        MuiDialog: {
            paperScrollPaper: {
                maxHeight: 'unset !important'
            }
        }
    }
};

export default createTheme;