import * as notifier from './notifier';
import * as types from './types/signup';
import * as api from '../xhr/signup';
import * as guests from '../xhr/guests';
import * as session from '../services/session';
import { loadUserData } from './user';

/**
 *
 * @param {*} dispatch
 * @param {*} state
 */
function setLoading(dispatch, state) {
    dispatch({
        type: types.LOADING,
        state
    });
}

/**
 * @param data
 * @returns {function(*=)}
 */
export function addWeddingDetails(data) {
    return (dispatch) => {
        setLoading(dispatch, true);

        return api
            .addWeddingDetails(data)
            .then(() => {
                setLoading(dispatch, false);
                dispatch(notifier.displayMessage('Wedding created successfully!'));

                return true;
            })
            .catch((error) => {
                setLoading(dispatch, false);
                dispatch(notifier.displayError(error));
            });
    };
}

/**
 * @param data
 * @returns {function(*=)}
 */
export function signupOwner(data) {
    return (dispatch) => {
        setLoading(dispatch, true);

        return api
            .signup(data)
            .then(async (userAndWedding) => {
                dispatch({
                    type: types.SIGNUP_OWNER_SUCCESS,
                    userAndWedding
                });

                // login
                await session.setToken(userAndWedding.jwt);
                await session.setWeddingToken(userAndWedding.wedding.id);

                await dispatch(loadUserData());

                setLoading(dispatch, false);
                dispatch(notifier.displayMessage('User Account created successfully!'));

                return true;
            })
            .catch((error) => {
                setLoading(dispatch, false);
                dispatch(notifier.displayError(error));
            });
    };
}

/**
 * @param spouse
 * @returns {function(*=)}
 */
export function addCouple(spouse) {
    return (dispatch) => {
        setLoading(dispatch, true);

        return guests
            .addGuest(spouse)
            .then((spouseData) => {
                // dispatch({
                //     type: types.ADD_COUPLE_SUCCESS,
                //     spouse
                // });

                setLoading(dispatch, false);
                dispatch(notifier.displayMessage('Spouse Account created successfully!'));

                return true;
            })
            .catch((error) => {
                setLoading(dispatch, false);
                dispatch(notifier.displayError(error));
            });
    };
}

/**
 * @param guest
 * @returns {function(*=)}
 */
export function addGuest(guest) {
    return (dispatch) => {
        setLoading(dispatch, true);

        return guests
            .addGuest(guest)
            .then(() => {
                setLoading(dispatch, false);
                dispatch(notifier.displayMessage('Guest added successfully!'));
                return true;
            })
            .catch((error) => {
                setLoading(dispatch, false);
                dispatch(notifier.displayError(error));
            });
    };
}

/**
 * Store the form value in state for the signup form steppers
 * @param section
 * @param key
 * @param value
 * @returns {Function}
 */
export function updateFormField(section, key, value) {
    return (dispatch) => {
        dispatch({
            type: types.SET_FORM_FIELD,
            section,
            key,
            value
        });
    };
}

/**
 *
 * @param {*} index
 * @param {*} dependent
 */
export function editDependentInList(index, dependent) {
    return (dispatch) => {
        dispatch({
            type: types.EDIT_DEPENDENT,
            dependent,
            index
        });

        // dispatch({
        //     type: types.CLEAR_DEPENDENT_FORM
        // });
    };
}

/**
 *
 * @param {*} index
 */
export function removeCouple(index) {
    return (dispatch) => {
        dispatch({
            type: types.REMOVE_COUPLE,
            index
        });
    };
}

/**
 *
 * @param {*} beneficiary
 */
export function addBeneficiaryToList(beneficiary) {
    return (dispatch) => {
        dispatch({
            type: types.ADD_BENEFICIARY,
            beneficiary
        });

        // dispatch({
        //     type: types.CLEAR_BENEFICIARY_FORM
        // });
    };
}

/**
 *
 * @param {*} index
 * @param {*} beneficiary
 */
export function editBeneficiaryInList(index, beneficiary) {
    return (dispatch) => {
        dispatch({
            type: types.EDIT_BENEFICIARY,
            beneficiary,
            index
        });

        // dispatch({
        //     type: types.CLEAR_BENEFICIARY_FORM
        // });
    };
}

/**
 *
 * @param {*} index
 */
export function removeBeneficiaryFromList(index) {
    return (dispatch) => {
        dispatch({
            type: types.REMOVE_BENEFICIARY,
            index
        });
    };
}