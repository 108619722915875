import * as types from './types/notifier';
import * as session from '../services/session';

import { history } from '../App';

/**
 * @param verbiage
 * @param timeout
 * @returns {Function}
 */
export function displayMessage(verbiage, timeout = 3500) {
    return (dispatch) => {
        setTimeout(() => {
            dispatch({
                type: types.HIDE_NOTIFICATION
            });
        }, timeout);

        dispatch({
            type: types.SHOW_NOTIFICATION,
            showing: true,
            notifierType: 'message',
            verbiage
        });
    };
}

/**
 *
 * @param {*} error
 */
export function displayError(error) {
    return (dispatch) => {
        if (error.code === 'INVALID_TOKEN') {
            session.removeToken();

            history.push('/login?type=invalid-token');
            return;
        }

        setTimeout(() => {
            dispatch({
                type: types.HIDE_NOTIFICATION
            });
        }, 3500);

        dispatch({
            type: types.SHOW_NOTIFICATION,
            showing: true,
            notifierType: 'error',
            verbiage: error.verbiage || 'Something went wrong, please try again or contact support.'
        });
    };
}

/**
 *
 */
export function hideNotification() {
    return (dispatch) => dispatch({
        type: types.HIDE_NOTIFICATION
    });
}