import request from './request';

/**
 * @returns {Promise<any>}
 */
function getAll(weddingId) {
    const options = {
        endpoint: `/events?weddingId=${ weddingId }`,
        method: 'GET'
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param eventId
 * @returns {Promise<any>}
 */
function getSingle(eventId) {
    const options = {
        endpoint: `/events/${ eventId }`,
        method: 'GET'
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param eventId
 * @returns {Promise<any>}
 */
function remove(eventId) {
    const options = {
        endpoint: `/events/${ eventId }`,
        method: 'DELETE'
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param eventId
 * @returns {Promise<any>}
 */
function updateEvent(body) {
    console.log('updateEvent', body);

    const options = {
        endpoint: '/updateEvent',
        method: 'POST',
        body
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param body
 * @returns {Promise<any>}
 */
function createEvent(body) {
    const options = {
        endpoint: '/createEvent',
        method: 'POST',
        body
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

export {
    getAll,
    getSingle,
    remove,
    updateEvent,
    createEvent
};