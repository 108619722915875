/**
 *
 * @param {*} token
 * @param {*} index
 */
const concatTokens = (token, index) => {
	if (index === undefined || null) {
		index = 1;
	}

	let a = token.toString() + index.toString();
	a = a.toLowerCase().replace(/[. ,:-]+/g, '-');
	return a;
};

export default concatTokens;
