import InputAdornment from '@material-ui/core/InputAdornment';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changeUserPassword } from '../../actions/user';
import { FormModal, Text, TextInput } from '../../components';
import validateInput from '../../services/FormValidation/validateInput';


class ChangePassword extends Component {
state = {
    existingPassword: '',
    newPassword: '',
    confirmPassword: '',
    passwordError: false,
    confirmPasswordError: false,
    notifyText: false,
    notifyTextColor: '',
    visibility: false
};

_toggleVisibility = () => {
    this.setState({
        visibility: !this.state.visibility
    });
};

_onBlur = (type) => {
    this.setState({
        [`${ type }Error`]: validateInput(type, this.state[type])
    });
};

_handleInputChange = (type, event) => {
    this.setState({
        [type]: event.target.value.trim()
    });
};

_confirm = () => {
    const existingPasswordError = validateInput(
        'password',
        this.state.existingPassword
    );
    const newPasswordError = validateInput(
        'newPassword',
        this.state.newPassword
    );
    const confirmPasswordError = validateInput(
        'confirmPassword',
        this.state.confirmPassword
    );

    this.setState({
        newPasswordError,
        confirmPasswordError
    });

    if (existingPasswordError || newPasswordError || confirmPasswordError) {
        return false;
    }

    if (this.state.newPassword !== this.state.confirmPassword) {
        this.setState({
            notifyText: 'Your passwords do not match',
            notifyTextColor: 'red'
        });

        return false;
    }

    const success = this.props.dispatch(
        changeUserPassword({
            existingPassword: this.state.existingPassword,
            newPassword: this.state.newPassword
        })
    );

    if (success) {
        this.setState({
            notifyText: false
        });

        this.props.close();
    }

    return true;
};

render() {
    return (
        <FormModal
            open={true}
            close={this.props.close}
            // title={'Change Password'}
            disableBackdropClick
            disableEscapeKeyDown
            onSave={this._confirm}
        >
            {this.state.notifyText && (
                <Text
                    align="center"
                    color={this.state.notifyTextColor}
                    small
                    display="inline-block"
                >
                    {this.state.notifyText}
                </Text>
            )}

            <TextInput
                variant="outlined"
                label="Existing Password"
                value={this.state.existingPassword}
                inputProps={{
                    endAdornment: (
                        <InputAdornment>
                            {this.state.visibility ? (
                                <VisibilityIcon
                                    style={{ cursor: 'pointer' }}
                                    onClick={this._toggleVisibility}
                                />
                            ) : (
                                <VisibilityOffIcon
                                    style={{ cursor: 'pointer' }}
                                    onClick={this._toggleVisibility}
                                />
                            )}
                        </InputAdornment>
                    )
                }}
                onBlur={() => this._onBlur('existingPassword')}
                onChange={(value) => this._handleInputChange('existingPassword', value)}
                error={Boolean(this.state.existingPasswordError)}
                helperText={this.state.existingPasswordError}
                secure={!this.state.visibility}
                fullWidth
                autofocus
            />

            <TextInput
                variant="outlined"
                label="New Password"
                value={this.state.newPassword}
                inputProps={{
                    endAdornment: (
                        <InputAdornment>
                            {this.state.visibility ? (
                                <VisibilityIcon
                                    style={{ cursor: 'pointer' }}
                                    onClick={this._toggleVisibility}
                                />
                            ) : (
                                <VisibilityOffIcon
                                    style={{ cursor: 'pointer' }}
                                    onClick={this._toggleVisibility}
                                />
                            )}
                        </InputAdornment>
                    )
                }}
                onBlur={() => this._onBlur('newPassword')}
                onChange={(value) => this._handleInputChange('newPassword', value)
                }
                error={Boolean(this.state.newPasswordError)}
                helperText={this.state.newPasswordError}
                secure={!this.state.visibility}
                fullWidth
            />

            <TextInput
                variant="outlined"
                label="Confirm Password"
                value={this.state.confirmPassword}
                inputProps={{
                    endAdornment: (
                        <InputAdornment>
                            {this.state.visibility ? (
                                <VisibilityIcon
                                    style={{ cursor: 'pointer' }}
                                    onClick={this._toggleVisibility}
                                />
                            ) : (
                                <VisibilityOffIcon
                                    style={{ cursor: 'pointer' }}
                                    onClick={this._toggleVisibility}
                                />
                            )}
                        </InputAdornment>
                    )
                }}
                onBlur={() => this._onBlur('confirmPassword')}
                onChange={(value) => this._handleInputChange('confirmPassword', value)
                }
                error={Boolean(this.state.confirmPasswordError)}
                helperText={this.state.confirmPasswordError}
                secure={!this.state.visibility}
                onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        this._confirm();
                        e.preventDefault();
                    }
                }}
                fullWidth
            />
        </FormModal>
    );
}
}

/**
 *
 * @param {*} state
 */
const mapStateToProps = (state) => ({
    loading: state.user.loading,
    meta: state.user.meta
});

export default connect(mapStateToProps)(ChangePassword);