import * as notifier from './notifier';
import * as user from '../xhr/user';
// import * as api from '../xhr/signup';
import * as session from '../services/session';
import * as types from './types/user';
import { SET_WEDDING } from './types/wedding';
import { getPosts } from './posts';
import { getEvents } from './events';
import { getGuests } from './guests';

import { history } from '../App';

/**
 *
 * @param {*} dispatch
 * @param {*} state
 */
function setLoading(dispatch, state) {
    dispatch({
        type: types.LOADING,
        state
    });
}

/**
 *
 */
export function loadUserData() {
    return (dispatch) => {
        setLoading(dispatch, true);

        return user
            .get()
            .then((data) => {
                session.setToken(data.token);

                // console.log('loadUserData', data);

                dispatch({
                    type: types.GET_USER,
                    meta: data.user
                    // notifications: data.notifications || []
                });

                dispatch({
                    type: SET_WEDDING,
                    weddingId: data.roles[0].weddingId
                });

                dispatch(getPosts({ 
                    weddingId: data.roles[0].weddingId
                }));

                dispatch(getEvents(data.roles[0].weddingId));

                // dispatch(getWedding(data.roles[0].weddingId));

                dispatch(getGuests(data.roles[0].weddingId));

                // if (data.notifications && data.notifications.length > 0) {
                //     dispatch(notifier.displayMessage('You have new notifications!'));
                // }

                // dispatch({
                //     type: usageTypes.GET_USAGE,
                //     usage: data.usage
                // });

                // dispatch({
                //     type: claimsTypes.GET_CLAIMS,
                //     claims: data.claims
                // });

                // dispatch({
                //     type: spendingAccountsTypes.GET_HEALTH_USAGE,
                //     health: data.spending.health
                // });

                // dispatch({
                //     type: spendingAccountsTypes.GET_LIFESTYLE_CLAIMS,
                //     lifestyle: data.spending.lifestyle
                // });

                // dispatch({
                //     type: appointmentsTypes.GET_ALL_APPOINTMENTS,
                //     appointments: data.appointments
                // });
            })
            .then(() => {
                setLoading(dispatch, false);
                return true;
            })
            .catch((error) => {
                setLoading(dispatch, false);
                dispatch(notifier.displayError(error));
            });
    };
}

/**
 * @param email
 * @param password
 */
export function login(email, password) {
    return (dispatch) => {
        setLoading(dispatch, true);

        return user
            .login({
                email,
                password
            })
            .then(async (data) => {
                session.setToken(data.jwt);
                // console.log('login data', data);

                setLoading(dispatch, false);
                history.push('/gallery');

                await dispatch(loadUserData());

                return true;
            })
            .catch((error) => {
                setLoading(dispatch, false);
                dispatch(notifier.displayError(error));
            });
    };
}

/**
 * @param email
 */
export function forgotPassword(email) {
    return (dispatch) => {
        setLoading(dispatch, true);

        return user
            .sendTempPassword(email)
            .then(() => {
                dispatch(notifier.displayMessage('Please check your inbox for a password recovery email.'));
                setLoading(dispatch, false);

                return true;
            })
            .catch((error) => {
                setLoading(dispatch, false);
                dispatch(notifier.displayError(error));
            });
    };
}

/**
 * @param body
 * @param credentials
 */
export function resetPassword(body, email, password) {
    return (dispatch) => {
        setLoading(dispatch, true);

        return user
            .resetPassword(body)
            .then(() => {
                setLoading(dispatch, false);

                dispatch(notifier.displayMessage('Your password has been updated. Welcome Back'));

                dispatch(login(email, password));
                
                return true;
            })
            .catch((error) => {
                setLoading(dispatch, false);
                dispatch(notifier.displayError(error));
            });
    };
}

/**
 *
 * @param {*} data
 */
export function updateUserSettings(data) {
    return (dispatch) => {
        setLoading(dispatch, true);

        return user
            .updateUserSettings(data)
            .then(() => {
                dispatch({
                    type: types.UPDATE_USER_SETTINGS_SUCCESS,
                    data
                });

                setLoading(dispatch, false);
                dispatch(notifier.displayMessage('Successfully updated user settings.'));
                return true;
            })
            .catch((error) => {
                setLoading(dispatch, false);
                dispatch(notifier.displayError(error));
                return false;
            });
    };
}

/**
 *
 * @param {*} data
 */
export function savePaymentDetails(data) {
    return (dispatch) => {
        setLoading(dispatch, true);

        return user
            .savePaymentDetails(data)
            .then(() => {
                dispatch({
                    type: types.PAYMENT_DETAILS_SUCCESS
                });

                setLoading(dispatch, false);
                dispatch(notifier.displayMessage('Successfully saved payment details.'));
                return true;
            })
            .catch((error) => {
                setLoading(dispatch, false);
                dispatch(notifier.displayError(error));
                return false;
            });
    };
}

/**
 *
 * @param {*} password
 */
export function changeUserPassword(password) {
    return (dispatch) => {
        setLoading(dispatch, true);

        return user
            .changePassword(password)
            .then(() => {
                setLoading(dispatch, false);
                dispatch(notifier.displayMessage('Password changed successfully!'));
                return true;
            })
            .catch((error) => {
                setLoading(dispatch, false);
                dispatch(notifier.displayError(error));
                return false;
            });
    };
}

/**
 * Remove the users JWT and refresh the app.
 */
export function logout() {
    return (dispatch) => {
        session.removeToken();
        session.removeWeddingToken();

        history.push('/');
        return true;
    };
}

/**
 * @param id
 * @returns {function(*=): Promise<any | never>}
 */
export function removeNotification(id) {
    return (dispatch) => {
        setLoading(dispatch, true);

        return user
            .removeNotification(id)
            .then(() => {
                dispatch({
                    type: types.REMOVE_NOTIFICATION_SUCCESS,
                    id
                });

                setLoading(dispatch, false);
                return true;
            })
            .catch((error) => {
                setLoading(dispatch, false);
                dispatch(notifier.displayError(error));
            });
    };
}