import initialState from '../initialState';
import {
    LOADING,
    GET_GUESTS,
    ADD_GUEST_SUCCESS,
    UPLOAD_GUESTS_SUCCESS
} from '../../actions/types/guests';

export default (state = initialState.guests, action) => {
    switch (action.type) {
        case LOADING: {
            return {
                ...state,
                loading: action.state
            };
        }

        case GET_GUESTS: {
            return {
                ...state,
                items: action.items
            };
        }

        case ADD_GUEST_SUCCESS: {
            const newGuest = {
                firstName: action.guest.firstName,
                lastName: action.guest.lastName,
                email: action.guest.email,
                role: {
                    role: action.guest.role,
                    weddingId: action.guest.weddingId
                }
            };
             
            return {
                ...state,
                items: [ newGuest, ...state.items ]
            };
        }

        case UPLOAD_GUESTS_SUCCESS: {
            return {
                ...state,
                items: [ action.data, ...state.items ]
            };
        }

        default:
            return state;
    }
};