import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
// import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
// import NotificationsIcon from '@material-ui/icons/Notifications';
import { Text } from '../components';
import { logout } from '../actions/user';
import { colors } from '../theme';

class Sidebar extends Component {
    state = {
        selectedScreen: 'dashboard',
        user: this.props.user || {}
    };

    componentDidMount() {
        let selectedScreen = '';
        const href = window.location.href;

        if (href.includes('my-account')) {
            selectedScreen = 'account';
        } else if (href.includes('upload')) {
            selectedScreen = 'upload';
        } else if (href.includes('guest-list')) {
            selectedScreen = 'guest-list';
        } else if (href.includes('events')) {
            selectedScreen = 'events';
        } else if (href.includes('support')) {
            selectedScreen = 'support';
        } else if (href.includes('notifications')) {
            selectedScreen = 'notifications';
        } else {
            selectedScreen = 'gallery';
        }

        this.setState({
            selectedScreen
        });
    }

    _selectItem = (selectedScreen) => {
        this.setState({
            selectedScreen
        });

        if (this.props.closeSideBar) {
            this.props.closeSideBar();
        }
    };

    _logout = () => {
        this.props.dispatch(logout());
    };

    render() {
        const { classes } = this.props;

        return (
            
            <div className={classes.sidebar}>
                <div className={classes.sidebarHeader}>
                    <Link
                        to="/gallery"
                        onClick={() => this._selectItem('gallery')}
                    >
                        <img
                            alt="Hitched Logo"
                            src={require('../assets/icon-white-drop.png')}
                            className={classes.logoSmall}
                        />
                    </Link>

                    <Link
                        to="/notifications"
                        className={classes.notificationBadge}
                        onClick={() => this._selectItem('notifications')}
                    >
                        {/* <Badge
                            color="secondary"
                            badgeContent={ notifications.length }
                            invisible={ notifications.length === 0 }
                        >
                            {
                                notifications.length === 0 ? <NotificationsNoneIcon /> : <NotificationsIcon />
                            }
                        </Badge> */}
                    </Link>
                </div>

                <div className={classes.sidebarBody}>
                    <List>
                        <Link
                            to="/gallery"
                            style={{ textDecoration: 'none' }}
                            onClick={() => this._selectItem('gallery')}
                        >
                            <ListItem
                                button
                                className={`${ classes.listItem } ${
                                    this.state.selectedScreen === 'gallery'
                                        ? classes.selected
                                        : null
                                }`}
                            >
                                <ListItemText
                                    disableTypography
                                    primary="Gallery"
                                />
                            </ListItem>
                        </Link>

                        <Link
                            to="/guest-list"
                            style={{ textDecoration: 'none', whiteSpace: 'nowrap' }}
                            onClick={() => this._selectItem('guest-list')}
                        >
                            <ListItem
                                button
                                className={`${ classes.listItem } ${
                                    this.state.selectedScreen === 'guest-list'
                                        ? classes.selected
                                        : null
                                }`}
                            >
                                {/* <ListItemIcon>
                                    <PeopleIcon />
                                </ListItemIcon> */}
                                <ListItemText
                                    disableTypography
                                    primary="Guest List"
                                />
                            </ListItem>
                        </Link>

                        {/* <Link
                            to="/upload"
                            style={{ textDecoration: 'none' }}
                            onClick={() => this._selectItem('upload')}
                        >
                            <ListItem
                                button
                                className={`${ classes.listItem } ${
                                    this.state.selectedScreen === 'upload'
                                        ? classes.selected
                                        : null
                                }`}
                            >
                                <ListItemText
                                    disableTypography
                                    primary="Upload"
                                />
                            </ListItem>
                        </Link> */}

                        {/* <Link
                            to="/events"
                            style={{ textDecoration: 'none' }}
                            onClick={() => this._selectItem('events')}
                        >
                            <ListItem
                                button
                                className={`${ classes.listItem } ${
                                    this.state.selectedScreen === 'events'
                                        ? classes.selected
                                        : null
                                }`}
                            >
                                <ListItemText
                                    disableTypography
                                    primary="Events"
                                />
                            </ListItem>
                        </Link> */}

                        <Divider variant="middle" style={{ margin: 20 }} />

                        <Link
                            to="/my-account"
                            style={{ textDecoration: 'none' }}
                            onClick={() => this._selectItem('my-account')}
                        >
                            <ListItem
                                button
                                className={`${ classes.listItem } ${
                                    this.state.selectedScreen === 'account'
                                        ? classes.selected
                                        : null
                                }`}
                            >
                                <ListItemIcon>
                                    <PersonIcon />
                                </ListItemIcon>
                                {/* <ListItemText primary="My Account" /> */}
                            </ListItem>
                        </Link>

                        <Link
                            to="/support"
                            style={{ textDecoration: 'none' }}
                            onClick={() => this._selectItem('support')}
                        >
                            <ListItem
                                button
                                className={`${ classes.listItem } ${
                                    this.state.selectedScreen === 'support'
                                        ? classes.selected
                                        : null
                                }`}
                            >
                                <ListItemIcon>
                                    <ContactSupportIcon />
                                </ListItemIcon>
                                {/* <ListItemText primary="Support" /> */}
                            </ListItem>
                        </Link>

                        <ListItem
                            button
                            className={classes.listItem}
                            onClick={this._logout}
                        >
                            <ListItemIcon>
                                <ExitToAppIcon
                                    // color="error"
                                />
                            </ListItemIcon>
                            {/* <ListItemText primary="Logout" /> */}
                        </ListItem>
                    </List>

                    <div className={classes.spacer}>
                        <Text
                            tiny
                            color={colors.mediumGrey}
                        >
                            Copyright © The Moseley Bros {new Date().getFullYear()}{'.'}
                        </Text>
                    </div>
                </div>
            </div>
        );
    }
}

/**
 *
 * @param {*} theme
 */
const styles = (theme) => ({
    sidebar: {
        backgroundColor: colors.white,
        display: 'flex',
        // flexDirection: 'column',
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    gravatar: {
        borderRadius: '50%',
        marginRight: 5,
        marginBottom: -10
    },
    sidebarHeader: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        // backgroundColor: colors.gradientBlue,
        background: 'linear-gradient(140deg, rgba(52,153,177,1) 0%, rgba(64,224,208,0.3435749299719888) 100%)',
        flex: 0.2,
        minHeight: 200
    },
    sidebarBody: {
        backgroundColor: colors.backgroundGrey,
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        padding: '10px 30px'
    },
    listItem: {
        margin: '5px 0px',
        cursor: 'pointer',
        color: colors.black
    },
    logoSmall: {
        width: 100
    },
    selected: {
        backgroundColor: colors.lightCyan,
        color: colors.gradientBlue
    },
    notificationBadge: {
        marginTop: 10,
        textDecoration: 'none',
        color: colors.darkGrey
    },
    spacer: {
        marginTop: 'auto'
    }
});

/**
 *
 * @param {*} state
 */
const mapStateToProps = (state) => ({
    user: state.user.meta
});

export default withStyles(styles)(connect(mapStateToProps)(Sidebar));