import React from 'react';
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";

/**
 * 
 * @param {*} param0 
 */
const SinglePhotoGrid = ({ photo, onPressImage, classes }) => (
    <img
        alt="Click to remove"
        // src={"https://freejquery.com/wp-content/uploads/2019/07/spiner.gif"}
        src={photo && photo.url}
        className={classes.image}
        onClick={() => onPressImage()}
    />
);

/**
 * 
 * @param {*} theme 
 */
const styles = (theme) => ({
    image: {
        alignSelf: 'center',
        backgroundColor: 'transparent',
        height: '100%',
        width: '100%',
        objectFit: 'cover',
        marginTop: 10,
        cursor: 'pointer'
    }
});

export default withStyles(styles)(connect()(SinglePhotoGrid));