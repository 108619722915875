import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';

import { globalStyles, colors } from '../../theme';
import Button from '../Button';
import Text from '../Text';
// import * as notifier from '../actions/notifier';

class DropZoneModal extends Component {
    state = {
        showContactModal: false,
        files: [],
        fileName: '',
        showDropzone: true
    };

    _chooseDocument = async (files) => {
        if (!files[0].name) {
            alert('Please use a .csv file with the columns : firstName, lastName, email, role');
            return false;
        }

        if (files[1] !== null) {
            files = new Array(files[0]);
        }

        console.log(files);

        const duplicate =
            this.state.files.length > 0 &&
            this.state.files[0].name === files[0].name;

        if (!duplicate) {
            this.setState({
                fileName: files[0].name,
                files: [ ...this.state.files, ...files ]
            });
        } else {
            alert('File already selected, please select a different file.');
        }

        await this.setState({
            showDropzone: this.state.files.length < this.props.maxItems
        });

        return true;
    };

    _fileType = (file) => {
        let fileType = null;

        switch (file.type) {
            case 'application/xlsx':
                fileType = 'xls';
                break;
            case 'application/vnd.ms-excel':
                fileType = 'xls';
                break;
            case 'text/csv':
                fileType = 'csv';
                break;
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                fileType = 'doc';
                break;
            case 'image/jpeg':
                fileType = 'img';
                break;
            case 'image/jpg':
                fileType = 'img';
                break;
            case 'image/png':
                fileType = 'img';
                break;
            default:
                fileType = 'pdf';
                break;
        }

        return fileType;
    };

    _fileName = (file) => {
        let filename = null;

        file.name.length > 25
            ? (filename = `${ file.name.substr(0, 11) }...${ file.name.substr(
                -11
            ) }`)
            : (filename = file.name);

        return filename;
    };

    _removeDocument = async (name) => {
        const fileArray = this.state.files.filter((file) => file.name !== name);

        await this.setState({
            files: fileArray,
            showDropzone: true
        });
    };

    _submitDocument = async () => {
        if (this.state.files.length > 0) {
            const submit = await this.props.submitFile(
                this.state.files,
                this.props.type
            );
            this.props.hideModal();
            return submit;
        }

        return null;
    };

    render() {
        const { classes, showModal, hideModal, title } = this.props;
        const { showDropzone } = this.state;

        return (
            <Modal
                open={showModal}
                onClose={hideModal}
                style={{ overflow: 'auto', WebkitOverflowScrolling: 'touch' }}
            >
                <div className={`${ classes.content } ${ classes.modal }`}>
                    <div className={classes.modalBody}>
                        <div className={classes.titleIconWrapper}>
                            <CloseIcon
                                className={classes.closeModalIcon}
                                onClick={hideModal}
                            />

                            <Text
                                align="center"
                                bold
                                mediumLarge
                                display="inline-block"
                                margin="0"
                            >
                                {title}
                            </Text>
                        </div>

                        {this.state.files.length > 0 && (
                            <React.Fragment>
                                <div className={classes.previewWrapper}>
                                    {this.state.files.map((file) => {
                                        const fileType = this._fileType(file);
                                        const fileName = this._fileName(file);
                                        return (
                                            <div
                                                key={file.name}
                                                className={classes.previewStyle}
                                            >
                                                <CloseIcon
                                                    className={
                                                        classes.closeModalIcon
                                                    }
                                                    onClick={() => this._removeDocument(
                                                        file.name
                                                    )
                                                    }
                                                />

                                                <img
                                                    alt="File type icon"
                                                    src={require(`../../../src/assets/${ fileType }.png`)}
                                                    className={classes.logo}
                                                />

                                                <Text
                                                    align="center"
                                                    bold
                                                    mediumLarge
                                                    display="block"
                                                    margin="0"
                                                    customStyle={{
                                                        maxWidth: '300px'
                                                    }}
                                                >
                                                    {fileName}
                                                </Text>
                                            </div>
                                        );
                                    })}
                                </div>
                            </React.Fragment>
                        )}

                        {this.state.showDropzone && (
                            <Dropzone
                                onDrop={(files) => this._chooseDocument(files)}
                                accept="image/png, image/jpeg, image/jpg, application/pdf, application/vnd.ms-excel,
                                    text/csv, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <section>
                                        <div
                                            {...getRootProps()}
                                            className={
                                                !showDropzone
                                                    ? classes.cardDisabled
                                                    : classes.cardStyle
                                            }
                                        >
                                            <input {...getInputProps()} />
                                            <p className={classes.cardText}>
                                                Drag and drop your files here,
                                                or click to select files
                                            </p>
                                        </div>
                                    </section>
                                )}
                            </Dropzone>
                        )}

                        <Button
                            disabled={this.state.files.length === 0}
                            onClick={this._submitDocument}
                            color="primary"
                            customStyle={`${ classes.btnWidth }`}
                            marginTop={20}
                        >
                            SUBMIT
                        </Button>
                    </div>
                </div>
            </Modal>
        );
    }
}

/**
 * @param theme
 */
const styles = (theme) => ({
    container: globalStyles.container,
    content: globalStyles.content,
    btnWidth: {
        width: 'fit-content',
        marginLeft: 'auto'
    },
    modal: {
        top: '50%',
        left: '50%',
        margin: '0',
        maxWidth: '40%',
        minWidth: '648px',
        outline: 'none',
        position: 'absolute',
        boxShadow: '0 1px 2px rgba(0, 0, 0, .15)',
        borderRadius: '12px',
        transform: 'translate(-50%, -50%)',
        WebkitTransform: 'translate(-50%, -50%)',
        [theme.breakpoints.down('666')]: {
            maxWidth: '75%',
            minWidth: 'unset'
        }
    },
    modalBody: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        borderRadius: '12px',
        padding: '20px',
        backgroundColor: colors.white
    },
    closeModalIcon: {
        cursor: 'pointer',
        display: 'inline',
        marginTop: '5px',
        fontSize: '22px',
        float: 'right',
        color: colors.mediumDarkGrey
    },
    titleIconWrapper: {
        display: 'block',
        width: '100%',
        paddingBottom: '15px',
        verticalAlign: 'center'
    },
    cardStyle: {
        backgroundColor: colors.lightBlue,
        boxShadow: 'none',
        padding: '20px',
        border: `2px dotted ${ colors.mediumBlue }`,
        borderRadius: '8px',
        textAlign: 'center',
        color: colors.mediumBlue
    },
    cardDisabled: {
        backgroundColor: colors.lightGrey,
        boxShadow: 'none',
        padding: '20px',
        border: `2px dotted ${ colors.mediumLightGrey }`,
        borderRadius: '8px',
        color: colors.mediumLightGrey
    },
    cardText: {
        textAlign: 'center'
    },
    previewStyle: {
        minHeight: '315px',
        minWidth: '285px',
        backgroundColor: colors.surface,
        borderRadius: '8px',
        padding: '10px',
        marginLeft: '5px',
        margin: '5px',
        wordBreak: 'break-all',
        [theme.breakpoints.down('666')]: {
            minWidth: 'fit-content'
        }
    },
    previewWrapper: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        alignItems: 'center',
        marginBottom: '10px',
        backgroundColor: colors.lightGrey,
        boxShadow: 'none',
        padding: '5px',
        borderRadius: '8px'
    },
    fileTitle: {
        maxWidth: '300px',
        wordBreak: 'break-all'
    },
    logo: globalStyles.logo
});

export default withStyles(styles)(DropZoneModal);