import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Loader, Tiles } from '../../components';
import { colors, globalStyles } from '../../theme';
import ChangePassword from './ChangePassword';
import Payments from './Payments';
import Settings from './Settings';


class Account extends Component {
    state = {
        settingsDialogOpen: false,
        changePasswordDialogOpen: false,
        paymentsDialogOpen: false
    };

    _toggleDialog = (type) => {
        this.setState({
            [`${ type }DialogOpen`]: !this.state[`${ type }DialogOpen`]
        });
    };

    render() {
        const { classes } = this.props;
        const tiles = [
            {
                title: 'Info',
                description:
                    'Your account information.',
                icon: {
                    name: 'person',
                    color: colors.skyBlue
                },
                onClick: () => this._toggleDialog('settings')
            },
            {
                title: 'Change Password',
                description: 'Change your account password.',
                icon: {
                    name: 'security',
                    color: colors.purple
                },
                onClick: () => this._toggleDialog('changePassword')
            }
            // {
            //     title: 'Billing Details',
            //     description: 'View and manage your payment options.',
            //     icon: {
            //         name: 'attachMoney',
            //         color: colors.brandGreen
            //     },
            //     onClick: () => this._toggleDialog('payments')
            // }
        ];

        return (
            <div className={classes.container}>
                <div className={classes.content}>
                    {/* <Title title="Your Account" /> */}
                    <Tiles tiles={tiles} />
                </div>

                {this.state.settingsDialogOpen && (
                    <Settings close={() => this._toggleDialog('settings')} />
                )}

                {this.state.changePasswordDialogOpen && (
                    <ChangePassword
                        close={() => this._toggleDialog('changePassword')}
                    />
                )}

                {this.state.paymentsDialogOpen && (
                    <Payments close={() => this._toggleDialog('payments')} />
                )}

                {this.props.loading && <Loader />}

                <span id="my-account"></span>
            </div>
        );
    }
}

/**
 *
 * @param {*} theme
 */
const styles = (theme) => ({
    container: globalStyles.container,
    content: globalStyles.contentSidebarSite
});

Account.propTypes = {
    loading: PropTypes.bool
};

/**
 *
 * @param {*} state
 */
const mapStateToProps = (state) => ({
    loading: state.user.loading
});

export default withStyles(styles)(connect(mapStateToProps)(Account));