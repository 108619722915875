import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Loader } from '../../components';
import NoticeModal from '../../components/Modals/NoticeModal';
import { globalStyles } from '../../theme';
import UploadModal from './UploadModal';


class Upload extends Component {
    state = { 
        modalOpen: false,
        currentEvent: {},
        noticeOpen: false,
        message: '',
        filter: ''
    }

    _handleChange = (panel) => (event, expanded) => {
        this.setState({
            expanded: this.state.expanded.push(panel)
        });
    };

    _handleFilter = (value) => {
        this.setState({ filter: value });
    };

    _toggleModal = (event) => {
        if (event === 'new') {
            event = {
                weddingId: this.props.wedding.weddingId,
                creatorId: this.props.user.id,
                photoId: 'e48c94ab-dcfc-46da-8fd8-a7a34f2d91dc',
                name: '',
                startTime: '2020-12-12T08:00:00.000Z',
                endTime: '2020-12-12T16:00:00.000Z',
                description: 'this initial state data is in events/index',
                blurb: 'Test Blurb',
                location: 'Test Location',
                notes: 'Test notes'
            };
        }

        this.setState({
            modalOpen: !this.state.modalOpen,
            currentEvent: event
        });
    }

    _toggleNotice = (event) => {
        this.setState({
            noticeOpen: !this.state.noticeOpen,
            message: `You are not the creator of this event (${ event.name }), please ask the administrator for permission to edit`
        });
    }

    render() {
        const { classes } = this.props;
        const { currentEvent, message } = this.state;

        // const filterString = this.state.filter.toLowerCase();

        return (
            <div className={classes.container}>
                <div className={classes.titleButton}>
                    <Button
                        onClick={() => this._toggleModal('new')}
                        color="primary"
                    >
                        Upload
                    </Button>
                </div>

                {/* <SearchBar
                    setState={this._handleFilter}
                    placeholder="Search..."
                    customStyle={{
                        margin: '20px auto',
                        width: '100%'
                    }}
                /> */}


                {
                    this.state.modalOpen && 
                        <UploadModal event={currentEvent} close={this._toggleModal} />
                }

                {
                    this.state.noticeOpen && 
                        <NoticeModal title="No edit permission" message={message} close={this._toggleNotice} />
                }

                {this.props.loading && <Loader />}
            </div>
        );
    }
}

/**
 *
 * @param {*} theme
 */
const styles = (theme) => ({
    container: globalStyles.container,
    content: globalStyles.content,
    titleContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'flex-start'
    },
    titleButton: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        flexDirection: 'row'
    }
});

Upload.propTypes = {
    loading: PropTypes.bool
};

/**
 *
 * @param {*} state
 */
const mapStateToProps = (state) => ({
    loading: state.events.loading,
    events: state.events.items,
    role: state.user.role,
    user: state.user.meta,
    wedding: state.wedding
});

export default withStyles(styles)(connect(mapStateToProps)(Upload));