import React from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

/**
 *
 * @param {*} props
 */
const TextInput = ({
    id,
    select,
    autoFocus,
    autoComplete,
    error,
    type,
    placeholder,
    label,
    value,
    defaultValue,
    margin,
    onChange,
    onBlur,
    fullWidth,
    helperText,
    secure,
    inputProps,
    required,
    variant,
    disabled,
    multiline,
    rowsMax,
    rows,
    onKeyPress,
    selectProps,
    selectOptions,
    customInputStyle
}) => {
    // If the data passed is an array, assign key value pairs. Otherwise use the object
    const wasArray = { ...selectOptions };
    const newObject = Object.keys(wasArray).map((key) => ({
        key: wasArray[key],
        value: wasArray[key]
    }));
    let data =
		selectOptions && selectOptions.some((option) => option.key) ? selectOptions : newObject;

    return (
        <TextField
            multiline={multiline}
            rowsMax={rowsMax}
            rows={rows}
            id={id}
            select={select}
            autoFocus={autoFocus}
            autoComplete={autoComplete}
            error={Boolean(error)}
            placeholder={placeholder}
            label={label}
            value={value}
            defaultValue={defaultValue}
            margin={margin || 'normal'} // none, dense, or normal
            onChange={onChange}
            onBlur={onBlur}
            fullWidth={fullWidth}
            helperText={helperText}
            type={secure ? 'password' : type}
            InputProps={
                inputProps
                    ? inputProps
                    : {
                        classes: {
                            input: customInputStyle || null
                        }
                    }
            }
            required={required}
            variant={variant || 'standard'}
            disabled={disabled}
            onKeyPress={onKeyPress}
            SelectProps={selectProps}
        >
            {select &&
                data.map((option) => (
                    <MenuItem key={option.key} value={option.value}>
                        {option.value}
                    </MenuItem>
                ))}
        </TextField>
    );
};

export default TextInput;