import React, { Component } from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Avatar from 'react-avatar';
import Grid from '@material-ui/core/Grid/Grid';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu/Menu';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import { withStyles } from '@material-ui/core/styles';

import { globalStyles, colors } from '../../theme';
import Text from '../Text';

class SingleLineDisplayCard extends Component {
    state = {
        anchorEl: null
    };

    _handleMenuClick = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    _handleCloseMenu = () => {
        this.setState({ anchorEl: null });
    };

    _editSegment = () => {
        this._handleCloseMenu();
        this.props.onSelect();
    };

    render() {
        const { classes, popUpText } = this.props;
        return (
            <Grid
                container
                alignItems="center"
                className={`${ classes.cardBlock } ${ classes.singleLineCard } ${ classes.sansShadow } ${ classes.itemBorder }`}
            >
                <Grid item xs={2} sm={2} md={1}>
                    <Avatar
                        name={this.props.segment.title}
                        size="40"
                        textSizeRatio={2.2}
                        round={false}
                    />
                </Grid>
                <Grid item xs={8} sm={9} md={10}>
                    <Grid container direction="column" alignItems="flex-start">
                        <p className={classes.cardTitle}>
                            {this.props.segment.title}
                        </p>
                    </Grid>
                </Grid>

                <Grid item xs={2} sm={1} md={1} style={{ textAlign: 'center' }}>
                    <IconButton
                        aria-label="Edit"
                        onClick={this._handleMenuClick}
                    >
                        <MoreVertIcon />
                    </IconButton>

                    <Menu
                        anchorEl={this.state.anchorEl}
                        open={Boolean(this.state.anchorEl)}
                        onClose={this._handleCloseMenu}
                        disableAutoFocusItem={true}
                    >
                        <MenuItem onClick={this._editSegment}>
                            <Text
                                align="center"
                                color="inherit"
                                medium
                                display="inline"
                            >
                                {popUpText}
                            </Text>
                        </MenuItem>
                    </Menu>
                </Grid>
            </Grid>
        );
    }
}

/**
 * @param theme
 */
const styles = (theme) => ({
    cardBlock: globalStyles.cardBlock,
    sansShadow: {
        boxShadow: 'none'
    },
    itemBorder: {
        borderBottom: `1px solid ${ colors.borderGrey }`
    },
    cardTitle: globalStyles.cardTitle,
    singleLineCard: globalStyles.singleLineCard
});

export default withStyles(styles)(SingleLineDisplayCard);