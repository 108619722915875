import * as notifier from './notifier';
import * as events from '../xhr/events';
import * as types from './types/events';


/**
 * @param dispatch
 * @param state
 */
function setLoading(dispatch, state) {
    dispatch({
        type: types.LOADING,
        state
    });
}

/**
 * @returns {function(*=): Promise<any | never>}
 */
export function getEvents(weddingId) {
    return (dispatch) => {
        setLoading(dispatch, true);

        return events
            .getAll(weddingId)
            .then((data) => {
                dispatch({
                    type: types.GET_ALL_EVENTS,
                    items: data.events
                });

                setLoading(dispatch, false);
            })
            .catch((error) => {
                setLoading(dispatch, false);
                dispatch(notifier.displayError(error.verbiage));
            });
    };
}

/**
 * @param eventId
 * @returns {function(*=): Promise<any | never>}
 */
export function getSingle(eventId) {
    return (dispatch) => {
        setLoading(dispatch, true);

        return events.getSingle(eventId)
            .then((data) => {
                dispatch({
                    type: types.GET_EVENT_SUCCESS,
                    data
                });

                setLoading(dispatch, false);
            })
            .catch((error) => {
                setLoading(dispatch, false);
                dispatch(notifier.displayError(error.verbiage));
            });
    };
}

/**
 * @param eventId
 * @returns {function(*=): Promise<any | never>}
 */
export function remove(eventId) {
    return (dispatch) => {
        setLoading(dispatch, true);

        return events.remove(eventId)
            .then(() => {
                dispatch({
                    type: types.REMOVE_EVENT_SUCCESS,
                    eventId
                });

                setLoading(dispatch, false);
                console.log('remove_event');
            })
            .catch((error) => {
                setLoading(dispatch, false);
                dispatch(notifier.displayError(error.verbiage));
            });
    };
}

/**
 * @param body
 * @returns {function(*=): Promise<any | never>}
 */
export function updateEvent(body) {
    return (dispatch) => {
        setLoading(dispatch, true);

        return events
            .updateEvent(body)
            .then((data) => {
                dispatch({
                    type: types.UPDATE_EVENT_SUCCESS,
                    item: data.event
                });

                setLoading(dispatch, false);
                console.log('update_event');
                return true;
            })
            .catch((error) => {
                setLoading(dispatch, false);
                dispatch(notifier.displayError(error.verbiage));
            });
    };
}

/**
 * @param body
 * @returns {function(*=): Promise<any | never>}
 */
export function createEvent(body) {
    return (dispatch) => {
        setLoading(dispatch, true);

        return events
            .createEvent(body)
            .then((data) => {

                dispatch({
                    type: types.CREATE_EVENT_SUCCESS,
                    item: data.event
                });

                setLoading(dispatch, false);
                console.log('create_event');
                return true;
            })
            .catch((error) => {
                setLoading(dispatch, false);
                dispatch(notifier.displayError(error.verbiage));
            });
    };
}

/**
 * @param eventId
 * @param data
 * @returns {function(*=): Promise<any | never>}
 */
export function setCurrentEvent(eventId) {
    return (dispatch) => {
        setLoading(dispatch, true);

        dispatch({
            type: types.SET_CURRENT_EVENT,
            eventId
        })
            .then(() => {
                setLoading(dispatch, false);
                console.log('set_current_event');
            })
            .catch((error) => {
                setLoading(dispatch, false);
                dispatch(notifier.displayError(error.verbiage));
            });
    };
}