// import Carousel from 'react-material-ui-carousel';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import ListSubheader from '@material-ui/core/ListSubheader';
import { withStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getPosts } from '../../actions/posts';
import { PostModal, PostCard, Text } from '../../components';
import { globalStyles, colors } from '../../theme';
import { generateUserName } from '../../services/helpers';


class Gallery extends Component {
    state = {
        autoPlay: true,
        timer: 500,
        animation: "fade",
        indicators: true,
        singleOpen: false,
        lightBox: false,
        photoIndex: 0,
        currentPost: {}
    }

    componentDidMount() {
        this.props.dispatch(getPosts({ weddingId: this.props.wedding.weddingId }));
    }

    _toggleSingle = (post) => {
        if (post === null) {
            post = {};
        }

        this.setState({ 
            singleOpen: !this.state.singleOpen,
            currentPost: post,
            lightBox: false
        });
    }

    _toggleLightBox = (post) => {
        this.setState({ 
            singleOpen: false,
            currentPost: null,
            lightBox: !this.state.lightBox
        });
    }

    render() {
        const { classes, posts, events, guests } = this.props;
        const { currentPost, lightBox, photoIndex } = this.state;

        const images = posts && posts.map((post) => (
            post.photo.url
        ));

        return (
            <div className={classes.container}>
                <div className={classes.content}>
                    {/* <img src="http://ak1.ostkcdn.com/wp-content/uploads/2017/04/fb-50.jpg" alt="Random Posts" className={classes.eventPhoto} /> */}
                    <GridList cellHeight={180} className={classes.gridList}>
                        <GridListTile key="Topheader" cols={2} style={{ height: 'auto' }}>
                            <ListSubheader component="div">
                                <Text 
                                    tiny
                                    customStyle={{ cursor: 'pointer' }}
                                    onClick={this._toggleLightBox}
                                >
                                    View in Light Box
                                </Text>
                            </ListSubheader>
                        </GridListTile>
                        { posts.map((post) => {
                            if (!post.eventId) {
                                return (
                                    <GridListTile key={post.id}>
                                        <img src={post.photo && post.photo.url} alt={post.content} />
                                        <GridListTileBar
                                            title={post.content}
                                            subtitle={<span>by: {generateUserName(guests, post.userId)}</span>}
                                            actionIcon={
                                                <IconButton 
                                                    aria-label={`info about ${ post.content }`} 
                                                    className={classes.icon}
                                                    onClick={() => this._toggleSingle(post)}
                                                >
                                                    <InfoIcon />
                                                </IconButton>
                                            }
                                        />
                                    </GridListTile>
                                );
                            }

                            return null;
                        })}           
                        <GridListTile key="Subheader" cols={2} style={{ height: 'auto' }}>
                            <ListSubheader component="div">
                                <Text 
                                    tiny
                                    customStyle={{ cursor: 'pointer' }}
                                    onClick={this._toggleLightBox}
                                >
                                    View in Light Box
                                </Text>
                            </ListSubheader>
                        </GridListTile>
                    </GridList>

                    {events && events.map((event) => {
                        const filter = posts.filter((post) => post.eventId === event.id);

                        // console.log(event);

                        return (
                            <div key={event.name} className={classes.root}>
                                <img src={event.photo && event.photo.url} alt={event.content} className={classes.eventPhoto} />
                                <GridList cellHeight={180} className={classes.gridList}>
                                    <GridListTile key="Subheader" cols={2} style={{ height: 'auto' }}>
                                        <ListSubheader component="div">
                                            <Text large>{event.name}</Text>
                                        </ListSubheader>
                                    </GridListTile>

                                    {filter.map((post) => (
                                        <GridListTile key={post.id}>
                                            <img src={post.photo && post.photo.url} alt={post.content} />
                                            <GridListTileBar
                                                title={post.content}
                                                subtitle={<span>by: {generateUserName(guests, post.userId)}</span>}
                                                actionIcon={
                                                    <IconButton 
                                                        aria-label={`info about ${ post.content }`} 
                                                        className={classes.icon}
                                                        onClick={() => this._toggleSingle(post)}
                                                    >
                                                        <InfoIcon />
                                                    </IconButton>
                                                }
                                            />
                                        </GridListTile>
                                    ))}
                                </GridList>
                            </div>
                        );
                    })}

                    <div className={classes.spacer}>
                        <Text
                            tiny
                        >
                            Copyright © The Moseley Bros {new Date().getFullYear()}{'.'}
                        </Text>
                    </div>
                </div>

                {
                    this.state.singleOpen && 
                        <PostModal
                            open={true}
                            close={() => this._toggleSingle(null)}
                            // title={currentPost.content}
                            maxWidth="xl"

                        >
                            <PostCard
                                close={() => this._toggleSingle(null)}
                                key={currentPost.id}
                                image={currentPost.photo.url}
                                content={currentPost.content}
                                createdAt={currentPost.createdAt}
                                postId={currentPost.id}
                                likes={currentPost.likes}
                                comments={currentPost.comments}
                                userId={currentPost.userId}
                            />
                        </PostModal>
                }

                {
                    lightBox && (
                        <Lightbox
                            mainSrc={images[photoIndex]}
                            nextSrc={images[(photoIndex + 1) % images.length]}
                            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                            onCloseRequest={() => this.setState({ lightBox: false })}
                            onMovePrevRequest={() => this.setState({
                                photoIndex: (photoIndex + images.length - 1) % images.length
                            })
                            }
                            onMoveNextRequest={() => this.setState({
                                photoIndex: (photoIndex + 1) % images.length
                            })
                            }
                        />
                    )
                }

            </div>
        );
    }
}

/**
 *
 * @param {*} theme
 */
const styles = (theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        marginTop: 50,
        borderRadius: 8
    },
    gridList: {
        width: '100%',
        // height: 660,
        paddingBottom: 50,
        backgroundColor: colors.backgroundGrey,
        padding: 10
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)'
    },
    eventPhoto: {
        left: 'unset',
        height: 'unset',
        position: 'unset',
        transform: 'unset',
        objectFit: 'cover',
        width: '100%',
        maxHeight: 300
    },
    container: globalStyles.container,
    '@media (max-width: 459px)': {
        container: {
            padding: 15
        }
    },
    content: {
        // margin: '20px auto',
        maxWidth: 800
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    spacer: {
        height: 100,
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end'
    }
});

/**
 *
 * @param {*} state
 */
const mapStateToProps = (state) => ({
    user: state.user.meta,
    wedding: state.wedding,
    posts: state.posts.items,
    guests: state.guests.items,
    loading: state.posts.loading,
    events: state.events.items
});

export default withStyles(styles)(connect(mapStateToProps)(Gallery));