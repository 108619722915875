import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Hidden from '@material-ui/core/Hidden';
import Text from '../../components/Text';
import { colors } from '../../theme';


const useStyles = makeStyles({
    card: {
        display: 'flex',
        background: colors.gradientBlue
    },
    wideCard: {
        display: 'flex'
    },
    cardDetails: {
        flex: 1,
        minHeight: 300
    },
    cardMedia: {
        width: 160
    }
});

export default function FeaturedPost(props) {
    const classes = useStyles();
    const { post, index } = props;

    return (
        <Grid item xs={12} md={index === 2 ? 12 : 6}>
            <Card className={index === 2 ? classes.wideCard : classes.card}>
                <div className={classes.cardDetails}>
                    <CardContent>
                        <Text
                            align="left"
                            mediumLarge
                            bold
                            margin="0px 0px 10px 0px"
                            display="block"
                            color={index === 2 ? null : 'white'}
                            // customStyle={{ color: colors.white, fontSize: 60, margin: '0' }}
                        >
                            {post.title}
                        </Text>
                        <Text
                            align="left"
                            medium
                            margin="0px 0px 10px 0px"
                            display="block"
                            color={index === 2 ? null : 'white'}
                            // customStyle={{ color: colors.white, fontSize: 60, margin: '0' }}
                        >
                            {post.description}
                        </Text>
                    </CardContent>
                </div>
                {
                    index !== 2 &&
                        <Hidden xsDown>
                            <CardMedia className={classes.cardMedia} image={post.image} title={post.imageTitle} />
                        </Hidden>
                }
            </Card>
        </Grid>
    );
}