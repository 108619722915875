import React from 'react';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';

/**
 *
 * @param {*} props
 */
function PhoneFormatCustom(props) {
	const { inputRef, onChange, ...other } = props;

	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={values => {
				onChange({
					target: {
						value: values.value
					}
				});
			}}
			format="(###) ###-####"
			mask="_"
		/>
	);
}

/**
 *
 * @param {*} props
 */
const PhoneInput = props => (
	<TextField
		id={props.id}
		select={props.select}
		error={props.error}
		label={props.label}
		value={props.value}
		margin="normal"
		onChange={props.onChange}
		onBlur={props.onBlur}
		fullWidth={props.fullWidth}
		helperText={props.helperText || props.error}
		InputProps={{
			inputComponent: PhoneFormatCustom
		}}
		required={props.required}
		variant={props.variant || 'standard'}
		disabled={props.disabled}
		onKeyPress={props.onKeyPress}
	/>
);

export default PhoneInput;
