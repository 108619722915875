import validate from 'validate.js';
import validations from './validations';
// import * as Dates from '../Dates';

/**
 * 
 * @param {*} fieldName 
 * @param {*} value 
 */
const validateInput = (fieldName, value) => {
    // console.log(fieldName, value);

    // if (fieldName.toLowerCase().includes('date')) {
    //     return Dates.validateDate(value);
    // }

    const formValues = {};
    formValues[fieldName] = value.toString();
    
    const formFields = {};
    formFields[fieldName] = validations[fieldName];

    const result = validate(formValues, formFields);
    
    if (result) {
        return result[fieldName][0];
    }
    
    return null;
};

export default validateInput;