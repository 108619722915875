const type = {
    base: '"Montserrat", sans-serif',
    medium: '"Montserrat", sans-serif',
    bold: '"Montserrat", sans-serif',
    heading: '"Montserrat", sans-serif',
    light: '"Montserrat", sans-serif'
};

const size = {
    h1: '48px',
    titleSpare: '36px',
    iconSpare: '32px',
    h2: '24px',
    h3: '21px',
    sh1: '18px',
    sh2: '16px',
    body: '16px',
    medium: '14px',
    caption: '13px',
    small: '12px',
    label: '14px',
    button: '20px',
    overline: '12px',
    tag: '11px'
};

const style = {
    h1: {
        fontFamily: type.heading,
        fontSize: size.h1
    },
    h2: {
        fontFamily: type.heading,
        fontSize: size.h2
    },
    h3: {
        fontFamily: type.bold,
        fontSize: size.h3
    },
    sh1: {
        fontFamily: type.heading,
        fontSize: size.sh1
    },
    sh2: {
        fontFamily: type.bold,
        fontSize: size.sh2
    },
    b1: {
        fontFamily: type.base,
        fontSize: size.body
    },
    b2: {
        fontFamily: type.base,
        fontSize: size.medium
    },
    b3: {
        fontFamily: type.base,
        fontSize: size.caption
    },
    sm: {
        fontFamily: type.base,
        fontSize: size.small
    },
    l1: {
        fontFamily: type.bold,
        fontSize: size.label
    },
    bl: {
        fontFamily: type.heading,
        fontSize: size.button
    },
    l2: {
        fontFamily: type.bold,
        fontSize: size.overline
    },
    l3: {
        fontFamily: type.bold,
        fontSize: size.tag
    }
};

export default {
    type,
    size,
    style
};