export const GET_USER = 'user/GET_USER';
export const LOADING = 'user/LOADING';
export const UPDATE_USER_SETTINGS_SUCCESS = 'user/UPDATE_USER_SETTINGS_SUCCESS';
export const PAYMENT_DETAILS_SUCCESS = 'user/PAYMENT_DETAILS_SUCCESS';

export const ADD_COUPLE_SUCCESS = 'user/ADD_COUPLE_SUCCESS';
export const REMOVE_COUPLE_SUCCESS = 'user/REMOVE_COUPLE_SUCCESS';

export const ADD_BENEFICIARY_SUCCESS = 'user/ADD_BENEFICIARY_SUCCESS';
export const UPDATE_BENEFICIARY_SUCCESS = 'user/UPDATE_BENEFICIARY_SUCCESS';
export const REMOVE_BENEFICIARY_SUCCESS = 'user/REMOVE_BENEFICIARY_SUCCESS';
export const UPDATE_CURRENT_BENEFICIARIES = 'user/UPDATE_CURRENT_BENEFICIARIES';

export const ADD_TRUSTEE_SUCCESS = 'user/ADD_TRUSTEE_SUCCESS';
export const UPDATE_TRUSTEE_SUCCESS = 'user/UPDATE_TRUSTEE_SUCCESS';
export const REMOVE_TRUSTEE_SUCCESS = 'user/REMOVE_TRUSTEE_SUCCESS';

export const REMOVE_NOTIFICATION_SUCCESS = 'user/REMOVE_NOTIFICATION_SUCCESS';