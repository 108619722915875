import initialState from '../initialState';
import {
    LOADING,
    GET_USAGE,
    ADD_NEW_USAGE
} from '../../actions/types/usage';

export default (state = initialState.usage, action) => {
    switch (action.type) {
        case LOADING: {
            return {
                ...state,
                loading: action.state
            };
        }

        case GET_USAGE: {
            return {
                ...state,
                items: action.usage
            };
        }

        case ADD_NEW_USAGE: {
            const items = state.items;
            const category = action.data.category.toLowerCase();

            if (action.data.dependentID) {
                items.dependents.map((item) => {
                    if (item.dependentID === action.data.dependentID) {
                        const usage = item.usage;

                        if (usage[category]) {
                            usage[category].amountUsed = Number(usage[category].amountUsed + action.data.amountPaid);
                        } else {
                            usage[category].amountUsed = Number(action.data.amountPaid);
                        }
                    }

                    return item;
                });
            } else {
                const user = items.user;
                if (user[category]) {
                    user[category].amountUsed = Number(user[category].amountUsed + action.data.amountPaid);
                } else {
                    user[category] = {
                        amountUsed: Number(action.data.amountPaid)
                    };
                }

                const latestUserClaimTypes = items.latestUserClaimTypes;
                const indexOfCategory = latestUserClaimTypes.indexOf(category);

                if (indexOfCategory === -1) {
                    // the category is not in the array so lets remove the last item and add it to the front
                    latestUserClaimTypes.splice(-1, 1);
                    latestUserClaimTypes.unshift(category);
                } else {
                    latestUserClaimTypes.splice(indexOfCategory, 1);
                    latestUserClaimTypes.unshift(category);
                }
            }

            return {
                ...state,
                items: {
                    ...items
                }
            };
        }

        default:
            return state;
    }
};