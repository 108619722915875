import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import notifier from './notifier';
import user from './user';
import theme from './theme';
import wedding from './wedding';
import events from './events';
import posts from './posts';
import signup from './signup';
import guests from './guests';
import usage from './usage';

export default combineReducers({
    routing: routerReducer,
    notifier,
    user,
    theme,
    wedding,
    events,
    posts,
    signup,
    guests,
    usage
});