import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import { hideNotification } from '../actions/notifier';
import Text from './Text';
import { colors } from '../theme';

class Notifier extends Component {
    state = {
        hide: false
    };

    _close = () => {
        this.props.dispatch(hideNotification());
    };

    render() {
        const { classes } = this.props;

        if (!this.props.showing) {
            return null;
        }

        return (
            <div
                className={
                    this.props.notifierType === 'error'
                        ? `${ classes.notifier } ${ classes.errorNotifier }`
                        : `${ classes.notifier } ${ classes.messageNotifier }`
                }
            >
                <CloseIcon
                    className={classes.closeModalIcon}
                    onClick={this._close}
                />

                <Text
                    align="center"
                    color={colors.white}
                    medium
                    display="block"
                >
                    {this.props.verbiage}
                </Text>
            </div>
        );
    }
}

/**
 *
 * @param {*} theme
 */
const styles = (theme) => ({
    errorNotifier: {
        backgroundColor: colors.red
    },
    messageNotifier: {
        backgroundColor: colors.brandGreen
    },
    notifier: {
        color: colors.surface,
        height: 'auto',
        left: '0',
        marginLeft: 'auto',
        marginRight: 'auto',
        minHeight: '20px',
        padding: '20px',
        position: 'absolute',
        right: '0',
        top: '2%',
        width: '600px',
        zIndex: '9999',
        maxWidth: '90%'
    },
    closeModalIcon: {
        cursor: 'pointer',
        display: 'inline',
        marginTop: -15,
        marginRight: -15,
        fontSize: 22,
        float: 'right',
        color: colors.surface
    }
});

Notifier.propTypes = {
    dispatch: PropTypes.func,
    verbiage: PropTypes.string,
    notifierType: PropTypes.string,
    showing: PropTypes.bool
};

/**
 *
 * @param {*} state
 */
const mapStateToProps = (state) => ({
    verbiage: state.notifier.verbiage,
    notifierType: state.notifier.type,
    showing: state.notifier.showing
});

export default withStyles(styles)(connect(mapStateToProps)(Notifier));