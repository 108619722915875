import request from './request';

/**
 * @returns {Promise<any>}
 */
function createPost(body) {
    const options = {
        endpoint: `/createPost`,
        method: 'POST',
        body
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @returns {Promise<any>}
 */
function completePost(body) {
    const options = {
        endpoint: `/completePost`,
        method: 'POST',
        body
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @returns {Promise<any>}
 */
function createPhoto(body) {
    const options = {
        endpoint: `/createPhoto`,
        method: 'POST',
        body
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @returns {Promise<any>}
 */
function markPhotoUploaded(body) {
    const options = {
        endpoint: `/markPhotoUploaded`,
        method: 'POST',
        body
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @returns {Promise<any>}
 */
function getFeed(body) {
    const options = {
        endpoint: `/feed`,
        method: 'POST',
        body
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @returns {Promise<any>}
 */
function addComment(body) {
    const options = {
        endpoint: `/addComment`,
        method: 'POST',
        body
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @returns {Promise<any>}
 */
function deleteComment(body) {
    console.log('deleteComment', body);
    const options = {
        endpoint: `/deleteComment`,
        method: 'POST',
        body
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @returns {Promise<any>}
 */
function addLike(body) {
    const options = {
        endpoint: '/addLike',
        method: 'POST',
        body
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
} 

/**
 * @returns {Promise<any>}
 */
function removeLike(body) {
    
    const options = {
        endpoint: '/removeLike',
        method: 'POST',
        body
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @returns {Promise<any>}
 */
function blockPost(body) {
    const options = {
        endpoint: '/blockPost',
        method: 'POST',
        body
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @returns {Promise<any>}
 */
function blockUser(body) {
    const options = {
        endpoint: '/blockUser',
        method: 'POST',
        body
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

export { 
    createPost, 
    completePost, 
    createPhoto, 
    getFeed, 
    addComment,
    deleteComment,
    addLike,
    removeLike,
    markPhotoUploaded,
    blockPost, 
    blockUser
};