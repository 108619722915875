const validations = {
    presence: {
        length: {
            minimum: 1,
            message: '^Please enter a value.'
        }
    },

    accountIdentifier: {
        length: {
            minimum: 6,
            message: '^Your Account Identifier is incorrect.'
        }
    },

    userIdentifier: {
        length: {
            minimum: 6,
            message: '^Your User Identifier is incorrect.'
        }
    },

    email: {
        presence: {
            message: '^Please enter an email address.'
        },
        email: {
            message: '^Please enter a valid email address.'
        }
    },

    password: {
        presence: {
            message: '^Please enter a password.'
        },
        length: {
            minimum: 6,
            message: '^Your password must be at least 6 characters.'
        }
    },

    newPassword: {
        presence: {
            message: '^Please enter a password.'
        },
        length: {
            minimum: 6,
            message: '^Your password must be at least 6 characters.'
        }
    },

    confirmPassword: {
        presence: {
            message: '^Please enter a password.'
        },
        length: {
            minimum: 6,
            message: '^Your password must be at least 6 characters.'
        }
    },

    companyName: {
        length: {
            minimum: 1,
            message: '^Please enter a company name.'
        }
    },

    address: {
        length: {
            minimum: 1,
            message: '^Please enter a valid address.'
        }
    },

    companyAddress: {
        length: {
            minimum: 1,
            message: '^Please enter a company address.'
        }
    },

    phone: {
        length: {
            minimum: 10,
            message: '^Please enter a valid phone number.'
        }
    },

    name: {
        length: {
            minimum: 1,
            message: '^Please enter a first and last name.'
        }
    },

    date: {
        presence: {
            message: '^Please enter a date.'
        },
        length: {
            minimum: 6,
            // maximum: 40,
            message: '^Please enter a valid date.'
        }
    },

    birthdate: {
        length: {
            minimum: 1,
            message: '^Please enter a birth date: MM/DD/YYYY.'
        }
    },

    className: {
        length: {
            minimum: 1,
            message: '^Please provide a class.'
        }
    },

    divisionName: {
        length: {
            minimum: 1,
            message: '^Please provide a division.'
        }
    },

    hireDate: {
        length: {
            minimum: 1,
            message: '^Please enter a hire date: MM/DD/YYYY.'
        }
    },

    gender: {
        length: {
            minimum: 1,
            message: '^Please provide a gender.'
        }
    },

    title: {
        length: {
            minimum: 1,
            message: '^Please provide an occupation for the user.'
        }
    },

    coverageType: {
        length: {
            minimum: 1,
            message: '^Please select a coverage type.'
        }
    },

    province: {
        length: {
            minimum: 1,
            message: '^Please provide the users province of residence.'
        }
    },

    earningsType: {
        length: {
            minimum: 1,
            message: '^Please choose the earnings type.'
        }
    },

    salary: {
        length: {
            minimum: 1,
            message: '^Please provide the users annual salary.'
        }
    },

    hourlyWage: {
        length: {
            minimum: 1,
            message: '^Please provide the users hourly wage.'
        }
    },

    hoursPerWeek: {
        length: {
            minimum: 1,
            message: '^Please provide the number of hours worked per week.'
        }
    },

    firstName: {
        length: {
            minimum: 1,
            message: '^Please provide a first name.'
        }
    },

    lastName: {
        length: {
            minimum: 1,
            message: '^Please provide a last name.'
        }
    },

    relationship: {
        length: {
            minimum: 1,
            message: '^Please provide a relationship.'
        }
    },

    shares: {
        length: {
            minimum: 1,
            message: '^Please provide a percentage of shares.'
        }
    },

    percentage: {
        length: {
            minimum: 1,
            message: '^Please provide a percentage of total benefit.'
        }
    }
};

export default validations;