import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';

import Text from '../Text';
import Button from '../Button';
import { colors } from '../../theme';

/**
 *
 * @param {*} param0
 */
const FormModal = ({
    open,
    close,
    title,
    maxWidth,
    fullWidth,
    fullScreen,
    transitionComponent,
    children,
    classes,
    disableBackdropClick,
    disableEscapeKeyDown,
    onSave,
    saveButtonText,
    onRemove
}) => (
    <Dialog
        maxWidth={maxWidth}
        fullWidth={fullWidth}
        fullScreen={fullScreen}
        onClose={close}
        open={open}
        aria-labelledby={title}
        TransitionComponent={transitionComponent}
        disableBackdropClick={disableBackdropClick}
        disableEscapeKeyDown={disableEscapeKeyDown}
    >
        <div className={classes.modal}>
            <div className={classes.titleIconWrapper}>
                <Text
                    align="center"
                    bold
                    mediumLarge
                    display="inline-block"
                    margin="0"
                >
                    {title}
                </Text>
                {close && (
                    <CloseIcon
                        className={classes.closeModalIcon}
                        onClick={close}
                    />
                )}
            </div>
            <div className={classes.form}>
                {children}
                {onRemove && onSave && (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <Button onClick={onRemove} transparent marginTop={40}>
							Remove
                        </Button>
                        <Button color="primary" onClick={onSave} marginTop={40}>
                            {saveButtonText || 'Save'}
                        </Button>
                    </div>
                )}
                {!onRemove && onSave && (
                    <Button
                        color="primary"
                        // fullWidth
                        onClick={onSave}
                        marginTop={40}
                    >
                        {saveButtonText || 'Save'}
                    </Button>
                )}
            </div>
        </div>
    </Dialog>
);

/**
 *
 * @param {*} theme
 */
const styles = (theme) => ({
    form: {
        // padding: '20px',
        paddingBottom: '0',
        paddingTop: '0',
        overflowY: 'scroll',
        height: '80vh'
        // width: 'fit-content'
    },
    modal: {
        overflow: 'auto',
        outline: 'none',
        // padding: '40px',
        padding: 10,
        paddingBottom: 30,
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        backgroundColor: colors.surface,
        height: 'fit-content'
    },
    modalTopRow: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    closeModalIcon: {
        cursor: 'pointer',
        fontSize: '22px'
    },
    titleIconWrapper: {
        width: '100%',
        paddingBottom: '15px',
        color: colors.mainTextGrey,

        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }
});

export default withStyles(styles)(FormModal);